import {Injectable} from '@angular/core';
import {ApiBaseService} from '@shared/services/api/api-base.service';

@Injectable()
export class InvoiceItemService extends ApiBaseService {
  route_prefix = 'invoice-items'

  options () {
    return this.http.get(this.route_prefix + '/' + 'options');
  }

  cancel(id) {
    return this.http.post(this.route_prefix + '/' + id + '/cancel', {});
  }

  pay(id) {
    return this.http.post(this.route_prefix + '/' + id + '/pay', {});
  }

  items(id) {
    return this.http.get(this.route_prefix + '/' + id + '/items');
  }
}
