import { Component, OnInit } from '@angular/core';
import { ServiceExtraService } from '../service-extra.service';
import { FormGroup } from '@angular/forms';
import { find, get, keys } from 'lodash';
import { ModelAttributeOptionService } from '@app/shared/services/api/model-attribute-option.service';
import { ServiceOptions } from '@app/shared/models/options';

@Component({
  selector: 'app-service-extra-edit',
  templateUrl: './service-extra-edit.component.html',
  styleUrls: ['./service-extra-edit.component.scss']
})
export class ServiceExtraEditComponent implements OnInit {
  public serviceOptions: ServiceOptions;
  public keys = keys;

  constructor(
    public serviceExtraService: ServiceExtraService,
    private mao: ModelAttributeOptionService,
  ) {
  }

  ngOnInit() {
    this.getOptions();
  }

  getOptions() {
    this.mao.all().subscribe((data) => {
      this.serviceOptions = data.service;
    })
  }

  getExtraFormValueData(key) {
    return get(find(this.serviceExtraService.extra, ['option_value', key]), 'data');
  }

}
