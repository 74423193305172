import {AppConfirmService} from '@shared/services/system/app-confirm/app-confirm.service';
import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {AppLoaderService} from '@shared/services/system/app-loader/app-loader.service';
import {ActivatedRoute} from '@angular/router';
import {PurchaseOrderService} from '@shared/services/api/purchase-order.service';
import {ModelAttributeOptionService} from '@shared/services/api/model-attribute-option.service';
import {DisplayColumnsService} from '@shared/services/display-columns.service';
import {ServiceService} from '@shared/services/api/service.service';
import {PopupService} from '@shared/services/popup.service';
import {PurchaseOrderItemModel} from '@app/shared/models/purchase-order-item.model';
import {SimpleReuseStrategy} from '@app/simple-reuse-strategy';
import {CompanyModel} from '@app/shared/models/company.model';
import * as _ from 'lodash';
import {get} from 'lodash';
import {NotifyService} from '@app/shared/services/notify.service';
import {Settings, SettingsService} from '@app/shared/services/system/settings.service';
import {AuthService} from '@shared/services/auth.service';
import {debounceTime, distinctUntilChanged, filter, switchMap} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';


@Component({
  selector: 'app-purchase-order-create',
  templateUrl: './purchase-order-create.component.html',
  providers: [SimpleReuseStrategy]
})
export class PurchaseOrderCreateComponent implements OnInit {
  public materials = [];
  public formGroup = new FormGroup({
    subject: new FormControl('', Validators.required),
    description: new FormControl('', Validators.required),
    vat: new FormControl(0, Validators.required),
    status: new FormControl('', Validators.required),
    target_company_id: new FormControl(null, Validators.required),  // supplier
    user_identity_user_id: new FormControl(null, Validators.required),
    service_id: new FormControl('', {updateOn: 'blur'}),
    data: new FormGroup({
      date_of_order: new FormControl(new Date(), Validators.required),
      delivery_to: new FormControl('', Validators.required),
      delivery_address: new FormControl(''),
      delivery_date: new FormControl(new Date()),
      order_method: new FormControl(''),
      emails: new FormControl([]),
    }),
  });
  public itemFormGroup: FormGroup = new FormGroup({
    product_id: new FormControl(null),
    item_type: new FormControl('', Validators.required),
    item_name: new FormControl('', [Validators.required, Validators.maxLength(255)]),
    item_description: new FormControl(''),
    item_unit_margin: new FormControl(0, Validators.compose([Validators.required, Validators.min(0)])),
    item_unit_price: new FormControl(0, Validators.compose([Validators.required])),
    item_quantity: new FormControl(0, Validators.compose([Validators.required, Validators.min(0)])),
  });

  public purchaseOrderItemTableDisplayColumns = [
    {display: 'Type', key: 'item_type', useColor: false},
    {display: 'Quantity', key: 'item_quantity', useColor: false},
    {display: 'Name', key: 'item_name', useColor: false},
    {display: 'Price', key: 'item_unit_price', useColor: false},
    {display: 'Markup (%)', key: 'item_unit_margin', useColor: false},
    {display: 'Total Price', key: 'item_total_price', useColor: false},
  ];
  @Input() inPopup = false;
  @Input() data: { serviceId: number; } = {serviceId: null};
  @Output() onSubmitted: EventEmitter<any> = new EventEmitter();

  public serviceSuppliers: CompanyModel[] = [];

  public purchaseOrderItems: PurchaseOrderItemModel[] = [];

  public total = 0;
  public subtotal = 0;
  public lineTotal = 0;
  public defaultMargin = 0;
  public useMargin = 0;
  public serviceIdIsValid = false;

  public get authUser() {
    return this.auth.user;
  }

  constructor(
    private fb: FormBuilder,
    public purchaseOrderService: PurchaseOrderService,
    private loader: AppLoaderService,
    private maoService: ModelAttributeOptionService,
    public displayColumnsService: DisplayColumnsService,
    public route: ActivatedRoute,
    public popup: PopupService,
    public http: HttpClient,
    public serviceService: ServiceService,
    private confirmService: AppConfirmService,
    private toast: NotifyService,
    public settingsService: SettingsService,
    private auth: AuthService,
  ) {
    settingsService.all().subscribe((settings: Settings) => {
      this.defaultMargin = get(settings, 'purchase_order_item_margin.unit_margin', 0);
      this.useMargin = get(settings, 'purchase_order_item_margin.unit_margin', 0);
      this.resetItemFormGroup();
    });
  }

  ngOnInit() {
    this.buildItemForm();
    if (this.inPopup) {
      // in popup
    } else {
      this.data.serviceId = +this.route.snapshot.paramMap.get('serviceId');
    }
  }

  resetItemFormGroup() {
    this.itemFormGroup.reset({
      product_id: null,
      item_type: '',
      item_name: '',
      item_description: '',
      item_unit_margin: this.useMargin,
      item_unit_price: 0,
      item_quantity: 0,
      use_margin: 0,
    });
  }

  buildItemForm() {
    this.itemFormGroup.valueChanges.subscribe((change) => {
      let item_quantity = change.item_quantity || 0;
      let item_unit_price = change.item_unit_price || 0;
      this.useMargin = change.item_unit_margin || 0;
      this.lineTotal = item_quantity * item_unit_price * (1 + this.useMargin / 100);
    });
    this.itemFormGroup.get('item_type').valueChanges.subscribe((change) => {
      this.useMargin = change === 'service' ? 0 : this.defaultMargin;
      this.itemFormGroup.patchValue({item_unit_margin: this.useMargin, product_id: null}, {emitEvent: false});
      this.materials = [];
    });
    this.formGroup.get('vat').valueChanges.subscribe((vat) => {
      this.computeTotal(vat);
    });
    this.formGroup.get('service_id').valueChanges.subscribe((change) => {
      if (!change) {
        this.serviceIdIsValid = false;
      } else {
        let loader = this.loader.open();
        this.serviceService.checkValid(change)
          .finally(() => loader.close())
          .subscribe((data: { valid: boolean; client_id: string; }) => {
            if (data.valid) {
              this.serviceIdIsValid = true;
              this.toast.show('Valid id');
              this.getServiceSuppliers(data.client_id);
            } else {
              this.serviceIdIsValid = false;
              this.toast.show('Invalid id');
            }
          });
      }
    });
    this.formGroup.get('data.delivery_to').valueChanges.subscribe((change) => {
      if (change) {
        let changeOption = _.find(this.purchaseOrderService.deliveryToOptions, ['option_value', change]);
        this.formGroup.patchValue({
          delivery_address: changeOption['address'] || ''
        });
      }
    });
    this.formGroup.get('data.order_method').valueChanges.subscribe((method) => {
      if (method === 'telephone') {
        this.formGroup.patchValue({emails: []});
      }
    });
    this.formGroup.patchValue({
      service_id: this.data.serviceId,
    });

    this.itemFormGroup.controls['item_name'].valueChanges
      .pipe(
        filter(text => {
          return this.itemFormGroup.controls['item_type'].value === 'material' && text.length > 2;
        }),
        debounceTime(10),
        distinctUntilChanged(),
        switchMap((res) => this.http.get('purchase-order-item/materials', {
          params: {
            keyword: res
          }
        })),
      )
      .subscribe((res: any) => {
        this.materials = res;
      });
  }

  submit() {
    this.confirmService.confirm().subscribe(res => {
      if (res) {
        let data = {
          ...this.formGroup.value,
          user_identity: this.purchaseOrderService.userIdentities.find((item) => {
            return item.id === this.formGroup.value.user_identity
          }),
          items: this.purchaseOrderItems
        };
        let loader = this.loader.open();
        this.purchaseOrderService.store(data)
          .finally(() => {
            loader.close();
          })
          .subscribe(() => {
            SimpleReuseStrategy.deleteRouteSnapshot('//purchase-order/create');
            this.onSubmitted.emit();
            this.toast.show('Purchase order added!');
          });
      }
    });
  }

  // Quotation Item Function

  addPurchaseOrderItem() {
    let item = this.itemFormGroup.value;
    if (item.item_name.name) {
      item.item_name = item.item_name.name;
    }
    this.purchaseOrderItems.push({
      ...item,
      item_total_price: item.item_unit_price * item.item_quantity * (1 + item.item_unit_margin / 100),
    });
    this.purchaseOrderItems = [...this.purchaseOrderItems];
    this.computeTotal();
    this.itemFormGroup.reset();
  }

  removePurchaseOrderItem(row) {
    let index = this.purchaseOrderItems.indexOf(row);
    this.purchaseOrderItems.splice(index, 1);
    this.purchaseOrderItems = [...this.purchaseOrderItems];
    this.computeTotal();
  }

  computeTotal(vat = 0) {
    this.subtotal = this.purchaseOrderItems.reduce((a, b) => {
      return a + parseFloat('' + b.item_total_price);
    }, 0);
    if (!vat) {
      vat = this.formGroup.value.vat || 0;
    }
    this.total = this.subtotal + vat;
  }

  openEditServicePopup(serviceId) {
    this.popup.openServiceEditPage(serviceId);
  }

  getServiceSuppliers(clientId = '') {
    let loader = this.loader.open();
    this.purchaseOrderService.serviceSuppliers(clientId)
      .finally(() => loader.close())
      .subscribe((data: CompanyModel[]) => {
        this.serviceSuppliers = data;
      });
  }

  materialAutoComplete($event) {
    const item = $event.option.value;
    this.itemFormGroup.patchValue({
      product_id: item.id,
      item_unit_price: item.buy_price,
      item_description: item.description
    });
  }

  materialName(item) {
    if (item) {
      return item.code + '-' + item.name;
    } else {
      return  '';
    }
  }

}
