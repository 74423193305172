import { Injectable } from '@angular/core';
import { ApiBaseService } from '@shared/services/api/api-base.service';

@Injectable()
export class InvoiceService extends ApiBaseService {
  route_prefix = 'invoices';

  options() {
    return this.http.get(this.route_prefix + '/' + 'options');
  }

  getQuotationItems(id) {
    return this.http.get(this.route_prefix + '/quotations' + '/' + id);
  }

  getPurchaseOrderItems(id) {
    return this.http.get(this.route_prefix + '/purchase-orders' + '/' + id);
  }

  getQuotations({ page, pre_page, keyword }) {
    return this.http.get(this.route_prefix + '/quotations', { params: { page, pre_page, keyword } });
  }

  getPurchaseOrders({ page, pre_page, keyword }) {
    return this.http.get(this.route_prefix + '/purchase-orders', { params: { page, pre_page, keyword } });
  }

  cancel(id) {
    return this.http.post(this.route_prefix + '/' + id + '/cancel', {});
  }

  pay(id) {
    return this.http.post(this.route_prefix + '/' + id + '/pay', {});
  }
}
