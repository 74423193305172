export class Paginate {
  // The number of elements in the page, default table size
  size = 100;
  // The total number of elements
  totalElements = 0;
  // The total number of pages
  totalPages = 0;
  // The current page number
  pageNumber = 0;

  constructor(props: { size?: number, totalElements?: number, totalPages?: number, pageNumber?: number } = {}) {
    if (props['size']) {
      this.size = props['size'];
    }
    if (props['totalElements']) {
      this.totalElements = props['totalElements'];
    }
    if (props['totalPages']) {
      this.totalPages = props['totalPages'];
    }
    if (props['pageNumber']) {
      this.pageNumber = props['pageNumber'];
    }
  }

}
