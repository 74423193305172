import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  constructor(
    private http: HttpClient,
  ) { }

  home() {
    return this.http.get('dashboard/home');
  }

  getServiceDashboard(group, type) {
    return this.http.get('dashboard/service', {
      params: {
        group, type
      }
    });
  }

  getServicePmDashboard(surveyor_source) {
    return this.http.get('dashboard/pm-service', {params: {
      surveyor_source: surveyor_source
    }});
  }

  getQuotationDashboard() {
    return this.http.get('dashboard/quotation');
  }
}
