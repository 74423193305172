import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import 'rxjs-compat/add/operator/finally';

@Component({
  selector: 'holiday-approval',
  template: // html
    `
    <h1 mat-dialog-title translate>{{data?.title}}</h1>
    <div mat-dialog-content>
      <div fxLayout="row wrap" fxLayout.lt-sm="column"  class="p-1">
        <!--Type-->
        <div fxFlex="100" class="pr-1">
          <mat-form-field class="full-width">
            <input matInput name="type" placeholder="Type" [value]="data.holiday.type | translate" disabled>
          </mat-form-field>
        </div>
        <!--Start-->
        <div fxFlex="100" class="pr-1">
          <mat-form-field class="full-width">
            <input matInput name="start" placeholder="Start" [value]="data.holiday.start_time | date: 'short'" disabled>
          </mat-form-field>
        </div>
        <!--end-->
        <div fxFlex="100" class="pr-1">
          <mat-form-field class="full-width">
            <input matInput name="end" placeholder="End" [value]="data.holiday.end_time | date: 'short'" disabled>
          </mat-form-field>
        </div>
        <!--severity-->
        <div fxFlex="100" class="pr-1">
          <mat-form-field class="full-width">
            <textarea matInput name="note" [value]="data.holiday.note"
                      placeholder="Note" rows="5" disabled></textarea>
          </mat-form-field>
        </div>
      </div>
    </div>
    <div mat-dialog-actions *ngIf="data.holiday.status=='' || data.holiday.status=='pending'">
      <div fxFlex></div>
      <button mat-raised-button (click)="submit('declined')" color="warn" translate>
      <mat-icon>close</mat-icon>Declined</button>
      <button mat-raised-button (click)="submit('approved')" color="primary" translate>
      <mat-icon>check</mat-icon>Approved</button>
    </div>
  `
})
export class HolidayApprovalComponent {
  constructor(
    public dialogRef: MatDialogRef<HolidayApprovalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { title?: string; holiday?: any; },
  ) {
  }

  submit(status) {
    this.dialogRef.close({ status: status });
  }
}

