import { AppLoaderService } from './../../../../services/system/app-loader/app-loader.service';
import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { AppConfirmService } from '@shared/services/system/app-confirm/app-confirm.service';

import { PopupService } from '@shared/services/popup.service';
import { DocumentService } from '@shared/services/api/document.service';
import { DocumentModel } from '@shared/models/document.model';
import { LayoutService } from '@app/shared/services/system/layout.service';
import { NotifyService } from '@app/shared/services/notify.service';
import {
  Image, PlainGalleryConfig, PlainGalleryStrategy, AdvancedLayout
} from '@ks89/angular-modal-gallery';
import { GalleryDialogComponent } from '@shared/components/business/gallery-dialog/gallery-dialog.component';
import { MatDialog } from '@angular/material';

@Component({
  selector: 'app-document-table',
  templateUrl: './document-table.component.html',
  styleUrls: ['./document-table.component.css']
})
export class DocumentTableComponent implements OnInit, OnDestroy {
  @Input() id;
  @Input() model;
  public filterType = '';
  public items: DocumentModel[] = [];
  public filterItems: DocumentModel[] = [];
  public images: Image[] = [];
  public imageItems = [];
  private onRefreshSub;

  galleryRowDescConfig: PlainGalleryConfig = {
    strategy: PlainGalleryStrategy.CUSTOM,
    layout: new AdvancedLayout(- 1, true)
  };

  constructor(
    public documentService: DocumentService,
    private confirmService: AppConfirmService,
    private toast: NotifyService,
    private popup: PopupService,
    private loading: AppLoaderService,
    private layout: LayoutService,
    private dialog: MatDialog
  ) {
  }

  ngOnInit() {
    this.refresh();
    this.onRefreshSub = this.layout.onRefresh.subscribe(() => this.refresh());
  }

  ngOnDestroy() {
    if (this.onRefreshSub) {
      this.onRefreshSub.unsubscribe();
    }
  }

  deleteItem(row) {
    this.confirmService.confirm({message: `Delete ${row.id}?`})
      .subscribe(res => {
        if (res) {
          let loader = this.loading.open();
          this.documentService.destroy(row.id)
            .finally(() => {
              loader.close();
            })
            .subscribe(() => {
              this.refresh();
              this.toast.show(`Note ${row.id} deleted!`);
            });
        }
      });
  }

  openEditPage(row) {
    this.popup.openDocumentEditPage(row.id)
      .afterClosed()
      .subscribe((res) => {
        if (res) {
          this.refresh();
        }
      });
  }

  refresh() {
    let loader = this.loading.open();
    this.images = [];
    this.imageItems = [];
    this.documentService.indexByModelAndModelId(this.model, this.id)
      .finally(() => loader.close())
      .subscribe((response: DocumentModel[]) => {
        this.filterItems = this.items = response;
        this.items.forEach((item, index) => {
          if (this.isImageUrl(item.full_url)) {
            this.images.push(new Image(
              index,
              { // modal
                img: item.full_url,
                description: item.description
              },
              { // plain
                img: item.full_url,
                title: item.subject
              }
            ));
            this.imageItems.push(item);
          }
        });
        this.images = [...this.images];
        this.imageItems = [...this.imageItems];
      });
  }

  openGalleryDialog(image: Image) {
    const index = image ? this.images.indexOf(image) : - 1;
    this.dialog.open(GalleryDialogComponent, {
      data: {
        images: this.images,
        galleryConfig: this.galleryRowDescConfig,
        index: index
      }
    });
  }

  isImageUrl(url) {
    return (url.match(/\.(jpeg|jpg|gif|png)$/) != null);
  }

  filterInAllDocuments() {
    this.filterItems = this.items.filter((item) => {
      if (this.filterType === '' || item.type === this.filterType) {
        return true;
      }
      return false;
    });
  }
}
