import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {

  constructor(public http: HttpClient) { }

  profile() {
    return this.http.get('profile/profile');
  }

  getAccountSettings() {
    return this.http.get('profile/account-settings');
  }

  setAccountSettings(data) {
    return this.http.post('profile/account-settings', data);
  }

  uploadUrl() {
    return environment.apiURL + environment.adminPrefix + 'profile/avatar';
  }

  resetPassword(data) {
    return this.http.post('profile/change-password', data);
  }

}
