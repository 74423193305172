import { GlobalSettingService } from './../../../../services/system/global-setting.service';
import { Component, EventEmitter, Injector, Input, OnInit, Output } from '@angular/core';
import { ServiceService } from '@shared/services/api/service.service';
import { egretAnimations } from '@shared/animations/egret-animations';
import { BaseTableComponent } from '@shared/components/base/base-table.component';
import { Conditions } from '@shared/models/conditions.model';
import { GlobalSettingConfig } from '@shared/interfaces/global-setting-config';
import { SimpleReuseStrategy } from '@app/simple-reuse-strategy';

@Component({
  selector: 'app-service-table',
  templateUrl: './service-table.component.html',
  animations: egretAnimations
})
export class ServiceTableComponent extends BaseTableComponent implements OnInit {
  @Input() dashboardType = '';
  @Input() dashboardGroup = '';
  @Input() inPopup = false;
  @Output() onBack = new EventEmitter();

  public conditions: Conditions = this._service.conditions;
  public itemName = 'Service';
  public urlPrefix = 'service';
  public items: any[];

  constructor(
    protected injector: Injector,
    public _service: ServiceService,
    private globalSettingService: GlobalSettingService
  ) {
    super(injector);
  }

  ngOnInit() {
    if (this.dashboardType && this.dashboardGroup) {
      this.conditions.dashboard_type.value = {
        item: this.dashboardType,
        group: this.dashboardGroup
      };
    }
    this.layout.onRefresh
      .takeWhile(() => !this.destroy)
      .subscribe(() => this.refresh());
    this.globalSettingService.config$
      .takeWhile(() => !this.destroy)
      .subscribe((config: GlobalSettingConfig) => {
        if (config.useCompanyId) {
          this.refresh();
        }
      });
  }

  back() {
    this.onBack.emit();
  }

  getSorts($event) {
    let canSort = false;
    this.displayColumnsService.configs.service.displayedColumns.forEach((item) => {
      if (item.key === $event.sort_column && item.canSort) {
        canSort = true;
        $event.sort_column = item.sortColumnName;
        $event.sort_table = item.columnTable;
      }
    });

    if (canSort) {
      return $event;
    }

    return {};
  }

  openCreatePage() {
    SimpleReuseStrategy.deleteRouteSnapshot('//service/');
    this.router.navigate([`/${this.urlPrefix}/create`]);
  }
}
