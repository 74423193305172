import { Injectable } from '@angular/core';
import { ApiBaseService } from '@shared/services/api/api-base.service';
import { environment } from '@env/environment';
import { HttpClient } from '@angular/common/http';
import { LayoutService } from '@shared/services/system/layout.service';
import { CompanyModel } from '@shared/models/company.model';
import { ReplaySubject } from 'rxjs';
import { GlobalSettingService } from '@shared/services/system/global-setting.service';
import { Conditions } from '@shared/models/conditions.model';
import { ModelAttributeOptionService } from '@shared/services/api/model-attribute-option.service';
import { ModelAttributeOptions } from '@shared/models/options';

@Injectable()
export class CompanyService extends ApiBaseService {
  route_prefix = 'companies';
  public clients: CompanyModel[] = [];
  public clients$ = new ReplaySubject(1);

  public companies: CompanyModel[] = [];
  public companies$ = new ReplaySubject(1);
  public conditions: Conditions = {
    status: {
      value: null,
      nullable: true,
      items: [],
      display_key: 'option_value',
      select_key: 'option_value',
      display_name: 'Status'
    },
  };

  constructor(
    public http: HttpClient,
    private layout: LayoutService,
    private globalSettingService: GlobalSettingService,
    private mao: ModelAttributeOptionService,
  ) {
    super(http);
    this.globalSettingService.useCompanyId$.subscribe((id) => {
      if (id) {
        this.getClients();
        this.indexAll().subscribe((companies: CompanyModel[]) => {
          this.companies = companies;
          this.companies$.next(companies);
        });
      }
    });
    this.getOptions();
  }

  getOptions() {
    this.mao.all()
      .subscribe((data: ModelAttributeOptions) => {
        this.conditions.status.items = data.company.status;
      });
  }

  private getClients() {
    return this.http.post('company/get-clients', {}).subscribe((clients: CompanyModel[]) => {
      this.clients = clients;
      this.clients$.next(clients);
    });
  }

  getBuildingsByClientId(client_id) {
    return this.http.get(`companies/${client_id}/buildings`);
  }

  private indexAll() {
    return this.http.get(`companies/all`);
  }

  self() {
    return this.http.get('companies/self');
  }

  users(companyId) {
    return this.http.get(`companies/${companyId}/users`);
  }

  register(data) {
    return this.http.post('companies/register', data);
  }

  uploadUrl() {
    return environment.apiURL + environment.adminPrefix + 'companies/upload-document';
  }
}
