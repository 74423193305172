import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-purchase-order-create-popup',
  template: `
    <div mat-dialog-title>
      <span>{{'Purchase Order Create' | translate}}</span>
      <div fxFlex></div>
      <a matTooltip="Close">
        <mat-icon (click)="dialogRef.close()">close</mat-icon>
      </a>
    </div>
    <app-purchase-order-create mat-dialog-content [data]="data" [inPopup]="true" (onSubmitted)="dialogRef.close(true)"></app-purchase-order-create>`
})

export class PurchaseOrderCreatePopupComponent implements OnInit {
  public serviceId;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { serviceId: number; },
    public dialogRef: MatDialogRef<PurchaseOrderCreatePopupComponent>,
  ) {
  }

  ngOnInit() {
  }
}
