import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-service-create-popup',
  template: `
    <app-service-create [engineer_id]="data.engineer_id" [inPopup]="true" [expect_start_time]="data.expect_start_time"  (onSubmitted)="dialogRef.close(true)" (onBack)="dialogRef.close()"></app-service-create>`
})
export class ServiceCreatePopupComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { engineer_id: any; expect_start_time: any; },
    public dialogRef: MatDialogRef<ServiceCreatePopupComponent>,
  ) {
  }

  ngOnInit() {
  }
}
