import { Injectable } from '@angular/core';
import { ApiBaseService } from '@shared/services/api/api-base.service';
import { Conditions } from '@shared/models/conditions.model';
import { HttpClient } from '@angular/common/http';
import { LayoutService } from '@shared/services/system/layout.service';
import { GlobalSettingService } from '@shared/services/system/global-setting.service';
import { ModelAttributeOptionService } from '@shared/services/api/model-attribute-option.service';
import { ModelAttributeOptions } from '@shared/models/options';

@Injectable()
export class BuildingService extends ApiBaseService {
  route_prefix = 'buildings';

  public conditions: Conditions = {
    status: {
      value: null,
      nullable: true,
      items: [],
      display_key: 'option_value',
      select_key: 'option_value',
      display_name: 'Status'
    },
  };

  constructor(
    public http: HttpClient,
    private layout: LayoutService,
    private globalSettingService: GlobalSettingService,
    private mao: ModelAttributeOptionService,
  ) {
    super(http);
    this.getOptions();
  }

  getOptions() {
    this.mao.all()
      .subscribe((data: ModelAttributeOptions) => {
        this.conditions.status.items = data.building.status;
      });
  }
}
