import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AppLoaderService } from '@shared/services/system/app-loader/app-loader.service';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef, MatSnackBar } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { AppConfirmService } from '@shared/services/system/app-confirm/app-confirm.service';
import { PurchaseOrderService } from '@shared/services/api/purchase-order.service';
import { PurchaseOrderItemService } from '@shared/services/api/purchase-order-item.service';
import { ModelAttributeOptionService } from '@shared/services/api/model-attribute-option.service';
import { CompanyService } from '@shared/services/api/company.service';
import { DisplayColumnsService } from '@shared/services/display-columns.service';
import { PopupService } from '@shared/services/popup.service';
import { PurchaseOrderModel } from '@shared/models/purchase-order.model';
import { GlobalSettingService } from '@shared/services/system/global-setting.service';
import { AuthGuard } from '@shared/services/system/auth/auth.guard';
import * as _ from 'lodash';
import { CompanyModel } from '@app/shared/models/company.model';
import { NotifyService } from '@app/shared/services/notify.service';
import { ServiceService } from '@app/shared/services/api/service.service';


@Component({
  selector: 'app-purchase-order-edit',
  templateUrl: './purchase-order-edit.component.html'
})
export class PurchaseOrderEditComponent implements OnInit {
  public itemForm = new FormGroup({
    subject: new FormControl('', Validators.required),
    description: new FormControl('', Validators.required),
    vat: new FormControl(0, Validators.required),
    status: new FormControl('', Validators.required),
    target_company_id: new FormControl('', Validators.required),
    user_identity_user_id: new FormControl(''),

    data: new FormGroup({
      date_of_order: new FormControl('', Validators.required),
      delivery_to: new FormControl('', Validators.required),
      delivery_address: new FormControl(''),
      delivery_date: new FormControl(''),
      order_method: new FormControl(''),
      emails: new FormControl([]),
    }),
  });
  public purchaseOrderItemDisplayColumns = [
    {display: 'Type', key: 'item_type', useColor: false},
    {display: 'Quantity', key: 'item_quantity', useColor: false},
    {display: 'Name', key: 'item_name', useColor: false},
    {display: 'Price', key: 'item_unit_price', useColor: false},
    {display: 'Markup (%)', key: 'item_unit_margin', useColor: false},
    {display: 'Total Price', key: 'item_total_price', useColor: false}
  ];

  @Input() id;
  @Input() inPopup = false;
  @Output() onSubmitted: EventEmitter<any> = new EventEmitter();
  public item: PurchaseOrderModel;
  public clients: CompanyModel[];
  public serviceSuppliers: CompanyModel[] = [];


  constructor(
    private _service: PurchaseOrderService,
    public purchaseOrderService: PurchaseOrderService,
    private purchaseOrderItemService: PurchaseOrderItemService,
    private loader: AppLoaderService,
    private snack: MatSnackBar,
    private confirmService: AppConfirmService,
    private route: ActivatedRoute,
    private router: Router,
    private maoService: ModelAttributeOptionService,
    private companyService: CompanyService,
    public displayColumnsService: DisplayColumnsService,
    private popup: PopupService,
    public auth: AuthGuard,
    public serviceService: ServiceService,
    public globalSetting: GlobalSettingService,
    public dialog: MatDialog,
    public toast: NotifyService,
  ) {
  }

  ngOnInit() {
    if (!this.inPopup) {
      this.id = this.route.snapshot.paramMap.get('id');
    }
    this.buildItemForm();
    this.show(this.id);
  }

  buildItemForm() {
    this.itemForm.get('data.delivery_to').valueChanges.subscribe((change) => {
      if (change) {
        let changeOption = _.find(this.purchaseOrderService.deliveryToOptions, ['option_value', change]);
        if (changeOption && changeOption['address']) {
          this.itemForm.patchValue({
            delivery_address: changeOption['address'],
          });
        }
      }
    });
    this.itemForm.get('data.order_method').valueChanges.subscribe((method) => {
      if (method === 'telephone') {
        this.itemForm.patchValue({emails: []});
      }
    });
    this.itemForm.get('vat').valueChanges.subscribe((change) => {
      this.item.total = this.item.subtotal + change;
    });
  }

  show(id) {
    let loader = this.loader.open();
    this._service.show(id)
      .finally(() => {
        loader.close();
      })
      .subscribe((data: any) => {
        if (data['data'] && data['data']['delivery_date']) {
          data['data']['delivery_date'] = new Date(data['data']['delivery_date']);
        }
        if (data['data'] && data['data']['date_of_order']) {
          data['data']['date_of_order'] = new Date(data['data']['date_of_order']);
        }
        this.item = data;
        this.itemForm.patchValue(data);
        this.getServiceSuppliers(_.get(this.item, 'data.relation.service.0.id'));
      });
  }

  submit() {
    this.confirmService.confirm().subscribe((res) => {
      if (res) {
        let data = {
          ...this.itemForm.value,
          id: this.id
        };
        let loader = this.loader.open();
        this._service.update(this.id, data)
          .finally(() => {
            loader.close();
            this.show(this.id);
          })
          .subscribe(() => {
            this.snack.open('Purchase Order updated!', 'OK', {duration: 4000});
          });
      }
    });
  }

  deleteItem(row) {
    this.confirmService.confirm({message: `Delete ${row.id}?`})
      .subscribe(res => {
        if (res) {
          let loader = this.loader.open();
          this.purchaseOrderItemService.destroy(row.id)
            .finally(() => {
              loader.close();
            })
            .subscribe(() => {
              this.refresh();
              this.snack.open(`Purchase order item ${row.id} deleted!`, 'OK', {duration: 4000});
            });
        }
      });
  }

  openEditItemPopup(row) {
    this.popup.openPurchaseOrderItemEditPage(row.id)
      .afterClosed()
      .subscribe(res => {
        if (res) {
          this.refresh();
        }
      });
  }

  openPurchaseOrderCreateItemPopup() {
    this.popup.openPurchaseOrderItemCreatePage(this.id, this.item.data.relation.service)
      .afterClosed()
      .subscribe((res) => {
        if (res) {
          this.refresh();
        }
      });
  }

  gotoTable() {
    this.router.navigate(['/purchase-order']);
  }

  refresh() {
    this.show(this.id);
  }

  openEditServicePopup(row) {
    this.popup.openServiceEditPage(row.id);
  }

  getServiceSuppliers(serviceId = '') {
    let loader = this.loader.open();
    this.serviceService.checkValid(serviceId)
      .subscribe((data: { valid: boolean; client_id: string; }) => {
        this.purchaseOrderService.serviceSuppliers(data.client_id)
          .finally(() => loader.close())
          .subscribe((data: CompanyModel[]) => {
            this.serviceSuppliers = data;
          });
      }, () => {
          loader.close();
      });
  }

  sendEmail() {
    const dialogRef = this.dialog.open(PurchaseOrderEditSendEmail, {
      width: '300px',
      data: {purchaseOrderId: this.id}
    });

    dialogRef.afterClosed().subscribe(emails => {
      if (emails) {
        let loader = this.loader.open('Send email...');
        this._service.sendEmails({
          purchase_order_id: this.id,
          emails: emails
        })
          .finally(() => loader.close())
          .subscribe(() => {
            this.toast.show('Send success');
          })
      }
    });
  }

}

@Component({
  selector: 'purchase-order-edit-send-email',
  template: `
    <h1 mat-dialog-title>Enter the address where you want to send the message</h1>
    <div mat-dialog-content>
      <mat-form-field class="full-width">
        <input matInput [formControl]="email" placeholder="Email">
      </mat-form-field>
      <button mat-raised-button class="full-width" (click)="addEmail()" translate [disabled]="email.invalid">ADD</button>
      <mat-list>
        <mat-list-item *ngFor="let email of emails">
          <button mat-icon-button (click)="removeEmail(email)">
            <mat-icon>delete</mat-icon>
          </button>
          {{email}}
        </mat-list-item>
      </mat-list>
    </div>
    <div mat-dialog-actions>
      <button mat-raised-button color="accent" (click)="onNoClick()">CANCEL</button>
      <div fxFlex></div>
      <button mat-raised-button color="primary" (click)="send()" [disabled]="emails.length === 0">SEND</button>
    </div>`
})
export class PurchaseOrderEditSendEmail {
  email = new FormControl('', Validators.email);
  emails = [];

  constructor(
    public dialogRef: MatDialogRef<PurchaseOrderEditSendEmail>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  addEmail() {
    if (this.email.valid) {
      this.emails.push(this.email.value);
      this.email.reset();
    }
  }

  removeEmail(email) {
    this.emails.splice(this.emails.indexOf(email), 1);
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  send() {
    this.dialogRef.close(this.emails);
  }

}
