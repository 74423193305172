import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';

@Injectable()
export class PublicService {
  resource_prefix = ''

  constructor(private http: HttpClient) {
  }

  search (data) {
    return this.http.get('search', {params: data});
  }

  setSetting (data) {
    return this.http.post('setting', data);
  }
}
