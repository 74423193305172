import {Component, OnInit, Inject} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA, MatSnackBar} from '@angular/material';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import 'rxjs-compat/add/operator/startWith';
import 'rxjs-compat/add/operator/debounceTime';
import 'rxjs-compat/add/operator/distinctUntilChanged';
import 'rxjs-compat/add/operator/switchMap';
import {AppLoaderService} from '@shared/services/system/app-loader/app-loader.service';
import {ServiceVisitService} from '@shared/services/api/service-visit.service';
import {environment} from '@env/environment';
import {ServiceVisitOptions} from '@shared/models/options';
import {ModelAttributeOptionService} from '@shared/services/api/model-attribute-option.service';
import * as _ from 'lodash';
import {DisplayColumnsService} from '@app/shared/services/display-columns.service';
import {AuditModel} from '@app/shared/models/audit.model';
import {TimeConvertService} from '@shared/services/time-convert.service';
import {HttpClient} from '@angular/common/http';
import {debounceTime, distinctUntilChanged, filter, switchMap} from 'rxjs/operators';
import {AppConfirmService} from '@shared/services/system/app-confirm/app-confirm.service';

@Component({
  selector: 'app-service-visit-edit-popup',
  templateUrl: './service-visit-edit.component.html'
})
export class ServiceVisitEditPopupComponent implements OnInit {
  _ = _;
  public id;
  public itemForm: FormGroup;
  public inPopup = true;
  public item;
  public options: ServiceVisitOptions;
  public sales = [];
  public audit: AuditModel;
  private needRefreshService = false;
  public vehicles = [];
  public materials = [];
  public sources = [
    'inventory',
    'vehicle',
    'other'
  ];

  public saleForm: FormGroup = new FormGroup({
    id: new FormControl(null),
    source: new FormControl('other', Validators.required),
    vehicle: new FormControl(''),
    item_name: new FormControl('', Validators.required),
    item_quantity: new FormControl('', Validators.required),
    item_description: new FormControl(''),
    item_unit_price: new FormControl(0, Validators.required),
    item_unit_margin: new FormControl(0),
  });

  constructor(
    private fb: FormBuilder,
    private _service: ServiceVisitService,
    private loader: AppLoaderService,
    private snack: MatSnackBar,
    private http: HttpClient,
    private confirm: AppConfirmService,
    public dialogRef: MatDialogRef<ServiceVisitEditPopupComponent>,
    private maoService: ModelAttributeOptionService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public displayColumnsService: DisplayColumnsService,
    public timeConvertService: TimeConvertService
  ) {
  }

  ngOnInit() {
    this.id = this.data.id;
    this.getOptions();
    this.buildItemForm({});
    this.show(this.id);
    this.getSales();
    this.getAudit();
    this.getVehicles();

    this.saleForm.controls['source'].valueChanges
      .subscribe((res) => {
        if (res !== 'vehicle') {
          this.saleForm.removeControl('vehicle');
          this.saleForm.addControl('vehicle', new FormControl(''));
        }
        this.saleForm.patchValue({
          item_name: '',
          item_quantity: 0,
          item_description: '',
          item_unit_price: 0,
          item_unit_margin: 0,
        });
        this.materials = [];
      });
    this.saleForm.controls['item_name'].valueChanges
      .pipe(
        filter(text => {
          return this.saleForm.controls['source'].value !== 'other' && text.length > 2;
        }),
        debounceTime(10),
        distinctUntilChanged(),
        switchMap((res) => this.http.get('service-visits/materials', {
          params: {
            keyword: res,
            source: this.saleForm.controls['source'].value,
            vehicle: this.saleForm.controls['vehicle'].value,
          }
        })),
      )
      .subscribe((res: any) => {
        this.materials = res;
        if (res.length === 0) {
          this.saleForm.controls['item_name'].setErrors({noneItem: true});
        }
      });
  }


  getSales() {
    this._service.sales(this.id).subscribe((sales: any) => {
      this.sales = [...sales];
    });
  }

  getAudit() {
    this._service.audit(this.id).subscribe((audit: AuditModel) => {
      this.audit = audit;
    });
  }

  getOptions() {
    this.maoService.all()
      .subscribe((data: any) => {
        this.options = data.service_visit;
      });
  }

  getVehicles() {
    this.http.get('service-visits/vehicles').subscribe((res: any) => {
      this.vehicles = res;
    });
  }

  buildItemForm(item) {
    this.itemForm = this.fb.group({
      id: [{value: item.id, disabled: true}],
      user_id: [item.user_id || ''],
      user_name: [item.user_name || ''],
      check_in_time: [item.check_in_time ? (new Date(item.check_in_time.replace(/-/g, '/').replace('T', ' '))).toISOString() : ''],
      check_out_time: [item.check_out_time ? (new Date(item.check_out_time.replace(/-/g, '/').replace('T', ' '))).toISOString() : ''],
      service_status: [item.service_status || ''],
      note: [item.note || ''],
      observer: [item.observer || ''],
      rate_type: [item.rate_type || ''],
      log_type: [{value: item.log_type || '', disabled: true}],
      created_at: [item.created_at || ''],
      signature: [item.signature || ''],
      check_in_latitude: [item.check_in_latitude || ''],
      check_in_longitude: [item.check_in_longitude || ''],
      check_out_latitude: [item.check_out_latitude || ''],
      check_out_longitude: [item.check_out_longitude || ''],
      data: [_.pick(item.data, ['risk_assessment', 'is_done', 'compliance']) || {}]
    });
  }

  show(id) {
    let loader = this.loader.open();
    this._service.show(id)
      .finally(() => {
        loader.close();
      })
      .subscribe((data: any) => {
        this.item = data;
        this.buildItemForm(this.item);
      });
  }

  submit() {
    let itemFormValue = this.itemForm.value;
    itemFormValue['check_in_time'] = this.timeConvertService.convert(itemFormValue['check_in_time']);
    itemFormValue['check_out_time'] = this.timeConvertService.convert(itemFormValue['check_out_time']);
    let data = {...itemFormValue};
    if (this.item.data.risk_assessment) {
      data['data']['risk_assessment'] = this.item.data.risk_assessment;
    }
    let loader = this.loader.open();
    this._service.update(this.id, data)
      .finally(() => {
        loader.close();
        this.show(this.id);
        this.needRefreshService = true;
      })
      .subscribe(() => {
        this.snack.open('Visit updated!', 'OK', {duration: 4000});
      });
  }

  refresh() {
    this.show(this.id);
  }

  gotoTable() {
    if (this.data) {
      this.dialogRef.close();
    }
  }

  getFullFileUrl(url) {
    if (url.substr(0, 1) === '/') {
      url = url.substr(1);
    }

    return environment.fileUrl + url;
  }

  materialAutoComplete($event) {
    const item = $event.option.value;
    this.saleForm.patchValue({
      item_description: item.description,
      item_unit_price: item.sell_price
    });
  }

  materialName(item) {
    return item.name;
  }

  addSale() {
    this.confirm.confirm()
      .subscribe((confirmRes) => {
        if (confirmRes) {
          const sale = this.saleForm.value;
          if (sale.source !== 'other') {
            if (sale.item_name.name) {
              const quantity = sale.item_quantity;
              if (quantity > sale.item_name.quantity) {
                this.snack.open('Maximum quantity exceeded.', 'OK', {duration: 4000});
                return;
              }
              if (quantity < 0) {
                this.snack.open('Quantity must be greater than 0.', 'OK', {duration: 4000});
                return;
              }
              _.assignIn(sale, {product_id: sale.item_name.id});
              sale.item_name = sale.item_name.name;
            } else {
              return;
            }
          }
          _.assignIn(sale, {item_total_price: this.itemTotalPrice});
          const loader = this.loader.open();
          this.http.post(`service-visits/${this.id}/sale`, sale)
            .finally(() => {
              loader.close();
            })
            .subscribe((res) => {
              this.saleForm.patchValue({
                id: null,
                source: 'other',
                vehicle: '',
                item_name: '',
                item_quantity: '',
                item_description: '',
                item_unit_price: 0,
                item_unit_margin: 0
              });
              this.snack.open('Sale saved!');
              this.getSales();
            });
        }
      });
  }

  deleteSale(sale) {
    this.confirm.confirm()
      .subscribe((confirmRes) => {
        if (confirmRes) {
          const loader = this.loader.open();
          this.http.delete(`service-visits/${this.id}/sale/${sale.id}`)
            .finally(() => {
              loader.close();
            })
            .subscribe((res) => {
              this.snack.open('Sale deleted!');
              this.getSales();
            });
        }
      });
  }

  get itemTotalPrice() {
    if (this.saleForm) {
      return (this.saleForm.controls['item_unit_price'].value * (1 + this.saleForm.controls['item_unit_margin'].value / 100))  *
        this.saleForm.controls['item_quantity'].value;
    }

    return 0;
  }
}
