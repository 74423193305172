/* "Barrel" of Http Interceptors */
import { HTTP_INTERCEPTORS } from '@angular/common/http';

import { LoggingInterceptor } from './logging-interceptor';
import { ErrorHandlerInterceptor } from '@app/shared/http-interceptors/error-handler.interceptor';
import { ApiPrefixInterceptor } from '@app/shared/http-interceptors/api-prefix.interceptor';
import {CancelHandlerInterceptor} from '@shared/http-interceptors/cancel-handler.interceptor';


/** Http interceptor providers in outside-in order */
export const httpInterceptorProviders = [
  // { provide: HTTP_INTERCEPTORS, useClass: LoggingInterceptor, multi: true },
  {provide: HTTP_INTERCEPTORS, useClass: ErrorHandlerInterceptor, multi: true},
  {provide: HTTP_INTERCEPTORS, useClass: ApiPrefixInterceptor, multi: true},
  { provide: HTTP_INTERCEPTORS, useClass: CancelHandlerInterceptor, multi: true },
];
