import { Component, OnDestroy, OnInit, EventEmitter, Output } from '@angular/core';
import { egretAnimations } from '@shared/animations/egret-animations';
import { DashboardService } from '@shared/services/api/dashboard.service';
import { ThemeService } from '@shared/services/system/theme.service';
import * as _ from 'lodash';
import { LayoutService } from '@shared/services/system/layout.service';
import { dashboardConfigs } from '@shared/config';
import { GlobalSettingService } from '@shared/services/system/global-setting.service';
import { GlobalSettingConfig } from '@shared/interfaces/global-setting-config';
import { ServiceDashboardModel } from '@app/shared/models/service-dashboard.model';

@Component({
  selector: 'app-quotation-dashboard',
  templateUrl: './quotation-dashboard.component.html',
  animations: egretAnimations
})
export class QuotationDashboardComponent implements OnInit, OnDestroy {
  _ = _;
  public loading = false;
  public dashboard;
  public serviceDashboard: ServiceDashboardModel = new ServiceDashboardModel;

  public displays = dashboardConfigs.quotation;
  public groups: string[] = [];
  public serviceGroups: string[] = [];
  private destroy = false;
  refreshSubArr = [];
  pmArr = [];
  @Output() onClickDashboard = new EventEmitter();

  constructor(
    private dashboardService: DashboardService,
    private layout: LayoutService,
    public theme: ThemeService,
    private globalSettingService: GlobalSettingService,
  ) {
    this.displays.map(item => {
      if (this.groups.indexOf(item.group) === - 1) {
        this.groups.push(item.group);
      }
    });
    dashboardConfigs.serviceQuotation.map(item => {
      if (this.serviceGroups.indexOf(item.group) === - 1) {
        this.serviceGroups.push(item.group);
      }
    });
  }

  ngOnInit() {
    this.layout.onRefresh
      .takeWhile(() => !this.destroy)
      .subscribe(() => this.refresh());
    this.globalSettingService.config$
      .takeWhile(() => !this.destroy)
      .subscribe((config: GlobalSettingConfig) => {
        if (config.useCompanyId) {
          this.refresh();
        }
      });
  }

  refresh() {
    this.loading = true;

    if (this.refreshSubArr.length > 0) {
      let sub = this.refreshSubArr.pop();
      if (sub) {
        sub.unsubscribe();
      }
    }
    this.dashboardService.getQuotationDashboard()
      .finally(() => this.loading = false)
      .subscribe((data: any) => {
        this.dashboard = data;
      });

    dashboardConfigs.serviceQuotation.map((item) => {
      let type = item.item;
      let group = item.group;
      const refreshSub = this.dashboardService.getServiceDashboard(group, type)
        .takeWhile(() => !this.destroy)
        .subscribe((data: number) => {
          this._.set(this.serviceDashboard, [group, type], data);
        });
      this.refreshSubArr.push(refreshSub);
    });
    this.dashboardService.getServicePmDashboard('quotation')
      .takeWhile(() => !this.destroy)
      .subscribe((data: any) => {
        this.pmArr = data.list;
      });
  }

  public getGroupItems(group) {
    return this.displays.filter(item => item.group === group).map(item => item.item);
  }

  public getServiceGroupItems(group) {
    return dashboardConfigs.serviceQuotation.filter(item => item.group === group).map(item => item.item);
  }

  openTable(dashboardGroup, dashboardType) {
    this.onClickDashboard.emit({group: dashboardGroup, item: dashboardType});
  }

  openServiceTable(dashboardGroup, dashboardType) {
    this.onClickDashboard.emit({ group: dashboardGroup, item: dashboardType, table: 'service' });
  }

  openSurveyorTable(item) {
    this.onClickDashboard.emit({ surveyor_id: item.id, table: 'service' });
  }

  ngOnDestroy() {
    this.destroy = true;
    if (this.refreshSubArr.length > 0) {
      let sub = this.refreshSubArr.pop();
      if (sub) {
        sub.unsubscribe();
      }
    }
  }
}
