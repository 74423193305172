import {Component, EventEmitter, OnInit, Output, OnDestroy, Input, OnChanges, SimpleChanges} from '@angular/core';
import { egretAnimations } from '@shared/animations/egret-animations';
import { DashboardService } from '@shared/services/api/dashboard.service';
import { ThemeService } from '@shared/services/system/theme.service';
import { ServiceDashboardModel } from '@shared/models/service-dashboard.model';
import { LayoutService } from '@shared/services/system/layout.service';
import * as _ from 'lodash';
import { dashboardConfigs } from '@shared/config';
import { GlobalSettingService } from '@shared/services/system/global-setting.service';
import { GlobalSettingConfig } from '@shared/interfaces/global-setting-config';

@Component({
  selector: 'app-service-dashboard',
  templateUrl: './service-dashboard.component.html',
  animations: egretAnimations
})
export class ServiceDashboardComponent implements OnInit, OnDestroy, OnChanges {
  public loading = false;
  public isPageFirstRequest = true;
  _ = _;
  public dashboard: ServiceDashboardModel = new ServiceDashboardModel;
  public displays = dashboardConfigs.service;
  public groups: string[] = [];
  destroy = false;
  refreshSubArr = [];
  pmArr = [];
  pmCount = 0;
  @Output() onClickDashboard = new EventEmitter();
  @Input() startRequest = false;

  constructor(
    private dashboardService: DashboardService,
    public theme: ThemeService,
    private layout: LayoutService,
    private globalSettingService: GlobalSettingService,
  ) {
    this.displays.map(item => {
      if (this.groups.indexOf(item.group) === - 1) {
        this.groups.push(item.group);
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.startRequest) {
      this.refresh();
    } else {
      console.log('service dashboard change')
      if (this.refreshSubArr.length > 0) {
        let sub = this.refreshSubArr.pop();
        if (sub) {
          sub.unsubscribe();
        }
      }
    }
  }

  ngOnInit() {
    this.layout.onRefresh
      .takeWhile(() => !this.destroy)
      .subscribe(() => {
        this.isPageFirstRequest = true;
        this.dashboard = new ServiceDashboardModel;
        this.refresh();
      });
    this.globalSettingService.config$
      .takeWhile(() => !this.destroy)
      .subscribe((config: GlobalSettingConfig) => {
        if (config.useCompanyId && this.startRequest) {
          this.refresh();
        }
      });
  }

  public getGroupItems(group) {
    return this.displays.filter((item) => (item.group === group) && (item.item.indexOf('quote_request') === - 1)).map(item => item.item);
  }


  refresh() {
    if (this.refreshSubArr.length > 0) {
      let sub = this.refreshSubArr.pop();
      if (sub) {
        sub.unsubscribe();
      }
    }
    this.loading = true;
    this.displays.map((item) => {
      let type = item.item;
      let group = item.group;
      if (type.indexOf('quote_request') === - 1) {
        const refreshSub = this.dashboardService.getServiceDashboard(group, type)
          .takeWhile(() => !this.destroy)
          .finally(() => this.loading = false)
          .subscribe((data: number) => {
            this._.set(this.dashboard, [group, type], data);
            this.isPageFirstRequest = false;
          });
        this.refreshSubArr.push(refreshSub);
      }
    });
    this.dashboardService.getServicePmDashboard('service')
      .takeWhile(() => !this.destroy)
      .subscribe((data: any) => {
        this.pmArr = data.list;
        this.pmCount = _.sumBy(this.pmArr, (item) => {
          return item.num;
        });
      });
  }

  openTable(dashboardGroup, dashboardType) {
    this.onClickDashboard.emit({group: dashboardGroup, item: dashboardType});
  }

  openSurveyorTable(item) {
    this.onClickDashboard.emit({ surveyor_id: item.id, surveyor_source: 'service' });
  }

  ngOnDestroy() {
    this.destroy = true;
    if (this.refreshSubArr.length > 0) {
      let sub = this.refreshSubArr.pop();
      if (sub) {
        sub.unsubscribe();
      }
    }
  }

  getTabSum(group) {
    let total = 0;
    for (const key in this.dashboard[group]) {
      if (this.dashboard[group].hasOwnProperty(key)) {
        const element = this.dashboard[group][key];
        total += element;
      }
    }
    return total;
  }
}
