import {Component, Inject, OnInit} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';
import {AdvancedLayout, Image, PlainGalleryConfig} from '@ks89/angular-modal-gallery';

@Component({
  selector: 'app-gallery-dialog',
  templateUrl: './gallery-dialog.component.html',
  styleUrls: ['./gallery-dialog.component.scss']
})
export class GalleryDialogComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<GalleryDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      images: Image[],
      galleryConfig: PlainGalleryConfig,
      index: number
    }
  ) { }

  ngOnInit() {
    setTimeout(() => {
      this.data.galleryConfig = Object.assign(
        {}, this.data.galleryConfig, {
          layout: new AdvancedLayout(this.data.index, true)
        }
      );
    }, 0);
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

}
