import {Pipe, PipeTransform} from '@angular/core';
import {UserType} from '@shared/models/user-type.model';

@Pipe({name: 'displayUserType'})
export class DisplayUserTypePipe implements PipeTransform {
  transform(text: string, userTypes: UserType[]) {
    if (userTypes !== undefined) {
      const userType = userTypes.find(function (x) {
        return x.option_value === text;
      });
      return userType === undefined ? text : userType.option_value;
    } else {
      return text;
    }
  };
}
