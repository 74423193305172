import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import 'rxjs-compat/add/operator/finally';

@Component({
  selector: 'app-confirm-with-note',
  template: // html
    `
    <h1 mat-dialog-title translate>{{data?.title}}</h1>
    <div mat-dialog-content *ngIf="data?.message">{{ data?.message }}</div>
    <div>
      <mat-form-field class="full-width">
        <textarea matInput [formControl]="form.controls['note']" placeholder="Note" rows="5"></textarea>
      </mat-form-field>
    </div>
    <div mat-dialog-actions>
      <div fxFlex></div>
      <button mat-raised-button (click)="dialogRef.close()" color="warn" translate>
      <mat-icon>close</mat-icon>Close</button>
      <button mat-raised-button (click)="submit()" color="primary" translate>
      <mat-icon>check</mat-icon>OK</button>
    </div>
  `
})
export class ConfirmWithNoteComponent {
  form = new FormGroup({
    note: new FormControl(''),
  });

  constructor(
    public dialogRef: MatDialogRef<ConfirmWithNoteComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { title?: string; message?: string; },
  ) {
  }

  submit() {
    this.dialogRef.close({ ...this.form.value });
  }
}

