export class Conditions {
  [key: string]: {
    nullable: boolean;
    items?: object[];
    display_key?: string;
    select_key?: string;
    group_key?: string;
    item_key?: string;
    display_name?: string;
    value: string & { item: string; group: string } & any;
    useGroup?: boolean;
    multi?: boolean;
    display_value?: string;
    display_value_key?: string;
    hidden?: boolean;
  }
}
