import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AppLoaderService } from '@shared/services/system/app-loader/app-loader.service';
import { FileUploader } from 'ng2-file-upload';
import { DocumentService } from '@shared/services/api/document.service';
import { AuthGuard } from '@shared/services/system/auth/auth.guard';

import { NotifyService } from '@app/shared/services/notify.service';
import { UploaderService } from '@shared/services/uploader.service';

@Component({
  selector: 'app-document-create',
  templateUrl: './document-create.component.html'
})
export class DocumentCreateComponent implements OnInit {
  @Input() id;  // type_id
  @Input() type = '';
  @Input() model;
  @Input() isPopup = false;
  @Output() onCreated = new EventEmitter();

  public uploader: FileUploader = this.uploaderService.get({url: this.documentService.uploadUrl()});
  public itemForm: FormGroup;

  constructor(
    private fb: FormBuilder,
    public documentService: DocumentService,
    private loader: AppLoaderService,
    private auth: AuthGuard,
    private toast: NotifyService,
    private uploaderService: UploaderService,
  ) {
  }

  ngOnInit() {
    this.buildItemForm();
    this.setUploadEvent();
  }

  buildItemForm() {
    this.itemForm = this.fb.group({
      subject: ['', Validators.required],
      description: ['', Validators.required],
      type: ['']
    });
  }

  setUploadEvent() {
    this.uploader.onBuildItemForm = (fileItem, form) => {
      this.uploader.options.additionalParameter = {...this.itemForm.value, model: this.model, model_id: this.id};
    };
    let loader;
    this.uploader.onBeforeUploadItem = (fileItem => {
      loader = this.loader.open('Uploading...');
    });
    this.uploader.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
      if (status === 200) {
        this.toast.show('Add success!');
        this.uploader.clearQueue();
        this.onCreated.emit(true);
      } else {
        this.toast.show('Add failed!');
      }
      loader.close();
    };
  }

  add() {
    this.uploader.uploadAll();
  }
}
