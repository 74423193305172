import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-invoice-edit-popup',
  template: `
    <app-invoice-edit [id]="data.invoiceId" [view]="data.view" [inPopup]="true"></app-invoice-edit>`
})
export class InvoiceEditPopup implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { invoiceId: number; view: boolean },
    public dialogRef: MatDialogRef<InvoiceEditPopup>,
  ) {
  }

  ngOnInit() {
  }
}
