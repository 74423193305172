import { Injectable } from '@angular/core';
import { LaravelPageRequest } from '@shared/models/laravel-page-request.model';
import { HttpClient } from '@angular/common/http';
import { Conditions } from '@shared/models/conditions.model';
import { CompanyService } from '@shared/services/api/company.service';
import { CompanyModel } from '@shared/models/company.model';
import { ServiceService } from '@shared/services/api/service.service';
import { ModelAttributeOptions, ServiceOptions } from '@shared/models/options';
import { ModelAttributeOptionService } from '@shared/services/api/model-attribute-option.service';

@Injectable({
  providedIn: 'root'
})
export class PpmService {
  route_prefix = 'ppms';
  public conditions: Conditions = {
    status: {
      value: null,
      nullable: true,
      items: [
        {option_value: 'due', option_name: 'due'},
        {option_value: 'pending', option_name: 'pending'}
      ],
      display_key: 'option_value',
      select_key: 'option_value',
      display_name: 'Status'
    },
    category: {
      value: null,
      nullable: true,
      items: [],
      display_key: 'option_value',
      select_key: 'option_value',
      display_name: 'Category'
    },
    company_id: {
      value: null,
      nullable: false,
      items: [],
      display_key: 'name',
      select_key: 'id',
      display_name: 'Client',
      multi: false,
    },
    building: {
      value: null,
      nullable: false,
      items: [],
      display_key: 'name',
      select_key: 'id',
      display_name: 'Building',
      multi: true
    },
    dashboard_type: {
      value: null,
      nullable: true,
      items: [
        {option_value: 'ppm_to_log', option_name: 'PPM to Log'},
        {option_value: 'due_in_n', option_name: 'PPM Due in N days'},
        {option_value: 'over_due', option_name: 'PPM Overdue'},
        {option_value: 'certificate_missing', option_name: 'Certificate missing'},
        {option_value: 'compliance_sign_off', option_name: 'Compliance sign off'},
      ],
      display_key: 'option_value',
      select_key: 'option_value',
      display_name: 'Dashboard Type'
    }
  };

  constructor(
    private http: HttpClient,
    private companyService: CompanyService,
    private mao: ModelAttributeOptionService,
  ) {
    this.companyService.clients$.subscribe((clients: CompanyModel[]) => {
      this.conditions.company_id.items = clients;
    });
    this.getBuildings();
    this.getOptions();
  }

  getOptions() {
    this.mao.all()
      .subscribe((result: ModelAttributeOptions) => {
        this.conditions.category.items = result.asset.category;
      });
  }

  getBuildings() {
    this.buildings()
      .subscribe((data: any) => {
        this.conditions.building.items = data;
      });
  }

  index(params: any | LaravelPageRequest) {
    return this.http.post('ppm/assets', params.request());
  }

  search(param: any) {
    return this.http.post('ppm/search', param);
  }

  store(param: any) {
    return this.http.post(this.route_prefix, param);
  }

  assetDashboard() {
    return this.http.post('ppm/asset_dashboard', null);
  }

  assetStore(data: any) {
    return this.http.post('assets', data);
  }

  assetUpdate(id, data) {
    return this.http.put('assets/' + id, data);
  }

  assetDestroy(id) {
    return this.http.delete('assets/' + id);
  }

  assetShow(id) {
    return this.http.get('assets/' + id);
  }

  buildings() {
    return this.http.get('ppm/buildings');
  }
}
