import { Injectable } from '@angular/core';
import { ApiBaseService } from '@shared/services/api/api-base.service';
import { Conditions } from '@shared/models/conditions.model';
import { dashboardConfigs } from '@shared/config';
import { ModelAttributeOptions } from '@app/shared/models/options';
import { HttpClient } from '@angular/common/http';
import { ModelAttributeOptionService } from './model-attribute-option.service';
import { QuotationItemOptions, QuotationOptions } from '@shared/models/options';
import { Settings, SettingsService } from '@shared/services/system/settings.service';
import { get } from 'lodash';
import { LaravelPageRequest } from '@shared/models/laravel-page-request.model';
import { ServiceModel } from '@shared/models/service.model';
import { LaravelPageResponse } from '@shared/models/laravel-page-response.model';
import { tap } from 'rxjs/operators';
import { Router } from '@angular/router';

@Injectable()
export class QuotationService {
  route_prefix = 'quotations';
  public quotationOptions: QuotationOptions;
  public quotationItemOptions: QuotationItemOptions;
  public defaultMargin = 0;
  public useMargin = 0;
  public others = {};
  public conditions: Conditions = {
    status: {
      value: null,
      nullable: true,
      items: [],
      display_key: 'option_value',
      select_key: 'option_value',
      display_name: 'Status'
    },
    type: {
      value: null,
      nullable: true,
      items: [],
      display_key: 'option_value',
      select_key: 'option_value',
      display_name: 'Type'
    },
    dashboard_type: {
      value: null,
      nullable: true,
      useGroup: true,
      items: dashboardConfigs.quotation,
      display_name: 'Dashboard Type',
      group_key: 'dashboard_group',
      item_key: 'dashboard_type',
    },
    creator_id: {
      value: null,
      nullable: true,
      items: [],
      display_name: 'Creator',
      display_key: 'user_name',
      select_key: 'user_id',
      item_key: 'creator',
    }
  };
  public items: ServiceModel[] = [];
  public paginator = {
    totalElements: 0,
    pageNumber: 0,
    size: 20,
  };
  public pageLoading = false;

  constructor(
    public http: HttpClient,
    private maoService: ModelAttributeOptionService,
    private settingsService: SettingsService,
    private router: Router,
  ) {
    this.getOptions();
    this.getCreators();
    this.settingsService.all().subscribe((settings: Settings) => {
      this.defaultMargin = get(settings, 'quotation_item_margin.unit_margin', 0);
      this.useMargin = get(settings, 'quotation_item_margin.unit_margin', 0);
    });
  }

  initPage() {
    if (!this.items.length) {
      this.loadPage().subscribe();
    }
  }

  loadPage({keyword}: any = {keyword: ''}) {
    this.pageLoading = true;
    let request = new LaravelPageRequest(this.paginator.size, this.paginator.pageNumber, this.conditions, keyword, this.others);
    return this.index(request)
      .pipe(
        tap(
          (response: LaravelPageResponse) => {
            this.paginator.size = response.per_page;
            this.paginator.pageNumber = response.current_page;
            this.paginator.totalElements = response.total;
            this.items = response.data;
          }
        ),
      )
      .finally(() => this.pageLoading = false);
  }

  refresh() {
    this.loadPage().subscribe();
  }

  onFilter(keyword) {
    this.loadPage({keyword}).subscribe();
  }

  getOptions() {
    this.maoService.all()
      .subscribe((data: ModelAttributeOptions) => {
        this.conditions.status.items = data.quotation.status;
        this.conditions.type.items = data.quotation.type;
        this.quotationOptions = data.quotation;
        this.quotationItemOptions = data.quotation_item;
      });
  }

  getCreators() {
    return this.http.post('quotations/get-creators', {})
        .subscribe((res: any) => {
          this.conditions.creator_id.items = res.items;
        });
  }

  approval(status, quotation_id) {
    return this.http.post('quotation/approval', {status, quotation_id});
  }

  generateService(quotationId, body) {
    return this.http.post(`${this.route_prefix}/${quotationId}/generate-service`, body);
  }

  clientReview(quotationId, {note, user_ids}) {
    return this.http.post(`${this.route_prefix}/${quotationId}/client-review`, {description: note, user_ids});
  }

  approve(quotationId) {
    return this.http.post(`${this.route_prefix}/${quotationId}/approve`, null);
  }

  reject(quotationId) {
    return this.http.post(`${this.route_prefix}/${quotationId}/reject`, null);
  }

  index(params: LaravelPageRequest | any) {
    return this.http.get(this.route_prefix, {params: params.request()});
  }

  store(data) {
    return this.http.post(this.route_prefix, data);
  }

  show(id) {
    return this.http.get(this.route_prefix + '/' + id);
  }

  update(id, data) {
    return this.http.put(this.route_prefix + '/' + id, data);
  }

  changeConditionValue(key, value) {
    this.conditions[key].value = value;
  }

  clickPage({offset}) {
    this.paginator.pageNumber = offset + 1;
    this.loadPage().subscribe();
  }

  openEditPage({id}) {
    this.router.navigate([`/quotation/edit/${id}`]);
  }

  openCreatePage() {
    this.router.navigate(['/quotation/create']);
  }
}
