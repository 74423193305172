import { Injectable } from '@angular/core';
import {environment} from '@env/environment';

@Injectable()
export class ReportService {

  constructor() {
  }

  baseUrl() {
    return environment.apiURL + environment.adminPrefix;
  }
}
