import { Injectable, Inject, Renderer2 } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { getQueryParam } from '@shared/helpers/url.helper';

interface ITheme {
  name: string;
  baseColor?: string;
  isActive?: boolean;
}

@Injectable()
export class ThemeService {
  public egretThemes: ITheme[] = [{
    'name': 'egret-dark-purple',
    'baseColor': '#9c27b0',
    'isActive': false
  }, {
    'name': 'egret-dark-pink',
    'baseColor': '#e91e63',
    'isActive': false
  }, {
    'name': 'egret-blue',
    'baseColor': '#247ba0',
    'isActive': true
  }, {
    'name': 'egret-indigo',
    'baseColor': '#3f51b5',
    'isActive': false
  }];
  public activatedTheme: ITheme;
  private dataColors = {
    pending: '#3C7D26',
    accepted: '#FFC038',
    cancelled: '#5F4CA1',
    completed: '#1A55A4',
    approved: '#407DC5',
    active: '#64A24D',
    inactive: '#380000',
    paid: '#EF6C00',
    delivered: '#4E342E',
    survey_completed: '#e91e63',

    quote_required: '#64A24D',
    compliance_to_sign_off: '#5F4CA1',
    audit_service: '#247ba0',

    ppm_to_log: '#3C7D26',
    due_in_n_days: '#FFC038',
    non_compliant: '#247ba0',
    ppm_overdue: '#e91e63',
    cert_missing: '#1A55A4',
    compliance_sign_off: '#5F4CA1',

    not_trading: '#3C7D26',
    to_be_assigned: '#407DC5',

    uf: '#3e56a0',
    capex: '#247ba0',

    logged: '#3C7D26',
    assigned: '#247ba0',
    in_progress: '#07a029',

    client: '#FFC038',
    supplier: '#247ba0',
    service_supplier: '#3e56a0',
    product_supplier: '#07a029',
    no_company: '#75a011',
    agent: '#3C7D26',

    open_health_and_safety: '#3C7D26',
    open_non_compliant: '#247ba0',
    open_business_critical: '#1A55A4',
    out_standing_quote: '#FFC038',

    heating_gas_quote_approval: '#3C7D26',
    plumbing_quote_approval: '#247ba0',
    electrical_quote_approval: '#1A55A4',
    general_quote_approval: '#1A55A4',
    heating_gas_quote_request: '#07a029',
    plumbing_quote_request: '#5F4CA1',
    electrical_quote_request: '#EF6C00',
    general_quote_request: '#5F4CA1',
    quotes_awaiting_action: '#3C7D26',
    quotes_awaiting_approval: '#247ba0',
    quotes_uploaded_today: '#1A55A4',
    quotes_approved_today: '#3C7D26',
    ac_quote_approval: '#247ba0',
    ac_quote_request: '#1A55A4',
    another_visit_quote_approval: '#3C7D26',

    heating_gas: '#1A55A4',
    plumbing: '#3C7D26',
    electrical: '#247ba0',
    general: '#5F4CA1',
    another_visit: '#1A55A4',
    parts_required: '#EF6C00',
    ppm: '#3C7D26',
    restricted_trading: '#1A55A4',
    attended_jobs: '#FFC038',

    // quotation status
    client_review: '#3C7D26',
    declined: '#e91e63',
    declined_with_message: '#EF6C00',
    awaiting: '#3e56a0',
    quoted: '#75a011',
    failed_benchmarking: '#1A55A4',
    holding_pool: '#3C7D26',
    more_info: '#FFC038',
    collected: '#67de',
    invoiced: '#21de',
    issued: '#3d8',

    send: '#e91e63',
    view: '#3C7D26',

    daily_completed_job: '#1A55A4',
    weekly_completed_job: '#3C7D26',
    monthly_completed_job: '#FFC038'
  };

  constructor(
    @Inject(DOCUMENT) private document: Document
  ) {
  }

  // Invoked in AppComponent and apply 'activatedTheme' on startup
  applyMatTheme(r: Renderer2) {
    /*
    ****** (SET YOUR DEFAULT THEME HERE) *******
    * Assign new Theme to activatedTheme
    */
    // this.activatedTheme = this.egretThemes[0];
    // this.activatedTheme = this.egretThemes[1];
    this.activatedTheme = this.egretThemes[2];
    // this.activatedTheme = this.egretThemes[3];

    // *********** ONLY FOR DEMO **********
    this.setThemeFromQuery();
    // ************************************

    this.changeTheme(r, this.activatedTheme);
  }

  changeTheme(r: Renderer2, theme: ITheme) {
    r.removeClass(this.document.body, this.activatedTheme.name);
    r.addClass(this.document.body, theme.name);
    this.flipActiveFlag(theme);
  }

  flipActiveFlag(theme: ITheme) {
    this.egretThemes.forEach((t) => {
      t.isActive = false;
      if (t.name === theme.name) {
        t.isActive = true;
        this.activatedTheme = theme;
      }
    });
  }

  // *********** ONLY FOR DEMO **********
  setThemeFromQuery() {
    let themeStr = getQueryParam('theme');
    try {
      this.activatedTheme = JSON.parse(themeStr);
      this.flipActiveFlag(this.activatedTheme);
    } catch (e) {
    }
  }

  getStrColor(str) {
    if (this.dataColors.hasOwnProperty(str)) {
      return this.dataColors[str];
    } else {
      return '#032965';
    }
  }
}
