import {Injectable} from '@angular/core';
import {ServiceVisitEditPopupComponent} from '@shared/components/business/service-visit/service-visit-edit/service-visit-edit-popup.component';
import {MatDialog} from '@angular/material';
import {NoteEditPopupComponent} from '@shared/components/business/note/note-edit/note-edit-popup.component';
import {DocumentEditPopupComponent} from '@shared/components/business/document/document-edit/document-edit-popup.component';
import {InvoiceItemCreatePopupComponent} from '@shared/components/business/invoice/invoice-item-create/invoice-item-create-popup.component';
import {ServiceEditPopupComponent} from '@shared/components/business/service/service-edit/service-edit-popup.component';
import {QuotationItemCreatePopupComponent} from '@shared/components/business/quotation/quotation-item-create/quotation-item-create-popup.component';
import {ServiceCertificateVerifyPopupComponent} from '@shared/components/base/service-certificate-verify/service-certificate-verify-popup.component';
import {PurchaseOrderItemCreatePopupComponent} from '@shared/components/business/purchase-order/purchase-order-item-create/purchase-order-item-create-popup.component';
import {InvoiceItemEditPopupComponent} from '@shared/components/business/invoice/invoice-item-edit/invoice-item-edit-popup.component';
import {QuotationItemEditPopupComponent} from '@shared/components/business/quotation/quotation-item-edit/quotation-item-edit-popup.component';
import {PurchaseOrderItemEditPopupComponent} from '@shared/components/business/purchase-order/purchase-order-item-edit/purchase-order-item-edit-popup.component';
import {AssetCreatePopupComponent} from '@shared/components/business/asset/asset-create/asset-create-popup.component';
import {ServiceVisitCreatePopupComponent} from '@shared/components/business/service-visit/service-visit-create/service-visit-create-popup.component';
import {PurchaseOrderCreatePopupComponent} from '@shared/components/business/purchase-order/purchase-order-create/purchase-order-create-popup.component';
import {QuotationEditPopupComponent} from '@shared/components/business/quotation/quotation-edit/quotation-edit-popup.component';
import {PurchaseOrderEditPopupComponent} from '@shared/components/business/purchase-order/purchase-order-edit/purchase-order-edit-popup.component';
import {QuotationClientReviewCreate} from '../components/business/quotation/quotation-client-review-create.component';
import { ServiceCreatePopupComponent } from '../components/business/service/service-create/service-create-popup.component';

@Injectable()
export class PopupService {
  constructor(
    public dialog: MatDialog,
  ) {
  }

  openCreateServiceVisitPopup({serviceId, rateType}) {
    return this.dialog.open(ServiceVisitCreatePopupComponent, {
      width: '80%',
      disableClose: false,
      data: {id: serviceId, rateType}
    });
  }

  openServiceVisitEditPage(visit_id) {
    return this.dialog.open(ServiceVisitEditPopupComponent, {
      width: '80%',
      disableClose: true,
      data: {id: visit_id}
    });
  }

  openNoteEditPage(note_id) {
    return this.dialog.open(NoteEditPopupComponent, {
      width: '80%',
      disableClose: false,
      data: {id: note_id}
    });
  }

  openDocumentEditPage(document_id) {
    return this.dialog.open(DocumentEditPopupComponent, {
      width: '80%',
      disableClose: false,
      data: {id: document_id}
    });
  }

  openInvoiceItemCreatePage(invoice_id) {
    return this.dialog.open(InvoiceItemCreatePopupComponent, {
      width: '80%',
      disableClose: false,
      data: {id: invoice_id}
    });
  }

  openServiceEditPage(service_id, view = false) {
    return this.dialog.open(ServiceEditPopupComponent, {
      width: '80%',
      height: '90vh',
      disableClose: false,
      data: {id: service_id, view}
    });
  }

  openServiceCreatePage(engineer_id = null, expect_start_time = null) {
    return this.dialog.open(ServiceCreatePopupComponent, {
      width: '80%',
      height: '90vh',
      disableClose: false,
      data: { engineer_id: engineer_id, expect_start_time: expect_start_time }
    });
  }

  openQuotationItemCreatePage(quotation_id, services) {
    return this.dialog.open(QuotationItemCreatePopupComponent, {
      width: '80%',
      disableClose: false,
      data: {id: quotation_id, services}
    });
  }

  openPurchaseOrderItemCreatePage(purchase_order_id, services) {
    return this.dialog.open(PurchaseOrderItemCreatePopupComponent, {
      width: '80%',
      disableClose: false,
      data: {id: purchase_order_id, services}
    });
  }

  openServiceCertificateVerifyPopup(document_id) {
    return this.dialog.open(ServiceCertificateVerifyPopupComponent, {
      width: '80%',
      disableClose: false,
      data: {id: document_id}
    });
  }

  openInvoiceItemEditPage(invoice_item_id, view = false) {
    return this.dialog.open(InvoiceItemEditPopupComponent, {
      width: '80%',
      disableClose: false,
      data: {id: invoice_item_id, view}
    });
  }

  openQuotationItemEditPage(quotation_item_id) {
    return this.dialog.open(QuotationItemEditPopupComponent, {
      width: '80%',
      disableClose: false,
      data: {id: quotation_item_id, view: false}
    });
  }

  openPurchaseOrderItemEditPage(purchase_order_item_id) {
    return this.dialog.open(PurchaseOrderItemEditPopupComponent, {
      width: '80%',
      disableClose: false,
      data: {id: purchase_order_item_id}
    });
  }

  openAssetCreatePage(companyId, buildingId) {
    return this.dialog.open(AssetCreatePopupComponent, {
      width: '80%',
      disableClose: false,
      data: {companyId, buildingId}
    });
  }

  openPurchaseOrderCreatePopup({serviceId, clientId}) {
    return this.dialog.open(PurchaseOrderCreatePopupComponent, {
      width: '80%',
      disableClose: true,
      data: {serviceId, clientId}
    });
  }

  openQuotationEditPage(quotationId) {
    return this.dialog.open(QuotationEditPopupComponent, {
      disableClose: true,
      data: {id: quotationId}
    });
  }

  openPurchaseOrderEditPage(purchaseOrderId) {
    return this.dialog.open(PurchaseOrderEditPopupComponent, {
      width: '80%',
      disableClose: true,
      data: {id: purchaseOrderId}
    });
  }

  openQuotationClientReview(clientId) {
    return this.dialog.open(QuotationClientReviewCreate, {
      disableClose: false,
      data: {clientId}
    });
  }
}
