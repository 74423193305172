import { ApiBaseService } from '@shared/services/api/api-base.service';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';

@Injectable()
export class FinanceService extends ApiBaseService {
  route_prefix = 'finances';

  awaitingCosts() {
    return this.http.get(`${this.route_prefix}/awaiting-costs`);
  }

  pendingSubmission() {
    return this.http.get(`${this.route_prefix}/pending-submission`);
  }

  applications() {
    return this.http.get(`${this.route_prefix}/applications`);
  }

  certificates() {
    return this.http.get(`${this.route_prefix}/certificates`);
  }

  submitApplication(serviceId, { materials, plants, others } = { materials: [], plants: [], others: [] }) {
    return this.http.post(`${this.route_prefix}/submit-application`, { service_id: serviceId, materials, plants, others });
  }

  moveToAwaitingCosts(serviceId) {
    return this.http.post(`${this.route_prefix}/move-to-awaiting-costs`, { service_id: serviceId });

  }

  budgetDate(companyId) {
    return this.http.get(`${this.route_prefix}/budget-date/${companyId}`);
  }

  uploadInvoice() {
    return this.http.post(`${this.route_prefix}/upload-invoice`, {})

  }

  manuallySubmit() {
    return this.http.post(`${this.route_prefix}/manually-submit`, {});
  }

  generateCertificateFile(invoiceId) {
    return this.http.post(this.route_prefix + '/' + invoiceId + '/generate-certificate-file', null);
  }

  uploadCertificateFileUrl(invoiceId) {
    return environment.apiURL + environment.adminPrefix + this.route_prefix + '/' + invoiceId + '/upload-certificate-file';
  }
}
