import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Conditions} from '@shared/models/conditions.model';
import {isArray, isNumber, isObject, isString} from 'lodash';

@Component({
  selector: 'app-table-condition',
  templateUrl: './condition.component.html'
})
export class AppTableConditionComponent implements OnInit {
  @Input() conditions: Conditions;
  @Output() changeConditionEvent = new EventEmitter<number>();

  objectKeys = Object.keys;
  isArray = isArray;
  isObject = isObject;
  isNumber = isNumber;
  isString = isString;

  constructor() {
  }

  ngOnInit() {
  }

  changeCondition(conditionKey, $event) {
    if ($event && this.conditions[conditionKey].hasOwnProperty('display_value') &&
      this.conditions[conditionKey].hasOwnProperty('display_value_key')) {
      this.conditions[conditionKey].display_value = $event[this.conditions[conditionKey].display_value_key];
    }
    this.changeConditionEvent.emit();
  }

  removeValue(conditionKey, value) {
    if (isArray(value)) {
      this.conditions[conditionKey].value.filter(condition => condition[conditionKey].value !== value);
    } else {
      this.conditions[conditionKey].value = null;
    }
    this.changeCondition(conditionKey, value);
  }
}
