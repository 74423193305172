import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

import 'rxjs-compat/add/operator/startWith';
import 'rxjs-compat/add/operator/debounceTime';
import 'rxjs-compat/add/operator/distinctUntilChanged';
import 'rxjs-compat/add/operator/switchMap';

@Component({
  selector: 'app-service-visit-create-popup',
  template: `
    <h1 mat-dialog-title>{{'Service Visit Create' | translate}}</h1>
    <app-service-visit-create mat-dialog-content [isPopup]="true" [id]="data?.id" [rateType]="data?.rateType"
                              (onCreated)="dialogRef.close(true)"></app-service-visit-create>`
})
export class ServiceVisitCreatePopupComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { id: number, rateType: string },
    public dialogRef: MatDialogRef<ServiceVisitCreatePopupComponent>,
  ) {
  }

  ngOnInit() {
  }
}
