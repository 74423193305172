import { Injectable } from '@angular/core';
import { ApiBaseService } from '@shared/services/api/api-base.service';

@Injectable()
export class NoteService extends ApiBaseService {
  route_prefix = 'notes';

  indexByTypeAndTypeId(type, typeId) {
    return this.http.get(`${this.route_prefix}/${type}/${typeId}`);
  }

  severityStyle(value) {
    let style =  {'background-color': '', 'color': ''};
    value = parseInt(value);
    switch (value) {
      case 1:
        style = {'background-color': '#c93756', 'color': 'white'};
        break;
      case 2:
        style = {'background-color': '#9b4400', 'color': 'white'};
        break;
      case 3:
        style = {'background-color': '#ffa631', 'color': 'white'};
        break;
      case 4:
        style = {'background-color': '#bbcdc5', 'color': ''};
        break;
      case 5:
        style = {'background-color': '', 'color': ''};
        break;
      default:
        break;
    }
    return style;
  }

}
