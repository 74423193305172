import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AppLoaderService } from '@shared/services/system/app-loader/app-loader.service';
import { BuildingService } from '@shared/services/api/building.service';
import { ModelAttributeOptionService } from '@shared/services/api/model-attribute-option.service';
import { BuildingOptions, ModelAttributeOptions } from '@shared/models/options';
import { CompanyService } from '@shared/services/api/company.service';
import { NotifyService } from '@app/shared/services/notify.service';
import { SimpleReuseStrategy } from '@app/simple-reuse-strategy';

@Component({
  selector: 'app-building-create',
  templateUrl: './building-create.component.html',
  styleUrls: ['./building-create.component.scss'],
  providers: [SimpleReuseStrategy],
})
export class BuildingCreateComponent implements OnInit {
  @Output() onSubmitted: EventEmitter<any> = new EventEmitter();
  public loading$ = false;
  public itemFormGroup: FormGroup = new FormGroup({
    name: new FormControl('', [Validators.required]),
    alias: new FormControl(''),
    code: new FormControl(''),
    address1: new FormControl(''),
    address2: new FormControl(''),
    town: new FormControl(''),
    city: new FormControl(''),
    postcode: new FormControl(''),
    country: new FormControl(''),
    latitude: new FormControl(''),
    longitude: new FormControl(''),
    email: new FormControl('', [Validators.email]),
    phone: new FormControl(''),
    company_id: new FormControl(null),
    status: new FormControl(''),
  });

  public get clients() {
    return this.companyService.clients;
  };

  public buildingOption: BuildingOptions;

  constructor(
    private _service: BuildingService,
    private loader: AppLoaderService,
    private maoService: ModelAttributeOptionService,
    private companyService: CompanyService,
    private toast: NotifyService,
  ) {
  }

  ngOnInit() {
    this.getOptions();
  }

  getOptions() {
    this.maoService.all()
      .subscribe((data: ModelAttributeOptions) => {
        this.buildingOption = data.building;
      });
  }

  submit() {
    let loader = this.loader.open();
    this._service.store({...this.itemFormGroup.value})
      .finally(() => {
        loader.close();
      })
      .subscribe(() => {
        this.onSubmitted.emit();
        this.toast.show('Building Added!');
        SimpleReuseStrategy.deleteRouteSnapshot('///settings/building/create');
      });
  }
}
