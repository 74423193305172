import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Component, Inject, OnInit } from '@angular/core';
import { AuthGuard } from '@shared/services/system/auth/auth.guard';

import { FormControl, FormGroup } from '@angular/forms';
import { SalaryTimeService } from '@shared/services/api/salary-time.service';
import 'rxjs-compat/add/operator/finally';
import { NotifyService } from '../../notify.service';

@Component({
  selector: 'app-reject-service-time',
  template: // html
    `
    <h1 mat-dialog-title translate>Reject job</h1>
    <div>
      <mat-form-field class="full-width">
        <textarea matInput [formControl]="form.controls['note']" placeholder="Note" rows="5"></textarea>
      </mat-form-field>
    </div>
    <div mat-dialog-actions>
      <div fxFlex></div>
      <button mat-raised-button (click)="dialogRef.close()" color="warn" translate>
      <mat-icon>close</mat-icon>Close</button>
      <button mat-raised-button (click)="submit()" color="primary" translate>
      <mat-icon>check</mat-icon>OK</button>
    </div>
  `
})
export class RejectServiceComponent implements OnInit {
  form = new FormGroup({
    note: new FormControl(''),
  });

  constructor(
    public dialogRef: MatDialogRef<RejectServiceComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { timeId: string & number },
    public salaryTimeService: SalaryTimeService,
    public auth: AuthGuard,
    public toast: NotifyService,
  ) {
  }

  ngOnInit(): void {
  }

  submit() {
    this.dialogRef.close({ ...this.form.value });
  }
}

