import { Component, OnInit, Injector, Input, OnDestroy } from '@angular/core';
import { egretAnimations } from '@shared/animations/egret-animations';
import { BaseTableComponent } from '@shared/components/base/base-table.component';
import { Conditions } from '@shared/models/conditions.model';
import { ModelAttributeOptionService } from '@shared/services/api/model-attribute-option.service';
import { ModelAttributeOptions } from '@shared/models/options';
import { AssetService } from '@shared/services/api/asset.service';
import { GlobalSettingService } from '@shared/services/system/global-setting.service';
import { GlobalSettingConfig } from '@shared/interfaces/global-setting-config';

@Component({
  selector: 'app-asset-table',
  templateUrl: './asset-table.component.html',
  animations: egretAnimations
})
export class AssetTableComponent extends BaseTableComponent implements OnInit {
  @Input() inPopup = false;

  public get conditions() {
    return this._service.conditions;
  }

  public itemName = 'Asset';
  public urlPrefix = 'settings/asset';
  public items: any[];

  constructor(
    protected injector: Injector,
    public _service: AssetService,
    private maoService: ModelAttributeOptionService,
    private globalSettingService: GlobalSettingService,
  ) {
    super(injector);
  }

  ngOnInit() {
    this.layout.onRefresh
      .takeWhile(() => !this.destroy)
      .subscribe(() => this.refresh());
    this.globalSettingService.config$
      .takeWhile(() => !this.destroy)
      .subscribe((config: GlobalSettingConfig) => {
        if (config.useCompanyId) {
          this.refresh();
        }
      });
  }
}
