import { Injectable } from '@angular/core';
import { GlobalSettingService } from '@shared/services/system/global-setting.service';
import { UserModel } from '@shared/models/user.model';
import { CompanyModel } from '@shared/models/company.model';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  public company: CompanyModel;
  public user: UserModel;

  constructor(
    private globalSettingService: GlobalSettingService,
    private http: HttpClient,
  ) {
    this.globalSettingService.useCompanyId$.subscribe((id) => {
        if (id) {
          this.me();
        }
      }
    );
  }

  public me() {
    this.http.get('auth/me').subscribe((response: { user: UserModel, company: CompanyModel }) => {
      this.company = response.company;
      this.user = response.user;
    });
  }
}
