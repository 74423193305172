import { Injectable } from '@angular/core';

@Injectable()
export class DisplayColumnsService {
  public configs = {
    service: {
      display_name: 'Service',
      key: 'service',
      displayedColumns: [
        {display: 'Job No', key: 'client_reference', useColor: false, canSort: true, columnTable: 'services', sortColumnName: 'id'},
        {display: 'Type', key: 'service_type', useColor: false, canSort: true,  columnTable: 'services', sortColumnName: 'service_type'},
        {display: 'Client', key: 'client_name', useColor: false,  canSort: true, columnTable: 'services', sortColumnName: 'client_name'},
        {display: 'Building', key: 'building_name', useColor: false, canSort: true, columnTable: 'services', sortColumnName: 'building_name'},
        {display: 'Discipline', key: 'discipline', useColor: false, canSort: true, columnTable: 'services', sortColumnName: 'discipline'},
        {display: 'Engineer', key: 'engineers', useColor: false,  canSort: false},
        {display: 'Priority', key: 'severity', useColor: false,  canSort: true, columnTable: 'services', sortColumnName: 'severity'},
        {display: 'Date', key: 'created_at', useColor: false,  canSort: true, columnTable: 'services', sortColumnName: 'created_at'},
        {display: 'Status', key: 'multi_status', useColor: true,  canSort: true, columnTable: 'services', sortColumnName: 'status'},
      ]
    },
    ppm: {
      displayName: 'Ppm',
      key: 'ppm',
      displayedColumns: [
        {display: 'Job No', key: 'id', width: 100, canSort: true, columnTable: 'assets', sortColumnName: 'id'},
        {display: 'Client', key: 'company_name', canSort: true, columnTable: 'assets', sortColumnName: 'company_name'},
        {display: 'Building', key: 'building_name', canSort: true, columnTable: 'assets', sortColumnName: 'building_name'},
        {display: 'Category', key: 'category', canSort: true, columnTable: 'assets', sortColumnName: 'category'},
        {display: 'Name', key: 'name', canSort: true, columnTable: 'assets', sortColumnName: 'name'},
        {display: 'Service Interval', key: 'service_interval', width: 100, canSort: true, columnTable: 'assets', sortColumnName: 'service_interval'},
        {display: 'Last Service Time', key: 'last_service_time', canSort: true, columnTable: 'assets', sortColumnName: 'last_service_time'},
        {display: 'Next Service Time', key: 'next_service_time', canSort: true, columnTable: 'assets', sortColumnName: 'next_service_time'},
        {display: 'Status', key: 'ppm_status', useColor: true, width: 100, canSort: false},
      ]
    },
    quotation: {
      display_name: 'Quotation',
      key: 'quotation',
      displayedColumns: [
        {display: 'Id', key: 'id', useColor: false, canSort: true, columnTable: 'quotations', sortColumnName: 'id'},
        {display: 'Client', key: 'target_company_name', useColor: false, canSort: true, columnTable: 'quotations', sortColumnName: 'target_company_name'},
        {display: 'Building', key: 'data.building_name', useColor: false, canSort: false},
        {display: 'Discipline', key: 'data.discipline', useColor: false, canSort: false},
        {display: 'Total', key: 'total', useColor: false, canSort: true, columnTable: 'quotations', sortColumnName: 'total'},
        {display: 'Type', key: 'type', useColor: false, canSort: true, columnTable: 'quotations', sortColumnName: 'type'},
        {display: 'Creator', key: 'create_user_name', useColor: false, canSort: true, columnTable: 'quotations', sortColumnName: 'create_user_name'},
        {display: 'Date', key: 'created_at', useColor: false, canSort: true, columnTable: 'quotations', sortColumnName: 'created_at'},
        {display: 'Status', key: 'status', useColor: true, canSort: true, columnTable: 'quotations', sortColumnName: 'status'},
      ]
    },
    purchase_order: {
      display_name: 'Purchase Order',
      key: 'purchase_order',
      deleteButton: false,
      displayedColumns: [
        {display: 'Id', key: 'id_with_service_code', useColor: false, canSort: true, columnTable: 'purchase_orders', sortColumnName: 'id'},
        {display: 'Target Company', key: 'target_company_name', useColor: false, canSort: true, columnTable: 'purchase_orders', sortColumnName: 'target_company_name'},
        {display: 'Source Company', key: 'source_company_name', useColor: false, canSort: true, columnTable: 'purchase_orders', sortColumnName: 'source_company_name'},
        {display: 'Total', key: 'total', useColor: false, canSort: true, columnTable: 'purchase_orders', sortColumnName: 'total'},
        {display: 'Status', key: 'status', useColor: true, canSort: true, columnTable: 'purchase_orders', sortColumnName: 'status'},
      ]
    },
    invoice: {
      display_name: 'Invoice',
      key: 'invoice',
      displayedColumns: [

        {display: 'Id', key: 'id', useColor: false},
        {display: 'Type', key: 'type', useColor: false},
        {display: 'Client', key: 'target_company_name', useColor: false},
        {display: 'Total', key: 'total', useColor: false},
        {display: 'Vat', key: 'vat', useColor: false},
        {display: 'Subtotal', key: 'subtotal', useColor: false},
        {display: 'Status', key: 'status', useColor: true},
      ]
    },
    company: {
      display_name: 'Company',
      key: 'company',
      displayedColumns: [

        {display: 'Id', key: 'id', useColor: false},
        {display: 'Name', key: 'name', useColor: false},
        {display: 'Email', key: 'email', useColor: false},
        {display: 'Types', key: 'types_show', useColor: true, isArray: true},
        {display: 'Phone', key: 'phone', useColor: false},
        {display: 'Industry', key: 'industry', useColor: false},
        {display: 'Status', key: 'status', useColor: true},
      ]
    },
    building: {
      display_name: 'Building',
      key: 'building',
      displayedColumns: [

        {display: 'Id', key: 'id', useColor: false},
        {display: 'Name', key: 'name', useColor: false},
        {display: 'Company', key: 'company_name', useColor: false},
        {display: 'Status', key: 'status', useColor: true},
        {display: 'Email', key: 'email', useColor: false},
        {display: 'Phone', key: 'phone', useColor: false},
        {display: 'Code', key: 'code', useColor: false},
      ]
    },
    application: {
      display_name: 'Application',
      key: 'application',
      displayedColumns: [

        {display: 'Id', key: 'id', useColor: false},
        {display: 'Total', key: 'total', useColor: false},
        {display: 'Contractor', key: 'source_company_name', useColor: false},
        {display: 'Client', key: 'target_company_name', useColor: false},
        {display: 'Status', key: 'status', useColor: true},
      ]
    },
    purchase_order_item: {
      display_name: 'Purchase Order Item',
      key: 'purchase_order_item',
      displayedColumns: [
        {display: 'Type', key: 'item_type', useColor: false},
        {display: 'Quantity', key: 'item_quantity', useColor: false},
        {display: 'Name', key: 'item_name', useColor: false},
        {display: 'Unit Price', key: 'item_unit_price', useColor: false},
        {display: 'Total Price', key: 'item_total_price', useColor: false},
        {display: 'Status', key: 'item_status', useColor: true},
      ]
    },
    quotation_item: {
      display_name: 'Quotation Item',
      key: 'quotation_item',
      displayedColumns: [
        {display: 'Id', key: 'id', useColor: false},
        {display: 'Type', key: 'item_type', useColor: false},
        {display: 'Total Price', key: 'item_total_price', useColor: false},
        {display: 'Service Id', key: 'item_type_id', useColor: false},
        {display: 'Name', key: 'item_name', useColor: false},
        {display: 'Unit Price', key: 'item_unit_price', useColor: false},
        {display: 'Quantity', key: 'item_quantity', useColor: false},
        {display: 'Status', key: 'item_status', useColor: true},
      ]
    },
    invoice_item: {
      display_name: 'Invoice Item',
      key: 'invoice_item',
      displayedColumns: [

        {display: 'Id', key: 'id', useColor: false},
        {display: 'Type', key: 'item_type', useColor: false},
        {display: 'Total Price', key: 'item_total_price', useColor: false},
        {display: 'Service Id', key: 'item_type_id', useColor: false},
        {display: 'Name', key: 'item_name', useColor: false},
        {display: 'Unit Price', key: 'item_unit_price', useColor: false},
        {display: 'Quantity', key: 'item_quantity', useColor: false},
        {display: 'Status', key: 'item_status', useColor: true},
      ]
    },
    service_visit: {
      display_name: 'Service Visit',
      key: 'service_visit',
      displayedColumns: [
        {display: 'Id', key: 'id', useColor: false},
        {display: 'User Name', key: 'user_name', useColor: false},
        {display: 'Check In Time', key: 'check_in_time', useColor: false},
        {display: 'Check Out Time', key: 'check_out_time', useColor: false},
        {display: 'Service Status', key: 'service_status', useColor: true},
        {display: 'Observer', key: 'observer', useColor: false},
        {display: 'Service Id', key: 'service_id', useColor: false},
      ]
    },
    service_visit_cost: {
      display_name: 'Service Visit Cost',
      key: 'service_visit_cost',
      displayedColumns: [
        {display: 'Visit Id', key: 'id', useColor: false},
        {display: 'Standard Hours', key: 'standard_hours', useColor: false},
        {display: 'Standard Minutes', key: 'standard_minutes', useColor: false},
        {display: 'Standard Total', key: 'standard_total', useColor: false, isCurrency: true},
        {display: 'Overtime Hours', key: 'overtime_hours', useColor: false},
        {display: 'Overtime Minutes', key: 'overtime_minutes', useColor: false},
        {display: 'Overtime Total', key: 'overtime_total', useColor: false, isCurrency: true},
        {display: 'Total Labour', key: 'total_labour', useColor: false, isCurrency: true},
      ]
    },
    note: {
      display_name: 'Note',
      key: 'note',
      displayedColumns: [

        {display: 'Id', key: 'id', useColor: false},
        {display: 'Title', key: 'title', useColor: false},
        {display: 'Note', key: 'note', useColor: false},
        {display: 'Severity', key: 'severity', useColor: false},
      ]
    },
    asset: {
      display_name: 'Asset',
      key: 'asset',
      displayedColumns: [

        {display: 'Id', key: 'id', useColor: false},
        {display: 'Company Name', key: 'company_name', useColor: false},
        {display: 'Building Name', key: 'building_name', useColor: false},
        {display: 'Category', key: 'category', useColor: false},
        {display: 'Name', key: 'name', useColor: false},
        {display: 'Service Interval', key: 'service_interval', useColor: false},
        {display: 'Last Service Time', key: 'last_service_time', useColor: false},
        {display: 'Next Service Time', key: 'next_service_time', useColor: false},
        {display: 'Status', key: 'status', useColor: true},
      ]
    },
    sale: {
      display_name: 'Sale',
      key: 'sale',
      displayedColumns: [

        {display: 'Id', key: 'id', useColor: false},
        {display: 'Type', key: 'item_type', useColor: false},
        {display: 'Name', key: 'item_name', useColor: false},
        {display: 'Desc', key: 'item_description', useColor: false},
        {display: 'Total', key: 'item_total_price', useColor: false},
        {display: 'Unit Price', key: 'item_unit_price', useColor: false},
        {display: 'Unit Duration', key: 'item_unit_duration', useColor: false},
        {display: 'Quantity', key: 'item_quantity', useColor: false},
        {display: 'Operator', key: 'operator', useColor: false},
        {display: 'Status', key: 'item_status', useColor: true},
      ]
    },
    approval: {
      display_name: 'Approval',
      key: 'approval',
      displayedColumns: [
        {display: 'Id', key: 'id', useColor: false},
        {display: 'Subject', key: 'subject', useColor: false},
        {display: 'Create User', key: 'create_user_name', useColor: false},
        {display: 'Create Company', key: 'company_name', useColor: false},
        {display: 'Status', key: 'status', useColor: true},
      ]
    },
    user: {
      display_name: 'User',
      key: 'user',
      urlPrefix: 'settings/user',
      displayedColumns: [
        {display: 'Id', key: 'id', useColor: false},
        {display: 'Name', key: 'name', useColor: false},
        {display: 'Email', key: 'email', useColor: false},
        {display: 'Identify', key: 'user_identity', useColor: false},
        {display: 'Phone', key: 'phone', useColor: false},
        {display: 'Status', key: 'status', useColor: true},
      ]
    },
  };

  constructor() {
  }
}
