import {Injectable} from '@angular/core';
import {ApiBaseService} from '@shared/services/api/api-base.service';

@Injectable()
export class QuotationItemService extends ApiBaseService {
  route_prefix = 'quotation-items'

  options() {
    return this.http.get(this.route_prefix + '/' + 'options');
  }
}
