import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AppLoaderService } from '@shared/services/system/app-loader/app-loader.service';
import { MatSnackBar } from '@angular/material';
import { CompanyService } from '@shared/services/api/company.service';
import { AssetOptions, ModelAttributeOptions } from '@shared/models/options';
import { ModelAttributeOptionService } from '@shared/services/api/model-attribute-option.service';
import { AssetModel } from '@shared/models/asset.model';
import { parseDate } from '@shared/helpers/utils';
import { AssetService } from '@shared/services/api/asset.service';
import { get } from 'lodash';
import { TimeConvertService } from '@shared/services/time-convert.service';

@Component({
  selector: 'app-asset-edit',
  templateUrl: './asset-edit.component.html'
})
export class AssetEditComponent implements OnInit {
  public loading$ = false;
  @Input() id;
  @Input() inPopup = false;
  @Output() onSubmitted = new EventEmitter();
  @Output() onBack = new EventEmitter();

  public get companies() {
    return this.companyService.companies;
  }

  public buildings = [];
  public options: AssetOptions;
  public item: AssetModel;
  public itemForm = new FormGroup({
    company_id: new FormControl('', [Validators.required]),
    building_id: new FormControl('', [Validators.required]),
    name: new FormControl(''),
    category: new FormControl(''),
    description: new FormControl(''),
    service_interval: new FormControl(''),
    last_service_time: new FormControl(''),
    next_service_time: new FormControl(''),
  });
  public dataForm = new FormGroup({
    notification_period: new FormControl(0),
    asset_condition: new FormControl(''),
    compliant_status: new FormControl(''),
  });

  constructor(
    private _service: AssetService,
    private loader: AppLoaderService,
    private snack: MatSnackBar,
    private companyService: CompanyService,
    private maoService: ModelAttributeOptionService,
    private timeConvertService: TimeConvertService
  ) {
  }

  ngOnInit() {
    this.getOptions();
    this.itemForm.get('company_id').valueChanges.subscribe((company_id: any) => {
      if (company_id) {
        this.getBuildings(company_id);
      }
    });
    this.show();
  }

  buildItemForm(item) {
    this.itemForm.patchValue({
      company_id: item.company_id || '',
      building_id: parseInt(item.building_id, 0) || '',
      name: item.name || '',
      category: item.category || '',
      description: item.description || '',
      service_interval: item.service_interval || '',
      last_service_time: parseDate(item, 'last_service_time'),
      next_service_time: parseDate(item, 'next_service_time'),
    });
    this.dataForm.patchValue({
      notification_period: get(item, 'data.notification_period', 0),
      asset_condition: get(item, 'data.asset_condition', ''),
      compliant_status: get(item, 'data.compliant_status', ''),
    });
  }

  show() {
    let loader = this.loader.open();
    this._service.show(this.id)
      .finally(() => loader.close())
      .subscribe((data: AssetModel) => {
        this.item = data;
        this.buildItemForm(data);
        // this.getBuildings(this.item.company_id);
      });
  }

  getOptions() {
    this.maoService.all()
      .subscribe((data: ModelAttributeOptions) => {
        if (data) {
          this.options = data.asset;
        }
      });
  }

  getBuildings(company_id) {
    let loader = this.loader.open();
    this.companyService.getBuildingsByClientId(company_id)
      .finally(() => loader.close())
      .subscribe((data: any) => {
        this.buildings = data;
      });
  }

  submit() {
    let loader = this.loader.open();
    let itemFormValue = this.itemForm.value;
    itemFormValue['last_service_time'] = this.timeConvertService.convert(itemFormValue['last_service_time']);
    itemFormValue['next_service_time'] = this.timeConvertService.convert(itemFormValue['next_service_time']);
    this._service.update(this.id, {...itemFormValue, data: this.dataForm.value})
      .finally(() => {
        loader.close();
      })
      .subscribe(() => {
        this.onSubmitted.emit();
        this.snack.open('Asset Updated!', 'OK', {duration: 4000});
      });
  }

  refresh() {
    this.show();
  }
}
