import { Observable } from 'rxjs';
import { MatDialog } from '@angular/material';
import { Injectable } from '@angular/core';
import {HolidayApprovalComponent} from '@shared/services/system/holiday-approval/holiday-approval.component';

@Injectable()
export class HolidayApprovalService {

  constructor(private dialog: MatDialog) {
  }

  public show(holiday): Observable<undefined | { status: string }> {
    return this.dialog.open(HolidayApprovalComponent, {
      width: '380px',
      disableClose: false,
      data: { title: 'User holiday', holiday: holiday }
    }).afterClosed();
  }
}
