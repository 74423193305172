import {AppConfirmService} from '@shared/services/system/app-confirm/app-confirm.service';
import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {AppLoaderService} from '@shared/services/system/app-loader/app-loader.service';
import {QuotationService} from '@shared/services/api/quotation.service';
import {DisplayColumnsService} from '@shared/services/display-columns.service';
import {ServiceService} from '@shared/services/api/service.service';
import {PopupService} from '@shared/services/popup.service';
import {ServiceModel} from '@shared/models/service.model';
import {NotifyService} from '@shared/services/notify.service';
import {debounceTime, distinctUntilChanged, filter, switchMap} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import * as _ from 'lodash';

@Component({
  selector: 'app-quotation-create',
  templateUrl: './quotation-create.component.html'
})
export class QuotationCreateComponent implements OnInit {
  @Input() data: { serviceId: number, client_reference: string, materials: any[] };
  @Input() inPopup = false;
  @Output() onSubmitted: EventEmitter<any> = new EventEmitter();
  public cacheKey = 'quotation-create';
  public hasCache = false;
  public quotationItemDisplayColumns = [
    {display: 'Type', key: 'item_type', useColor: false},
    {display: 'Quantity', key: 'item_quantity', useColor: false},
    {display: 'Name', key: 'item_name', useColor: false},
    {display: 'Price', key: 'item_unit_price', useColor: false},
    {display: 'Markup', key: 'item_unit_margin', useColor: false},
    {display: 'Total Price', key: 'item_total_price', useColor: false},
  ];
  public itemFormGroup: FormGroup = new FormGroup({
    product_id: new FormControl(null),
    item_type: new FormControl('', Validators.required),
    item_name: new FormControl('', [Validators.required, Validators.maxLength(255)]),
    item_description: new FormControl(''),
    item_unit_margin: new FormControl(0, Validators.compose([Validators.required, Validators.min(0)])),
    item_unit_price: new FormControl(0, Validators.compose([Validators.required, Validators.min(0)])),
    item_quantity: new FormControl(0, Validators.compose([Validators.required, Validators.min(0)])),
  });
  public formGroup: FormGroup = new FormGroup({
    type: new FormControl('', Validators.required),
    description: new FormControl(''),
    data: new FormGroup({
      service_id: new FormControl('', {updateOn: 'blur', validators: [Validators.required]}),
      out_of_hours_work_required: new FormControl('no'),
      we_require_another_discipline_contractor_for_these_works: new FormControl('no'),
      special_terms: new FormControl(''),
      client_reference: new FormControl(''),
      lead_in_time: new FormControl(''),
      work_duration: new FormControl(''),
      client_name: new FormControl(''),
      building_name: new FormControl(''),
      building_address: new FormControl(''),
      discipline: new FormControl(''),
    })
  });
  public quotationItems = [];
  public materials = [];

  public total = 0;
  public lineTotal = 0;
  public subtotal = 0;

  public serviceIdIsValid = false;
  public service: ServiceModel;

  constructor(
    public quotationService: QuotationService,
    private toast: NotifyService,
    private loader: AppLoaderService,
    private http: HttpClient,
    public displayColumnsService: DisplayColumnsService,
    public serviceService: ServiceService,
    public popup: PopupService,
    private confirmService: AppConfirmService,
  ) {
  }

  ngOnInit() {
    const cache = JSON.parse(localStorage.getItem(this.cacheKey));
    if (cache) {
      this.formGroup.patchValue(cache.formGroup);
      this.quotationItems = cache.quotationItems;
      this.buildItemForm();
      this.computeTotal();
      this.hasCache = true;
    } else {
      this.buildItemForm();
      if (this.data && this.data['serviceId']) {
        this.formGroup.get('data').patchValue({
          service_id: this.data.serviceId
        });
      }
    }
  }

  resetItemFormGroup() {
    this.itemFormGroup.reset({
      product_id: null,
      item_type: '',
      item_name: '',
      item_description: '',
      item_unit_margin: this.quotationService.useMargin,
      item_unit_price: 0,
      item_quantity: 0,
      use_margin: 0,
    });
  }

  buildItemForm() {
    this.formGroup.get('data.service_id').valueChanges.subscribe((change) => {
      if (!change) {
        this.serviceIdIsValid = false;
      } else {
        this.serviceService.checkValid(change, 'quotation')
          .subscribe((data: any) => {
            if (data.valid) {
              this.serviceIdIsValid = true;
              this.showService(change);
            } else {
              this.serviceIdIsValid = false;
            }
          });
      }
    });

    this.itemFormGroup.valueChanges.subscribe((change) => {
      let item_quantity = change.item_quantity || 0;
      let item_unit_price = change.item_unit_price || 0;
      this.quotationService.useMargin = change.item_unit_margin || 0;
      this.lineTotal = item_quantity * item_unit_price * (1 + this.quotationService.useMargin / 100);
    });
    this.itemFormGroup.get('item_type').valueChanges.subscribe((change) => {
      this.quotationService.useMargin = change === 'service' ? 0 : this.quotationService.defaultMargin;
      this.itemFormGroup.patchValue({item_unit_margin: this.quotationService.useMargin, product_id: null}, {emitEvent: false});
      this.materials = [];
    });

    this.itemFormGroup.controls['item_name'].valueChanges
      .pipe(
        filter(text => {
          return this.itemFormGroup.controls['item_type'].value === 'material' && text.length > 2;
        }),
        debounceTime(10),
        distinctUntilChanged(),
        switchMap((res) => this.http.get('quotation-items/materials', {params: {keyword: res}}))
      )
      .subscribe((res: any) => {
        this.materials = res;
      });
  }

  showService(serviceId) {
    let loader = this.loader.open();
    this.serviceService.show(serviceId)
      .finally(() => loader.close())
      .subscribe((data: ServiceModel) => {
        this.service = data;
        const materials = _.get(this.service, 'site_audit_materials', []);
        const quotationItems = [];
        const client_reference = _.get(this.service, 'data.client.client_reference', '');
        materials.forEach((item) => {
          quotationItems.push({
            item_type: 'material',
            item_name: item.name,
            item_description: item.description,
            item_unit_margin: this.quotationService.useMargin,
            item_unit_price: 0,
            item_quantity: item.quantity,
            use_margin: 0,
            item_total_price: 0
          });
        });
        this.quotationItems = [...quotationItems];
        this.formGroup.get('data').patchValue({
          discipline: data.discipline,
          client_name: data.client_name,
          building_name: data.building_name,
          client_reference: client_reference,
          building_address: data.building.address1 || data.building.address2,
        });
        this.computeTotal();
      });
  }

  submit() {
    this.confirmService.confirm().subscribe((res) => {
      if (res) {
        let data = {items: this.quotationItems, ...this.formGroup.value};
        let loader = this.loader.open();
        this.quotationService.store(data)
          .finally(() => {
            loader.close();
          })
          .subscribe(() => {
            this.onSubmitted.emit();
            localStorage.removeItem(this.cacheKey);
            this.quotationService.refresh();
            this.toast.show('Quotation Added!');
          });
      }
    });
  }

  // Quotation Item Function

  addQuotationItem() {
    let item = this.itemFormGroup.value;
    if (item.item_type === 'material' && item.item_name.name) {
      item.item_name = item.item_name.name;
    }
    this.quotationItems.push({
      ...item,
      item_total_price: item.item_unit_price * item.item_quantity * (1 + item.item_unit_margin / 100)
    });
    this.quotationItems = [...this.quotationItems];
    this.computeTotal();
    this.resetItemFormGroup();
  }

  removeQuotationItem(row) {
    let index = this.quotationItems.indexOf(row);
    this.quotationItems.splice(index, 1);
    this.quotationItems = [...this.quotationItems];
    this.computeTotal();
  }

  editQuotationItem(row) {
    this.itemFormGroup.patchValue(row);
    let index = this.quotationItems.indexOf(row);
    this.quotationItems.splice(index, 1);
    this.quotationItems = [...this.quotationItems];
    this.computeTotal();
    if (row.item_type === 'material') {
      this.itemFormGroup.patchValue({item_name: {name: row.item_name}});
    }
  }

  computeTotal(vat = 0) {
    this.subtotal = 0;
    this.quotationItems.forEach((item) => {
      this.subtotal += +item.item_total_price;
    });
    if (!vat) {
      vat = 0;
    }
    this.total = this.subtotal + vat;
  }

  openEditServicePopup() {
    this.popup.openServiceEditPage(this.formGroup.value.data.service_id);
  }

  materialAutoComplete($event) {
    const item = $event.option.value;
    this.itemFormGroup.patchValue({
      product_id: item.id,
      item_unit_price: item.sell_price,
      item_description: item.description
    });
  }

  materialName(item) {
    if (item) {
      if (item.code) {
        return item.code + '-' + item.name;
      } else {
        return item.name;
      }
    } else {
      return '';
    }
  }

  saveTemp() {
    localStorage.setItem(this.cacheKey, JSON.stringify({quotationItems: this.quotationItems, formGroup: this.formGroup.value}));
    this.hasCache = true;
    this.toast.show('Quotation saved temp!');
  }

  clearTemp() {
    localStorage.removeItem(this.cacheKey);
    this.hasCache = false;
    this.toast.show('Quotation temp cleared!');
  }
}
