import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AppLoaderService } from '@shared/services/system/app-loader/app-loader.service';
import { MatSnackBar } from '@angular/material';
import { InvoiceService } from '@shared/services/api/invoice.service';
import { InvoiceItemService } from '@shared/services/api/invoice-item.service';
import { AppConfirmService } from '@shared/services/system/app-confirm/app-confirm.service';
import { ModelAttributeOptionService } from '@shared/services/api/model-attribute-option.service';
import { InvoiceOptions } from '@shared/models/options';
import { CompanyService } from '@shared/services/api/company.service';
import { DisplayColumnsService } from '@shared/services/display-columns.service';
import { PopupService } from '@shared/services/popup.service';

@Component({
  selector: 'app-invoice-edit',
  templateUrl: './invoice-edit.component.html'
})
export class InvoiceEditComponent implements OnInit {
  public loading$ = false;
  public itemForm: FormGroup;
  @Input() id;
  @Input() view = false;
  @Input() inPopup = false;
  public item;
  public invoiceOptions: InvoiceOptions;
  public get clients() {
    return this.companyService.clients;
  };

  constructor(
    private fb: FormBuilder,
    private _service: InvoiceService,
    private itemService: InvoiceItemService,
    private loader: AppLoaderService,
    private snack: MatSnackBar,
    private confirmService: AppConfirmService,
    private companyService: CompanyService,
    private maoService: ModelAttributeOptionService,
    public displayColumnsService: DisplayColumnsService,
    private popup: PopupService
  ) {
  }

  ngOnInit() {
    this.getOptions();
    this.show(this.id);
    this.buildItemForm({});
  }

  buildItemForm(item) {
    this.itemForm = this.fb.group({
      target_company_id: [item.target_company_id || ''],
      vat: [item.vat || 0, [Validators.min(0)]],
      status: [item.status || ''],
      subject: [item.subject || ''],
      description: [item.description || ''],
    });
  }

  getOptions() {
    this.maoService.all()
      .subscribe((data: any) => {
        this.invoiceOptions = data.invoice;
      });
  }

  show(id) {
    let loader = this.loader.open();
    this._service.show(id)
      .finally(() => loader.close())
      .subscribe((data: any) => {
        this.item = data;
        this.buildItemForm(data);
      });
  }

  submit() {
    let data = { ...this.itemForm.value };
    let loader = this.loader.open();
    this._service.update(this.id, data)
      .finally(() => {
        loader.close();
        this.show(this.id);
      })
      .subscribe(() => {
        this.snack.open('Invoice updated!', 'OK', { duration: 4000 });
      });
  }

  pay() {
    let loader = this.loader.open();
    this._service.pay(this.id)
      .finally(() => {
        loader.close();
      })
      .subscribe(() => {
        this.snack.open('Invoice pay success.', 'OK', { duration: 4000 });
        this.show(this.id);
      });
  }

  cancel() {
    let loader = this.loader.open();
    this._service.cancel(this.id)
      .finally(() => {
        loader.close();
      })
      .subscribe(() => {
        this.snack.open('Invoice cancel success.', 'OK', { duration: 4000 });
        this.show(this.id);
      });
  }

  openInvoiceItemCreatePopup() {
    this.popup.openInvoiceItemCreatePage(this.id)
      .afterClosed()
      .subscribe((res) => {
        if (!res) {
          return;
        }
        this.refresh();
      });
  }

  deleteItem(row) {
    this.confirmService.confirm({ message: `Delete ${row.name}?` })
      .subscribe(res => {
        if (res) {
          let loader = this.loader.open();
          this.itemService.destroy(row.id)
            .finally(() => {
              loader.close();
            })
            .subscribe(() => {
              this.refresh();
              this.snack.open('Invoice item deleted!', 'OK', { duration: 4000 });
            });
        }
      });
  }

  refresh() {
    this.show(this.id);
  }

  openEditServicePopUp(row) {
    this.popup.openServiceEditPage(row.id, this.view)
      .afterClosed()
      .subscribe(res => {
        if (!res) {
          return;
        }
        this.refresh();
      });
  }

  openEditItemPopup(row) {
    this.popup.openInvoiceItemEditPage(row.id, this.view)
      .afterClosed()
      .subscribe(res => {
        if (res) {
          this.refresh();
        }
      });
  }
}
