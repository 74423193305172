import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Component, Inject, OnInit } from '@angular/core';
import { AuthGuard } from '@shared/services/system/auth/auth.guard';

import { FormControl, FormGroup } from '@angular/forms';
import { SalaryTimeService } from '@shared/services/api/salary-time.service';
import 'rxjs-compat/add/operator/finally';
import { NotifyService } from '../../notify.service';
import {TimeConvertService} from '@shared/services/time-convert.service';

@Component({
  selector: 'app-approve-service-time',
  template: // html
    `
    <h1 mat-dialog-title translate>Approve job</h1>
    <div>
      <md2-datepicker type="datetime" class="full-width" placeholder="Expect Start Time" [formControl]="form.controls['expect_start_time']" required>
      </md2-datepicker>
    </div>
    <div mat-dialog-actions>
      <div fxFlex></div>
      <button mat-raised-button (click)="dialogRef.close()" color="warn" translate>
      <mat-icon>close</mat-icon>Close</button>
      <button mat-raised-button (click)="submit()" color="primary" [disabled]="form.invalid" translate>
      <mat-icon>check</mat-icon>OK</button>
    </div>
  `
})
export class ApproveServiceComponent implements OnInit {
  form = new FormGroup({
    expect_start_time: new FormControl(''),
  });

  constructor(
    public dialogRef: MatDialogRef<ApproveServiceComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { timeId: string & number },
    public salaryTimeService: SalaryTimeService,
    public auth: AuthGuard,
    public toast: NotifyService,
    public timeConvertService: TimeConvertService
  ) {
  }

  ngOnInit(): void {
  }

  submit() {
    let formValue = this.form.value;
    formValue['expect_start_time'] = this.timeConvertService.convert(formValue['expect_start_time']);
    this.dialogRef.close({ ...formValue });
  }
}

