import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FileUploader } from 'ng2-file-upload';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthGuard } from '@shared/services/system/auth/auth.guard';
import { DocumentService } from '@shared/services/api/document.service';

import { DocumentUploadData } from '@shared/models/document-upload-data.model';
import { NotifyService } from '@app/shared/services/notify.service';
import { UploaderService } from '@shared/services/uploader.service';

@Component({
  selector: 'app-service-certificate',
  templateUrl: './app-service-certificate.component.html',
  styleUrls: ['./app-service-certificate.component.scss']
})
export class AppServiceCertificateComponent implements OnInit {
  public uploader: FileUploader = this.uploaderService.get({url: this.document.uploadUrl()});
  public itemForm: FormGroup;
  @Output() onSubmitted: EventEmitter<null> = new EventEmitter();
  @Input() submitFormData: DocumentUploadData;
  @Input() enable = false;
  constructor(
    private fb: FormBuilder,
    private auth: AuthGuard,
    private document: DocumentService,
    private toast: NotifyService,
    private uploaderService: UploaderService,
  ) {
  }

  ngOnInit() {
    this.buildItem();
    this.uploader.onBuildItemForm = (fileItem, form) => {
      this.uploader.options.additionalParameter = { ...this.itemForm.value, ...this.submitFormData };
    };
    this.uploader.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
      if (status === 200) {
        this.toast.show('Add success!');
        this.uploader.clearQueue();
        this.buildItem();
      } else {
        this.toast.show('Add failed!');
      }
      this.onSubmitted.emit();
    };
  }

  buildItem() {
    this.itemForm = this.fb.group({
      description: ['', Validators.required],
    });
  }

  add() {
    this.uploader.uploadAll();
  }
}
