import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef, MatSnackBar } from '@angular/material';
import { AppLoaderService } from '@shared/services/system/app-loader/app-loader.service';
import { ModelAttributeOptionService } from '@shared/services/api/model-attribute-option.service';
import { InvoiceItemOptions, ServiceOptions } from '@shared/models/options';
import { CompanyService } from '@shared/services/api/company.service';
import { InvoiceItemService } from '@shared/services/api/invoice-item.service';

@Component({
  selector: 'app-invoice-item-create',
  templateUrl: './invoice-item-create.component.html'
})
export class InvoiceItemCreatePopupComponent implements OnInit {
  @Input() id;
  @Output() onSubmitted: EventEmitter<any> = new EventEmitter();
  public loading$ = false;
  public itemFormGroup: FormGroup;
  public buildings;
  public options: InvoiceItemOptions;
  public lineTotal = 0;
  public serviceOptions: ServiceOptions;
  public get clients() {
    return this.companyService.clients;
  };

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { id: number },
    public dialogRef: MatDialogRef<InvoiceItemCreatePopupComponent>,
    private fb: FormBuilder,
    private _service: InvoiceItemService,
    private snack: MatSnackBar,
    private loader: AppLoaderService,
    private maoService: ModelAttributeOptionService,
    private companyService: CompanyService
  ) {
    if (data.id) {
      this.id = data.id;
    }
  }

  ngOnInit() {
    this.buildItemForm();
    this.getOptions();
  }

  buildItemForm() {
    this.itemFormGroup = this.fb.group({
      item_type: ['', Validators.required],
      item_name: ['', [Validators.required, Validators.maxLength(255)]],
      item_description: [''],
      item_unit_price: [0, Validators.compose([Validators.required, Validators.min(0)])],
      item_quantity: [0, Validators.compose([Validators.required, Validators.min(0)])],
      item_status: ['', Validators.required],
    });

    this.itemFormGroup.get('item_unit_price').valueChanges.subscribe((item_unit_price) => {
      let item_quantity = this.itemFormGroup.value.item_quantity || 0;
      let price = item_unit_price || 0;
      this.lineTotal = item_quantity * price;
    });
    this.itemFormGroup.get('item_quantity').valueChanges.subscribe((item_quantity) => {
      let q = item_quantity || 0;
      let price = this.itemFormGroup.value.item_unit_price || 0;
      this.lineTotal = q * price;
    });
  }

  getOptions() {
    this.maoService.all()
      .subscribe((data: any) => {
        this.serviceOptions = data.service;
        this.options = data.invoice_item;
      });
  }

  submit() {
    let data = { ...this.itemFormGroup.value, invoice_id: this.id };
    let loader = this.loader.open();
    this._service.store(data)
      .finally(() => {
        loader.close();
      })
      .subscribe(() => {
        this.onSubmitted.emit();
        this.snack.open('Invoice item added!', 'OK', { duration: 4000 });
        if (this.data) {
          this.dialogRef.close(true);
        }
      });
  }

  close() {
    if (this.data) {
      this.dialogRef.close();
    }
  }
}
