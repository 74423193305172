import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {ServiceService} from '@shared/services/api/service.service';
import {AppLoaderService} from '@shared/services/system/app-loader/app-loader.service';
import {Router} from '@angular/router';
import * as _ from 'lodash';
import {ModelAttributeOptionService} from '@shared/services/api/model-attribute-option.service';
import {DocumentService} from '@shared/services/api/document.service';
import {DocumentModel} from '@shared/models/document.model';
import {AppConfirmService} from '@shared/services/system/app-confirm/app-confirm.service';

import {AssetModel} from '@shared/models/asset.model';
import {AssetService} from '@shared/services/api/asset.service';
import {parseDate} from '@shared/helpers/url.helper';
import {PopupService} from '@shared/services/popup.service';
import {LayoutService} from '@shared/services/system/layout.service';
import {ServiceModel} from '@shared/models/service.model';
import {BuildingModel} from '@shared/models/building.model';
import {BuildingService} from '@shared/services/api/building.service';
import {PurchaseOrderModel} from '@shared/models/purchase-order.model';
import {QuotationModel} from '@shared/models/quotation.model';
import {DisplayColumnsService} from '@shared/services/display-columns.service';
import {ServiceCustomizeService} from '@app/shared/components/common/service-customize.service';
import {ServiceExtraService} from '@app/shared/components/common/service-extra.service';
import {NotifyService} from '@app/shared/services/notify.service';
import {ServiceVisitService} from '@shared/services/api/service-visit.service';
import * as moment from 'moment';
import {QuotationCreatePopupComponent} from '@shared/components/business/quotation/quotation-create/quotation-create-popup.component';
import {MatDialog} from '@angular/material';
import {UserService} from '@shared/services/api/user.service';
import {Location} from '@angular/common';
import {ModelAttributeOptions} from '@shared/models/options';
import { RejectServiceService } from '@app/shared/services/system/reject-service/reject-service.service';
import { ApproveServiceService } from '@app/shared/services/system/approve-service/approve-service.service';
import { GlobalSettingService } from '@app/shared/services/system/global-setting.service';

@Component({
  selector: 'app-service-edit',
  templateUrl: './service-edit.component.html',
  styleUrls: ['./service-edit.component.scss'],
  providers: [
    ServiceCustomizeService,
    ServiceExtraService,
  ],
})
export class ServiceEditComponent implements OnInit, OnDestroy {
  _ = _;
  public formGroup: FormGroup = new FormGroup({
    subject: new FormControl(''),
    problem: new FormControl(''),
    budget: new FormControl(0),
    service_type: new FormControl(''),
    discipline: new FormControl(''),
    severity: new FormControl(''),
    expect_start_time: new FormControl(null),
    expect_end_time: new FormControl(null),
    engineer_ids: new FormControl([]),
    status: new FormControl(''),
    asset_ids: new FormControl([]),
    data: new FormGroup({
      client: new FormGroup({
        client_reference: new FormControl(''),
        service_reference: new FormControl(''),
        service_code: new FormControl(''),
      }),
      standard_rate: new FormGroup({
        start_time: new FormControl(''),
        end_time: new FormControl(''),
      }),
      room_id: new FormControl(null),
    })
  });

  loadingAssets = false;
  showCreateQuoteRequestButton = false;
  public saleDisplayColumns = [
    {display: 'Service', key: 'service_id', useColor: false},
    {display: 'Type', key: 'item_type', useColor: false},
    {display: 'Name', key: 'item_name', useColor: false},
    {display: 'Desc', key: 'item_description', useColor: false},
    {display: 'Total', key: 'item_total_price', useColor: false},
    {display: 'Unit Price', key: 'item_unit_price', useColor: false},
    {display: 'Unit Duration', key: 'item_unit_duration', useColor: false},
    {display: 'Quantity', key: 'item_quantity', useColor: false},
    {display: 'Operator', key: 'operator', useColor: false},
    {display: 'Status', key: 'item_status', useColor: true},
  ];
  public relationServicesDisplayedColumns = [
    {display: 'Id', key: 'id', useColor: false},
    {display: 'Client', key: 'client_name', useColor: false},
    {display: 'Building', key: 'building_name', useColor: false},
    {display: 'Relation Type', key: 'relation_type', useColor: false},
    {display: 'Type', key: 'service_type', useColor: false},
    {display: 'Discipline', key: 'discipline', useColor: false},
    {display: 'Contractors', key: 'contractors', useColor: false},
    {display: 'Creator', key: 'create_user_name', useColor: false},
    {display: 'Status', key: 'status', useColor: true},
    {display: 'Priority', key: 'severity', useColor: false}
  ];
  @Input() id = null;
  @Input() inPopup = false;
  @Input() view = false;
  @Output() onSubmitted: EventEmitter<any> = new EventEmitter();
  @Output() onBack = new EventEmitter();

  public item: ServiceModel;

  public disciplines = [];

  public rooms = [];
  public status: any[];
  public buildings = [];
  public actionLogs = [];
  public showActionLog = false;
  public costs = [];
  public totalLabour = 0;
  public showCosts = false;
  public salaries = [];

  public get engineers() {
    // append service engineer
    let engineers = this.userService.engineers;
    this.item.user_service_relations.map(relation => {
      let findIndex = engineers.findIndex(item => item.id === relation.user_id);
      if (findIndex !== -1) {
        engineers[findIndex]['visit_status'] = relation.visit_status;
      } else {
        engineers.push({
          visit_status: relation.visit_status,
          id: relation.user_id,
          name: relation.user_name,
        });
      }
    });
    return engineers;
  }

  public assets: AssetModel[];
  public building: BuildingModel;
  public quotations: QuotationModel[] = [];
  public purchaseOrders: PurchaseOrderModel[] = [];
  public filterPurchaseOrders: PurchaseOrderModel[] = [];
  private onRefreshSub;
  public buildingLoading = false;
  public poSubTotal = 0;
  public salarySubTotal = 0;

  // visit
  public certificates: [DocumentModel];

  constructor(
    private _service: ServiceService,
    public serviceService: ServiceService,
    private serviceVisitService: ServiceVisitService,
    private loader: AppLoaderService,
    private router: Router,
    private rejectService: RejectServiceService,
    private approveService: ApproveServiceService,
    private maoService: ModelAttributeOptionService,
    private documentService: DocumentService,
    private confirmService: AppConfirmService,
    private toast: NotifyService,
    private assetService: AssetService,
    public popup: PopupService,
    public layout: LayoutService,
    public buildingService: BuildingService,
    public displayColumnsService: DisplayColumnsService,
    public customizeService: ServiceCustomizeService,
    public extraService: ServiceExtraService,
    private dialog: MatDialog,
    private userService: UserService,
    private location: Location,
    private global: GlobalSettingService
  ) {
  }

  ngOnInit() {
    this.initData();
    this.onRefreshSub = this.layout.onRefresh.subscribe(() => this.refresh());
    const roles = this.global.getConfig('roles');
    if (roles.indexOf('financier') > -1) {
      this.showCosts = true;
    }
  }

  initData() {
    this.show();
    this.refreshCertificateTable();
    this.maoService.options$.subscribe((data: ModelAttributeOptions) => {
      this.disciplines = data.service.discipline.filter(item => item.status !== 'inactive');
    });
  }

  fillForm(item) {
    let data = item.data;
    if (data['standard_rate']) {
      if (data['standard_rate']['start_time']) {
        let start = moment(data['standard_rate']['start_time'], 'HH:mm');
        data['standard_rate']['start_time'] = moment().set('hour', start.get('hour')).set('minute', start.get('minute'));
      }
      if (data['standard_rate']['end_time']) {
        let end = moment(data['standard_rate']['end_time'], 'HH:mm');
        data['standard_rate']['end_time'] = moment().set('hour', end.get('hour')).set('minute', end.get('minute'));
      }
    }

    this.formGroup.patchValue({
      subject: item.subject || '',  // string
      problem: item.problem || '',    // string
      budget: parseFloat(item.budget).toFixed(2) || 0,  // number
      service_type: item.service_type || '', // option
      discipline: item.discipline || '', // option
      severity: item.severity || '',   // option
      expect_start_time: parseDate(item, 'expect_start_time'),
      expect_end_time: parseDate(item, 'expect_end_time'),
      engineer_ids: item.engineer_ids || [],
      status: item.status || '',
      asset_ids: _.get(item, 'asset_ids', []),
      data: data,
    });
    this.showCreateQuoteRequestButton = (item.status === 'quote_required');
    this.extraService.setFormGroup(_.get(item, 'data.extra', {}));
    this.customizeService.setByObject(_.get(item, 'data.customize', {}));
  }

  getBuilding(buildingId) {
    this.buildingLoading = true;
    this.buildingService.show(buildingId)
      .finally(() => this.buildingLoading = false)
      .subscribe((data: BuildingModel) => {
        this.building = data;
        this.rooms = _.get(this.building, 'data.rooms', []);
      });
  }

  getAssets(building_id) {
    this.loadingAssets = true;
    this.assetService.byBuildingId(building_id)
      .finally(() => this.loadingAssets = false)
      .subscribe((data: [AssetModel]) => {
        this.assets = data;
      });
  }

  submit() {
    this.confirmService.confirm({})
      .subscribe((res) => {
        if (res) {
          let formGroupValue = this.formGroup.value;
          formGroupValue['expect_start_time'] = formGroupValue['expect_start_time'];
          formGroupValue['expect_end_time'] = formGroupValue['expect_end_time'];
          formGroupValue['data']['extra'] = this.extraService.all();
          formGroupValue['data']['customize'] = this.customizeService.allObject();
          if (formGroupValue['data']['standard_rate']['start_time']) {
            formGroupValue['data']['standard_rate']['start_time'] = moment(formGroupValue['data']['standard_rate']['start_time']).format('HH:mm');
          }
          if (formGroupValue['data']['standard_rate']['end_time']) {
            formGroupValue['data']['standard_rate']['end_time'] = moment(formGroupValue['data']['standard_rate']['end_time']).format('HH:mm');
          }
          let loader = this.loader.open();
          this._service.update(this.id, formGroupValue)
            .finally(() => loader.close())
            .subscribe(() => {
              this.toast.show('Service updated!');
              this.refresh();
              this.onSubmitted.emit();
            });
        }
      });
  }

  show() {
    let loader = this.loader.open();
    this._service.show(this.id)
      .finally(() => {
        loader.close();
      })
      .subscribe((data: any) => {
        this.item = data;
        this.quotations = data.quotations;
        this.purchaseOrders = data.purchaseOrders;
        this.filterPurchaseOrders = data.filterPurchaseOrders;
        this.poSubTotal = _.sumBy(this.filterPurchaseOrders, 'total');
        this.fillForm(data);
        this.getBuilding(data.building_id);
        this.getAssets(data.building_id);
        this.salaries = data.salaries;
        this.salarySubTotal = _.sumBy(this.salaries, (item) => {
          return (item.extra_cost + item.hourly_rate_cost);
        });
        const salaryTotal = _.sumBy(_.get(data, 'salaries', []), function (item: any) {
          return item.extra_cost + item.hourly_rate_cost;
        });
        const poTotal = _.sumBy(_.get(data, 'purchaseOrders', []), function (item: any) {
          return item.total;
        });

        this.totalLabour = salaryTotal + poTotal;
        if (-1 === this.disciplines.findIndex(item => item.option_value === this.item.discipline)) {
          this.disciplines.push({
            option_value: this.item.discipline
          });
        }
      });
  }

  gotoTable() {
    this.router.navigate(['/service']);
  }

  ngOnDestroy(): void {
    if (this.onRefreshSub) {
      this.onRefreshSub.unsubscribe();
    }
  }

  refresh() {
    this.initData();
  }

  refreshCertificateTable() {
    this.documentService.certificates(this.id)
      .subscribe((data: any) => {
        this.certificates = data;
      });
  }

  verifyCertificate(document_id) {
    this.popup.openServiceCertificateVerifyPopup(document_id)
      .afterClosed()
      .subscribe(res => {
        if (res) {
          this.refreshCertificateTable();
        }
      });
  }

  deleteCertificate(id) {
    this.confirmService.confirm({message: `Delete ${id}?`})
      .subscribe(res => {
        if (res) {
          let loader = this.loader.open();
          this.documentService.destroy(id)
            .finally(() => {
              loader.close();
            })
            .subscribe(() => {
              this.refreshCertificateTable();
              this.toast.show(`Certificate ${id} deleted!`);
            });
        }
      });
  }

  openAssetCreatePage() {
    this.popup.openAssetCreatePage(this.formGroup.value.client_id, this.formGroup.value.building_id)
      .afterClosed()
      .subscribe((res) => {
        if (res) {
          this.getAssets(this.formGroup.value.building_id);
        }
      });
  }

  openCreateServiceVisitPopup(serviceId) {
    this.popup.openCreateServiceVisitPopup({
      serviceId,
      rateType: _.get(this.item, 'data.extra.out_of_hours_call_out', 'no') === 'yes' ? 'one_off_rate' : 'hourly_rate'
    })
      .afterClosed()
      .subscribe((res) => {
        if (res) {
          this.refresh();
        }
      });
  }

  openQuotationEditPopup($event) {
    this.popup.openQuotationEditPage($event.id)
      .afterClosed()
      .subscribe((res) => {
        if (res) {
          this.refresh();
        }
      });
  }

  openQuotationCreatePopup() {
    this.dialog.open(QuotationCreatePopupComponent, {
      width: '80%',
      disableClose: true,
      data: {
        serviceId: this.id,
        client_reference: _.get(this.item, 'data.client.client_reference', ''),
        materials: _.get(this.item, 'site_audit_materials', [])
      }
    })
      .afterClosed()
      .subscribe((res) => {
        if (res) {
          this.refresh();
        }
      });
  }

  openPurchaseOrderEditPopup($event) {
    this.popup.openPurchaseOrderEditPage($event.id)
      .afterClosed()
      .subscribe((res) => {
        if (res) {
          this.refresh();
        }
      });
  }

  openPurchaseOrderCreatePopup() {
    this.popup.openPurchaseOrderCreatePopup({serviceId: this.id, clientId: this.formGroup.value.client_id})
      .afterClosed()
      .subscribe((res) => {
        if (res) {
          this.refresh();
        }
      });
  }

  cancel() {
    this.confirmService.confirm({message: `Canceling service ${this.id}, please confirm.`})
      .subscribe((res) => {
        if (res) {
          let loader = this.loader.open();
          this._service.cancel(this.id)
            .finally(() => loader.close())
            .subscribe(() => {
              this.toast.show('Cancel success');
              this.refresh();
            });
        }
      });
  }

  complete() {
    this.confirmService.confirm({message: `Completing service ${this.id}, please confirm.`})
      .subscribe((res) => {
        if (res) {
          let loader = this.loader.open();
          this._service.complete(this.id)
            .finally(() => loader.close())
            .subscribe(() => {
              this.toast.show('Complete success');
              this.refresh();
            });
        }
      });
  }

  assignTo() {
    this.confirmService.confirm({message: `Assigning to engineers, please confirm.`})
      .subscribe((res) => {
        if (res) {
          let loader = this.loader.open();
          this._service.assignTo(this.id, this.formGroup.get('engineer_ids').value, this.formGroup.get('expect_start_time').value)
            .finally(() => loader.close())
            .subscribe(() => {
              this.toast.show('Assign success');
              this.refresh();
            });
        }
      });
  }

  openEditServicePopup(row) {
    this.popup.openServiceEditPage(row.id);
  }

  deleteServiceVisit(row) {
    this.confirmService.confirm({message: `Delete ${row.id}?`})
      .subscribe(res => {
        if (res) {
          let loader = this.loader.open();
          this.serviceVisitService.destroy(row.id)
            .finally(() => loader.close())
            .subscribe(() => {
              this.refresh();
              this.toast.show(`Service visit ${row.id} deleted!`);
            });
        }
      });
  }

  openEditServiceVisit(row) {
    this.popup.openServiceVisitEditPage(row.id)
      .afterClosed()
      .subscribe((res) => {
        if (res) {
          this.refresh();
        }
      });
  }

  toCreateQuoteRequest() {
    let loader = this.loader.open();
    this._service.getLastVisitNote(this.id)
      .finally(() => loader.close())
      .subscribe((note: string) => {
        this.router.navigate(['/service/create', {
          type: 'create_quote_request',
          id: this.id,
          client_id: this.item.client_id,
          building_id: this.item.building_id,
          subject: this.formGroup.value.subject,
          problem: note,
          discipline: this.formGroup.value.discipline,
        }]);
      });
  }

  viewPdf() {
    this.documentService.viewPdf('survey', this.item.id);
  }

  back() {
    if (this.inPopup) {
      this.onBack.emit();
    } else {
      // this.router.navigate(['/service']);
      this.location.back();
    }
  }

  openLink(document: DocumentModel) {
    window.open(document.full_url);
  }

  viewPdfs(row) {
    let loader = this.loader.open();
    this._service.viewPdfs(row.id)
      .finally(() => loader.close())
      .subscribe((pdfs: any) => {
        pdfs.map((pdf) => {
          let win = window.open('');
          win.document.write(pdf.html);
        });
      });
  }

  getActionLogs() {
    this.serviceService.getActionLogs(this.id).subscribe((data: any) => {
      this.actionLogs = data;
      this.showActionLog = true;
    });
  }

  loadActionLog() {
    if (!this.showActionLog) {
      this.getActionLogs();
    }
  }

  searchRoom(term, item) {
    return item.name.toString().toLowerCase().indexOf(term.toString().toLowerCase()) >= 0;
  }

  accept() {
    this.approveService.show().subscribe((res: undefined | { expect_start_time: string }) => {
      if (res) {
        let loader = this.loader.open();
        this._service.approve(this.id, { ...res })
          .finally(() => loader.close())
          .subscribe(() => {
            this.toast.show('Approve success');
            this.refresh();
          });
      }
    });
  }

  reject() {
    this.rejectService.show().subscribe((res: undefined | { note: string }) => {
      if (res) {
        let loader = this.loader.open();
        this._service.reject(this.id, { ...res })
          .finally(() => loader.close())
          .subscribe(() => {
            this.toast.show('Reject success');
            this.layout.onRefresh.emit();
          });
      }
    });
  }

  formatCosts(list) {
    this.costs = [];
    this.totalLabour = 0;
    const group =  _.groupBy(list, (item) => {
      return item.user_id;
    });
    _.forEach(group, (val) => {
      let user = {
        user_id: 0,
        user_name: '',
        total: 0,
        items: []
      };
      _.forEach(val, (item) => {
        user.user_id = item.user_id;
        user.user_name = item.name;
        user.total += (item.total_labour);
        user.items.push(item);
      });
      this.totalLabour += user.total;
      this.costs.push(user);
    });
  }

  openServiceSheet(table) {
    let ids = table.selected.map((item) => {
      return item.id;
    });
    this.documentService.viewPdf('service_sheet_multi', ids.join(','));
  }
}
