import { Injectable } from '@angular/core';
import { ApiBaseService } from '@shared/services/api/api-base.service';

@Injectable()
export class ServiceVisitService extends ApiBaseService {
  route_prefix = 'service-visits';

  sales(serviceVisitId) {
    return this.http.get(`service-visits/${serviceVisitId}/sales`);
  }

  audit(serviceVisitId) {
    return this.http.get(`service-visits/${serviceVisitId}/audit`);
  }
}
