import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AppLoaderService } from '@shared/services/system/app-loader/app-loader.service';
import { Router } from '@angular/router';
import { BuildingService } from '@shared/services/api/building.service';
import { CompanyService } from '@shared/services/api/company.service';
import { BuildingOptions, CompanyOptions, ModelAttributeOptions } from '@shared/models/options';
import { ModelAttributeOptionService } from '@shared/services/api/model-attribute-option.service';
import { NotifyService } from '@app/shared/services/notify.service';

@Component({
  selector: 'app-building-edit',
  templateUrl: './building-edit.component.html',
  styleUrls: ['./building-edit.component.scss']
})
export class BuildingEditComponent implements OnInit {
  public loading$ = false;
  @Input() id;
  @Input() inPopup = false;
  @Output() onSubmitted = new EventEmitter();

  public itemFormGroup: FormGroup = new FormGroup({
    name: new FormControl('', [Validators.required]),
    alias: new FormControl(''),
    code: new FormControl(''),
    address1: new FormControl(''),
    address2: new FormControl(''),
    town: new FormControl(''),
    city: new FormControl(''),
    postcode: new FormControl(''),
    country: new FormControl(''),
    latitude: new FormControl(''),
    longitude: new FormControl(''),
    email: new FormControl('', [Validators.email]),
    phone: new FormControl(''),
    company_id: new FormControl(''),
    status: new FormControl(''),
  });

  public get clients() {
    return this.companyService.clients;
  };

  public item;
  public buildingOption: BuildingOptions;

  constructor(
    public toast: NotifyService,
    private _service: BuildingService,
    private loader: AppLoaderService,
    private router: Router,
    private companyService: CompanyService,
    private maoService: ModelAttributeOptionService
  ) {
  }

  ngOnInit() {
    this.getOptions();
    this.show(this.id);
  }


  show(id) {
    let loader = this.loader.open();
    this._service.show(id)
      .finally(() => loader.close())
      .subscribe((data: any) => {
        this.item = data;
        this.itemFormGroup.patchValue(data);
      });
  }

  getOptions() {
    this.maoService.all().subscribe((data: ModelAttributeOptions) => {
      this.buildingOption = data.building;
    });
  }

  submit() {
    let loader = this.loader.open();
    this._service.update(this.id, this.itemFormGroup.value)
      .finally(() => {
        loader.close();
      })
      .subscribe(() => {
        if (this.inPopup) {
          this.onSubmitted.emit(true);
        } else {
          this.router.navigate(['/settings/building']);
        }
        this.toast.show('Building Updated!');
      });
  }

  refresh() {
    this.show(this.id);
  }
}
