import { ApprovalModel } from './../../../models/approval.model';
import { UserService } from '@shared/services/api/user.service';
import { UserModel } from '@shared/models/user.model';

import { AppLoaderService } from '@shared/services/system/app-loader/app-loader.service';
import { ServiceService } from '@shared/services/api/service.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Component, OnInit, Input } from '@angular/core';
import { NotifyService } from '@app/shared/services/notify.service';

@Component({
  selector: 'app-uplift',
  templateUrl: './uplift.component.html'
})
export class UpliftComponent implements OnInit {

  @Input() serviceId;

  items: ApprovalModel[] = [];
  accountants: UserModel[] = [];

  public formGroup = new FormGroup({
    user_ids: new FormControl([]),
    budget: new FormControl(0, [Validators.required, Validators.min(0.01)]),
    labourTotal: new FormControl(0),
    materialTotal: new FormControl(0),
    plantTotal: new FormControl(0),
    otherTotal: new FormControl(0),
    comments: new FormControl('')
  });

  constructor(
    private serviceService: ServiceService,
    private loader: AppLoaderService,
    private toast: NotifyService,
    private userService: UserService
  ) { }

  ngOnInit() {
    this.getAccountants();
    this.refresh();
    this.formGroup.valueChanges.subscribe(change => {
      this.formGroup.patchValue({ budget: change.labourTotal + change.materialTotal + change.plantTotal + change.otherTotal }, { emitEvent: false });
    });
  }

  getAccountants() {
    this.userService.accountantsByServiceId(this.serviceId)
      .subscribe((response: UserModel[]) => {
        this.accountants = response;
      });
  }

  submit() {
    let loader = this.loader.open();
    let description = `Comments: ${this.formGroup.value.comments} <br>`
      + `Labour total: ${this.formGroup.value.labourTotal},
    Materials total: ${this.formGroup.value.materialTotal},
    Plant total: ${this.formGroup.value.plantTotal},
    Other total: ${this.formGroup.value.otherTotal},
    Total: ${this.formGroup.value.budget}
    `;
    this.serviceService.storeUplift(this.serviceId, { ...this.formGroup.value, description })
      .finally(() => loader.close())
      .subscribe(() => {
        this.toast.show('Create uplift success.');
        this.refresh();
      });
  }

  refresh() {
    let loader = this.loader.open();
    this.serviceService.uplifts(this.serviceId)
      .finally(() => loader.close())
      .subscribe((data: ApprovalModel[]) => {
        this.items = data;
      });
  }
}
