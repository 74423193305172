import { DisplayColumnsService } from './../display-columns.service';
import { TranslateService } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { get } from 'lodash';
import { NotifyService } from '../notify.service';

export interface Settings {
  purchase_order: {
    show_delete_button: boolean;
  },
  purchase_order_item_margin: {
    unit_margin: number,
    unit_margin_type: string,
  },
  quotation_item_margin: {
    unit_margin: number,
    unit_margin_type: string,
  },
}

export const DefaultSettings: Settings = {
  purchase_order: {
    show_delete_button: false
  },
  purchase_order_item_margin: {
    unit_margin: 15,
    unit_margin_type: "percent",
  },
  quotation_item_margin: {
    unit_margin: 15,
    unit_margin_type: "percent",
  },
};

@Injectable({
  providedIn: 'root'
})
export class SettingsService {
  public settings$: ReplaySubject<Settings> = new ReplaySubject(1);
  public settings: Settings = undefined;
  private settingsIsLoading = false;

  constructor(
    private http: HttpClient,
    private translate: TranslateService,
    private toast: NotifyService,
    private displayService: DisplayColumnsService
  ) {
    this.settings$.subscribe((settings: Settings) => {
      if (settings) {
        this.onSettingsChange(settings);
      }
    });
  }

  init() {
    this.settings = undefined;
    this.all().subscribe();
  }

  private onSettingsChange(settings: Settings) {
    this.toast.debug('settings', settings);
    // set purchase order table delete button show status
    this.displayService.configs.purchase_order.deleteButton = get(settings, 'purchase_order.show_delete_button', false);
  }

  all() {
    if (!this.settingsIsLoading && this.settings === undefined) {
      if (this.settings) {
        // get from cache
        this.settingsIsLoading = false;
      } else {
        // get from api
        this.http
          .get('settings/full-contractor')
          .finally(() => (this.settingsIsLoading = false))
          .map((data: { option_name: string; option_value: string; data: {} }[]) => {
            let settings = {};
            data.map(item => {
              settings[item.option_name] = item.option_value || item.data;
            });
            return settings;
          })

          .subscribe(
            (data: Settings) => {
              this.setSettings(data);
            },
            error =>
              this.toast.show(this.translate.instant('alert_read_settings_failed'))
          );
      }
    }
    return this.settings$;
  }

  reset() {
    this.setSettings(DefaultSettings);
  }

  setSettings(settings: Settings) {
    this.settings = settings;
    this.publish(this.settings);
  }

  setSetting(key, value) {
    if (this.settings.hasOwnProperty(key)) {
      this.settings[key] = value;
      this.publish(this.settings);
    }
  }

  clean() {
    this.settings = undefined;
    this.all();
  }

  private publish(settings: Settings) {
    this.settings$.next(settings);
  }

  public initTranslation() {
    this.http
    .get('settings/translation/read')
    .subscribe(
      (res: any) => {
        console.log(res);
        if (res.translation) {
          console.log(res.translation);
          this.translate.setTranslation('en', res.translation);
        }
      },
      error =>
        this.toast.show(this.translate.instant('alert_read_settings_failed'))
    );
  }
}
