import { Injectable } from '@angular/core';
import { ApiBaseService } from '@shared/services/api/api-base.service';

@Injectable()
export class ApplicationService extends ApiBaseService {

  route_prefix = 'applications';

  getCanGenerateQuotationServices(data) {
    return this.http.get('applications/get-can-generate-quotation-service', { params: data });
  }

  getClients(supplier_id) {
    return this.http.get('applications/clients', { params: { supplier_id } });
  }
}
