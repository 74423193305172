import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { SimpleReuseStrategy } from '@app/simple-reuse-strategy';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModelAttributeOptions, UserCompanyOptions, UserOptions } from '@shared/models/options';
import { MatSnackBar } from '@angular/material';
import { AppLoaderService } from '@shared/services/system/app-loader/app-loader.service';
import { Router } from '@angular/router';
import { UserService } from '@shared/services/api/user.service';
import { ModelAttributeOptionService } from '@shared/services/api/model-attribute-option.service';
import { CompanyModel } from '@shared/models/company.model';
import { CompanyService } from '@shared/services/api/company.service';
import { AppConfirmService } from '@shared/services/system/app-confirm/app-confirm.service';

@Component({
  selector: 'app-user-create',
  templateUrl: './user-create.component.html',
  styleUrls: ['./user-create.component.scss'],
  providers: [SimpleReuseStrategy]
})
export class UserCreateComponent implements OnInit {
  @Output() onSubmitted: EventEmitter<any> = new EventEmitter();
  public loading$ = false;
  public itemForm: FormGroup;
  public userOptions: UserOptions;
  public relationTypes = [];

  constructor(
    private _service: UserService,
    private snack: MatSnackBar,
    private loader: AppLoaderService,
    private fb: FormBuilder,
    private router: Router,
    private confirm: AppConfirmService,
    private companyService: CompanyService,
    private mao: ModelAttributeOptionService
  ) {
  }

  ngOnInit() {
    this.initData();
  }

  initData() {
    this.buildItemForm();
    this.getOptions();
  }

  buildItemForm() {
    this.itemForm = this.fb.group({
      name: ['', Validators.required],
      phone: [''],
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, Validators.minLength(6)]],
      password_confirmation: ['', Validators.required],
      user_identity: [''],
      status: ['', Validators.required],
      address1: [''],
      address2: [''],
      town: [''],
      country: ['United Kingdom'],
      city: [''],
      postcode: [''],
      user_company: [[]]
    });
  }

  getOptions() {
    this.mao
      .all()
      .subscribe((data: ModelAttributeOptions) => {
        this.userOptions = data.user;
        data.user_company.type.forEach((type) => {
          if (type.option_name === 'user_company_type') {
            this.relationTypes = type.data.supplier;
          }
        });
      });
  }

  submit() {
    if (this.itemForm.valid) {
      let loader = this.loader.open();
      this._service.store({
        ...this.itemForm.value
      })
        .finally(() => loader.close())
        .subscribe(() => {
          this.onSubmitted.emit();
          this.snack.open('User Added!', 'OK', {duration: 4000});
          SimpleReuseStrategy.deleteRouteSnapshot('///settings/user/create');
        });
    }
  }

  gotoTable() {
    this.router.navigate(['/settings/user']);
  }
}
