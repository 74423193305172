import { BuildingModel } from './../../../../models/building.model';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material';
import { AppLoaderService } from '@shared/services/system/app-loader/app-loader.service';
import { ModelAttributeOptionService } from '@shared/services/api/model-attribute-option.service';
import { AssetOptions, ModelAttributeOptions } from '@shared/models/options';
import { CompanyService } from '@shared/services/api/company.service';
import { AssetService } from '@shared/services/api/asset.service';
import * as _ from 'lodash';
import { TimeConvertService } from '@shared/services/time-convert.service';

@Component({
  selector: 'app-asset-create',
  templateUrl: './asset-create.component.html'
})
export class AssetCreateComponent implements OnInit {
  @Output() onSubmitted: EventEmitter<any> = new EventEmitter();
  @Input() data;
  @Input() inPopup = false;
  public loading$ = false;
  public itemForm = new FormGroup({
    company_id: new FormControl('', [Validators.required]),
    building_id: new FormControl('', [Validators.required]),
    name: new FormControl(''),
    category: new FormControl(''),
    description: new FormControl(''),
    service_interval: new FormControl(''),
    last_service_time: new FormControl(''),
    next_service_time: new FormControl(''),
  });
  public dataForm = new FormGroup({
    notification_period: new FormControl(0),
    asset_condition: new FormControl(''),
    compliant_status: new FormControl(''),
  });

  public get companies() {
    return this.companyService.companies;
  }

  public buildings: BuildingModel[] = [];
  public options: AssetOptions;

  constructor(
    private _service: AssetService,
    private snack: MatSnackBar,
    private loader: AppLoaderService,
    private maoService: ModelAttributeOptionService,
    private companyService: CompanyService,
    private timeConvertService: TimeConvertService
  ) {
  }

  ngOnInit() {
    this.fullForm();
    this.getOptions();
  }

  fullForm() {
    this.itemForm.get('company_id').valueChanges.subscribe((company_id: any) => {
      if (company_id) {
        this.getBuildings(company_id);
      }
    });
    this.itemForm.patchValue({
      company_id: _.get(this.data, 'companyId', ''),
      building_id: _.get(this.data, 'buildingId', '')
    });
  }

  getBuildings(company_id) {
    let loader = this.loader.open();
    this.companyService.getBuildingsByClientId(company_id)
      .finally(() => loader.close())
      .subscribe((data: any) => {
        this.buildings = data;
      });
  }

  getOptions() {
    this.maoService.all()
      .subscribe((data: ModelAttributeOptions) => {
        if (data) {
          this.options = data.asset;
        }
      });
  }

  submit() {
    let loader = this.loader.open();
    let itemFormValue = this.itemForm.value;
    itemFormValue['last_service_time'] = this.timeConvertService.convert(itemFormValue['last_service_time']);
    itemFormValue['next_service_time'] = this.timeConvertService.convert(itemFormValue['next_service_time']);
    this._service.store({...itemFormValue, data: this.dataForm.value})
      .finally(() => {
        loader.close();
      })
      .subscribe(() => {
        this.onSubmitted.emit();
        this.snack.open('Asset Added!', 'OK', {duration: 4000});
      });
  }
}
