import {Injectable} from '@angular/core';
import {ApiBaseService} from '@shared/services/api/api-base.service';
import {HttpClient} from '../../../../../node_modules/@angular/common/http';
import {LaravelPageRequest} from '@shared/models/laravel-page-request.model';
import {ModelAttributeOptions, PurchaseOrderItemOptions, PurchaseOrderOptions} from '@shared/models/options';
import {ModelAttributeOptionService} from '@shared/services/api/model-attribute-option.service';
import {GlobalSettingService} from '@shared/services/system/global-setting.service';
import {CompanyService} from '@shared/services/api/company.service';
import {Router} from '@angular/router';
import {UserModel} from '@shared/models/user.model';

@Injectable()
export class PurchaseOrderService {
  route_prefix = 'purchase-orders';
  public orderMethods = [
    {option_value: 'telephone'},
    {option_value: 'email'},
  ];
  public purchaseOrderOptions: PurchaseOrderOptions;
  public purchaseOrderItemOptions: PurchaseOrderItemOptions;
  public deliveryToOptions: { option_name: string; option_value: string; address: string }[] = [];
  public users: UserModel[] = [];
  public userIdentities: { id: number; name: string; user_identity: string; email: string }[] = [];

  constructor(
    private http: HttpClient,
    private router: Router,
    private companyService: CompanyService,
    private globalSetting: GlobalSettingService,
    private mao: ModelAttributeOptionService,
  ) {
    this.getUserIdentities();
    this.getUsers();
    this.getOptions();
    this.getDeliveryToOptions();
  }

  getDeliveryToOptions() {
    this.http.get(this.route_prefix + '/delivery-to-options')
      .subscribe((data: any) => {
        this.deliveryToOptions = data;
      });
  }

  getOptions() {
    this.mao.all()
      .subscribe((data: ModelAttributeOptions) => {
        this.purchaseOrderOptions = data.purchase_order;
        this.purchaseOrderItemOptions = data.purchase_order_item;
      });
  }


  getUsers() {
    this.companyService.users(this.globalSetting.getConfig('useCompanyId'))
      .subscribe((data: any) => {
        this.users = data;
      });
  }

  pay(id) {
    return this.http.post(this.route_prefix + '/' + id + '/pay', {});
  }

  clients() {
    return this.http.get(this.route_prefix + '/clients');
  }

  serviceSuppliers(clientId = '') {
    return this.http.get(this.route_prefix + '/service-suppliers', {params: {client_id: clientId}});
  }

  getUserIdentities() {
    return this.http.get(this.route_prefix + '/user-identities').subscribe((data: any) => {
      this.userIdentities = data;
    });
  }

  sendEmails(body: { purchase_order_id: number, emails: string[] }) {
    return this.http.post(this.route_prefix + '/send-assigned-email', body)
  }


  index(params: LaravelPageRequest | any) {
    return this.http.get(this.route_prefix, {params: params.request()});
  }

  store(data) {
    return this.http.post(this.route_prefix, data);
  }

  show(id) {
    return this.http.get(this.route_prefix + '/' + id);
  }

  destroy(id) {
    return this.http.delete(this.route_prefix + '/' + id);
  }

  update(id, data) {
    return this.http.put(this.route_prefix + '/' + id, data);
  }
}
