import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';

@Component({
  selector: 'app-quotation-edit-popup',
  template: `
    <app-page-card-header title="Quotation {{id}}" [pdf]="{type: 'quotation', id: id}" (onBack)="dialogRef.close()"></app-page-card-header>
    <app-quotation-edit mat-dialog-content [id]="id" [inPopup]="true" (onSubmitted)="dialogRef.close(true)"></app-quotation-edit>
  `
})
export class QuotationEditPopupComponent implements OnInit {
  public id;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { id: number },
    public dialogRef: MatDialogRef<QuotationEditPopupComponent>,
  ) {
    if (this.data.id) {
      this.id = data.id;
    }
  }

  ngOnInit() {
  }
}
