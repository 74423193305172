import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { GlobalSettingService } from '@shared/services/system/global-setting.service';
import { FormControl, FormGroup } from '@angular/forms';

import { AuthGuard } from '@shared/services/system/auth/auth.guard';
import { CompanyModel } from '@shared/models/company.model';
import { CompanyService } from '@shared/services/api/company.service';
import { NavigationEnd, Router } from '@angular/router';
import { GlobalSettingConfig } from '@app/shared/interfaces/global-setting-config';
import { NotifyService } from '@app/shared/services/notify.service';
import * as moment from 'moment';

@Component({
  selector: 'app-global-setting',
  templateUrl: './global-setting.component.html',
  styleUrls: ['./global-setting.component.scss']
})
export class GlobalSettingComponent implements OnInit {
  @Input() globalSettingPanel;
  public companies: CompanyModel[];

  public get clients() {
    return this.companyService.clients;
  };

  public settings: FormGroup = new FormGroup({
    client: new FormControl(),
    pageStartTimeText: new FormControl(),
    pageEndTimeText: new FormControl(),
    useCompanyId: new FormControl(),
  });

  constructor(
    private globalSettingService: GlobalSettingService,
    private toast: NotifyService,
    private companyService: CompanyService,
    private router: Router
  ) {
  }

  ngOnInit() {
    this.router.events.subscribe((routeChange) => {
      if (routeChange instanceof NavigationEnd) {
        this.globalSettingPanel.close();
      }
    });
    this.initConfig();
  }

  initConfig() {
    this.globalSettingService.config$.subscribe((settings: GlobalSettingConfig) => {
      this.companies = settings.companies;
      this.settings.patchValue({
        client: settings.client,
        pageStartTimeText: moment(settings.pageStartTimestamp),
        pageEndTimeText: moment(settings.pageEndTimestamp),
        useCompanyId: + settings.useCompanyId,
      }, {emitEvent: false});
    });

    this.settings.valueChanges.subscribe((change) => {
      let client = change.client;
      if (change.useCompanyId !== this.globalSettingService.config.useCompanyId) {
        client = null;
        this.settings.get('client').patchValue(null, {emitEvent: false});
      }

      this.globalSettingService.publishConfig({
        pageStartTimestamp: change.pageStartTimeText.toDate().getTime(),
        pageEndTimestamp: change.pageEndTimeText.toDate().getTime(),
        client: client,
        useCompanyId: change.useCompanyId
      });
      this.toast.show('Save success.');
    });
  }
}
