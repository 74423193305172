export class ServiceDashboardModel {
  status: [{count: number, status: string}];
  service_type: [{count: number, status: string}];
  ppm: {
    ppm_to_log: number,
    due_in_n_days: number,
    non_compliant: number,
    ppm_overdue: number,
    cert_missing: number,
    compliance_sign_off: number
  };
}
