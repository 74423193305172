import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import {  FormGroup, Validators, FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef, MatSnackBar, MatDialog } from '@angular/material';
import { AppLoaderService } from '@shared/services/system/app-loader/app-loader.service';
import { ModelAttributeOptionService } from '@shared/services/api/model-attribute-option.service';
import { ModelAttributeOptions, QuotationItemOptions, ServiceOptions } from '@shared/models/options';
import { QuotationItemService } from '@shared/services/api/quotation-item.service';
import { ServiceEditPopupComponent } from '../../service/service-edit/service-edit-popup.component';
import { SettingsService, Settings } from '@app/shared/services/system/settings.service';
import { get } from 'lodash';
import {debounceTime, distinctUntilChanged, filter, map, switchMap} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';

@Component({
  selector: 'app-quotation-item-create',
  templateUrl: './quotation-item-create.component.html'
})
export class QuotationItemCreatePopupComponent implements OnInit {
  @Input() id;
  @Input() public services: { type: string; id: string }[];
  @Output() onSubmitted: EventEmitter<any> = new EventEmitter();
  public loading$ = false;
  public itemFormGroup: FormGroup = new FormGroup({
    product_id: new FormControl(null),
    item_type: new FormControl('', Validators.required),
    item_name: new FormControl('', [Validators.required, Validators.maxLength(255)]),
    item_description: new FormControl(''),
    item_unit_duration: new FormControl(1, Validators.compose([Validators.required, Validators.min(1)])),
    item_unit_margin: new FormControl(0, Validators.compose([Validators.required, Validators.min(0)])),
    item_unit_price: new FormControl(0, Validators.compose([Validators.required, Validators.min(0)])),
    item_quantity: new FormControl(0, Validators.compose([Validators.required, Validators.min(0)])),
  });
  public lineTotal = 0;
  public options: QuotationItemOptions;
  public defaultMargin = 0;
  public useMargin = 0;
  public materials = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { id: number; services: { type: string; id: string }[] },
    public dialogRef: MatDialogRef<QuotationItemCreatePopupComponent>,
    private _service: QuotationItemService,
    private snack: MatSnackBar,
    private loader: AppLoaderService,
    private http: HttpClient,
    private maoService: ModelAttributeOptionService,
    public dialog: MatDialog,
    private settingsService: SettingsService,
  ) {
    if (data.id) {
      this.id = data.id;
    }
    if (data.services) {
      this.services = data.services;
    }
    settingsService.all().subscribe((settings: Settings) => {
      this.defaultMargin = get(settings, 'quotation_item_margin.unit_margin', 0);
      this.useMargin = get(settings, 'quotation_item_margin.unit_margin', 0);
      this.itemFormGroup.patchValue({ item_unit_margin: this.useMargin });
    });
  }

  ngOnInit() {
    this.buildItemForm();
    this.getOptions();
  }

  buildItemForm() {
    this.itemFormGroup.valueChanges.subscribe((change) => {
      let item_quantity = change.item_quantity || 0;
      let item_unit_price = change.item_unit_price || 0;
      this.useMargin = change.item_unit_margin || 0;
      this.lineTotal = item_quantity * item_unit_price * (1 + this.useMargin / 100);
    });
    this.itemFormGroup.get('item_type').valueChanges.subscribe((change) => {
      this.useMargin = change === 'service' ? 0 : this.defaultMargin;
      this.itemFormGroup.patchValue({item_unit_margin: this.useMargin, product_id: null}, {emitEvent: false});
      this.materials = [];
    });

    this.itemFormGroup.controls['item_name'].valueChanges
      .pipe(
        filter(text => {
          return this.itemFormGroup.controls['item_type'].value === 'material' && text.length > 2;
        }),
        debounceTime(10),
        distinctUntilChanged(),
        switchMap((res) => this.http.get('quotation-items/materials', {params: {keyword: res}}))
      )
      .subscribe((res: any) => {
        this.materials = res;
      });
  }

  getOptions() {
    this.maoService.all()
      .subscribe((data: ModelAttributeOptions) => {
        this.options = data.quotation_item;
      });
  }

  submit() {
    let item = this.itemFormGroup.value;
    if (item.item_type === 'material' && item.item_name.name) {
      item.item_name = item.item_name.name;
    }
    let data = { ...item, quotation_id: this.id };
    let loader = this.loader.open();
    this._service.store(data)
      .finally(() => {
        loader.close();
      })
      .subscribe((data1) => {
        this.onSubmitted.emit();
        this.snack.open('Quotation Item Added!', 'OK', { duration: 4000 });
        if (this.data) {
          this.dialogRef.close(true);
        }
      });
  }

  close() {
    if (this.data) {
      this.dialogRef.close();
    }
  }

  openEditServicePopup(id) {
    this.dialog.open(ServiceEditPopupComponent, {
      width: '80%',
      height: '90vh',
      disableClose: false,
      data: { id }
    }).afterClosed();
  }

  materialAutoComplete($event) {
    const item = $event.option.value;
    this.itemFormGroup.patchValue({
      product_id: item.id,
      item_unit_price: item.sell_price,
      // item_description: item.description
    });
  }

  materialName(item) {
    if (item) {
      return item.code + '-' + item.name;
    } else {
      return '';
    }
  }
}
