import {Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import { ThemeService } from '@app/shared/services/system/theme.service';

@Component({
  selector: 'app-color-ship',
  templateUrl: './color-ship.component.html',
  styleUrls: ['./color-ship.component.scss']
})
export class ColorShipComponent implements OnInit {
  @Input() public set text(t) {
    if (this.isArray(t)) {
      this.arr = t;
    }
    if (this.isString(t) || this.isNumber(t)) {
      this.arr = [t];
    }
  };
  @Input() public color = '';
  @Input() public icon = '';
  @Output() click = new EventEmitter();
  public arr = [];

  constructor(
    public theme: ThemeService,
  ) { }

  ngOnInit() {
  }

  isArray(text) {
    return Array.isArray(text);
  }

  isString(text) {
    return typeof (text) === 'string';
  }
  isNumber(text) {
    return typeof (text) === 'number';
  }


  getBackgroundColor(item) {
    if (this.color) {
      return this.color;
    } else {
      return this.theme.getStrColor(item);
    }
  }
}
