import {Injectable} from '@angular/core';
import {ApiBaseService} from '@shared/services/api/api-base.service';
import {dashboardConfigs} from '@shared/config';
import {Conditions} from '@shared/models/conditions.model';
import {HttpClient} from '@angular/common/http';
import {ModelAttributeOptionService} from './model-attribute-option.service';
import {ModelAttributeOptions, ServiceOptions} from '@app/shared/models/options';
import {CompanyModel} from '@shared/models/company.model';
import {UserService} from '@shared/services/api/user.service';
import {UserModel} from '@shared/models/user.model';
import {GlobalSettingService} from '@shared/services/system/global-setting.service';

@Injectable()
export class ServiceService extends ApiBaseService {
  route_prefix = 'services';
  allBuildings = [];
  beforeClientId = null;

  public conditions: Conditions = {
    client_id: {
      value: null,
      nullable: true,
      items: [],
      display_key: 'name',
      select_key: 'id',
      display_value: null,
      display_name: 'Client',
      display_value_key: 'name',
    },
    building_id: {
      value: null,
      nullable: true,
      items: [],
      display_key: 'name',
      select_key: 'id',
      display_value: null,
      display_name: 'Building',
      display_value_key: 'name',
    },
    service_type: {
      value: null,
      nullable: true,
      items: [],
      display_key: 'option_value',
      select_key: 'option_value',
      display_name: 'ServiceType'
    },
    discipline: {
      value: null,
      nullable: true,
      items: [],
      display_key: 'option_value',
      select_key: 'option_value',
      display_name: 'Discipline'
    },
    severity: {
      value: null,
      nullable: true,
      items: [],
      display_key: 'option_name',
      select_key: 'option_value',
      display_name: 'Priority'
    },
    status: {
      value: null,
      nullable: true,
      items: [],
      display_key: 'option_value',
      select_key: 'option_value',
      display_name: 'Status',
      multi: true
    },
    surveyor_id: {
      value: null,
      nullable: true,
      items: [],
      display_key: 'name',
      select_key: 'id',
      display_name: 'PM',
      multi: false
    },
    surveyor_source: {
      value: null,
      nullable: true,
      useGroup: false,
      items: [],
      display_name: 'Surveyor Source',
      group_key: 'surveyor_source',
      item_key: 'surveyor_source',
      hidden: true
    },
    is_invoiced: {
      value: null,
      nullable: true,
      items: [],
      display_key: 'option_name',
      select_key: 'option_value',
      display_name: 'Invoiced'
    },
    dashboard_type: {
      value: null,
      nullable: true,
      useGroup: true,
      items: dashboardConfigs.service,
      display_name: 'Dashboard Type',
      group_key: 'dashboard_group',
      item_key: 'dashboard_type',
    },
  };
  public options: ServiceOptions;

  constructor(
    public http: HttpClient,
    public maoService: ModelAttributeOptionService,
  ) {
    super(http);
    this.getOptions();
    this.getClients()
      .subscribe((data: any) => {
        this.conditions.client_id.items = data;
      });
    this.getBuildings()
      .subscribe((data: any) => {
        this.allBuildings = data;
        this.conditions.building_id.items = data;
      });
  }

  afterConditionChange() {
    const client_id = this.conditions.client_id.value;
    if (client_id && this.beforeClientId !== client_id) {
      this.beforeClientId = client_id;
      this.conditions.building_id.items = this.allBuildings.filter((building) => {
        return building.company_id === client_id;
      });
      const hasBuildingId = this.conditions.building_id.items.some((building: any) => {
        return building.id === this.conditions.building_id.value;
      });
      if (!hasBuildingId) {
        this.conditions.building_id.value = null;
      }
    } else {
      this.beforeClientId = null;
      this.conditions.building_id.items = this.allBuildings;
    }
  }

  clearCondition() {
    Object.keys(this.conditions).map(key => {
      if (this.conditions[key]['value']) {
        this.conditions[key]['value'] = null;
      }
    });
    this.conditions = {...this.conditions};
  }

  getOptions() {
    this.maoService.all()
      .subscribe((data: ModelAttributeOptions) => {
        this.conditions.discipline.items = data.service.discipline.filter(item => item.status !== 'inactive');
        this.conditions.status.items = data.service.status;
        this.conditions.severity.items = data.service.severity.map((item) => {
          item.option_name = item.option_value;
          return item;
        });
        this.conditions.service_type.items = data.service.service_type;
        this.conditions.is_invoiced.items = [{
          option_value: 'invoiced',
          option_name: 'invoiced',
        }, {
          option_value: 'not_invoiced',
          option_name: 'not_invoiced',
        }];
        this.options = {...data.service, discipline: data.service.discipline.filter(item => item.status !== 'inactive')};
      });
    this.getSurveyors().subscribe((data: any) => {
      this.conditions.surveyor_id.items = data.list;
    })
  }

  getSurveyors() {
    return this.http.post(`service/get-surveyors`, {});
  }

  getClients() {
    return this.http.post(`service/get-clients`, {});
  }

  getBuildings() {
    return this.http.post(`service/get-buildings`, {});
  }

  engineers(serviceId) {
    return this.http.get(`services/${serviceId}/engineers`);
  }

  getDefaultBudget(clientId) {
    return this.http.get(`services/${clientId}/default-budget`);
  }

  getVisits(service_id) {
    return this.http.post('service/visits', {service_id});
  }

  complete(service_id) {
    return this.http.post(`services/${service_id}/complete`, null);
  }

  filterServicesByBuilding(buildingId, keyword = '') {
    return this.http.get(`${this.route_prefix}/filter-services/building/${buildingId}`, {params: {keyword}});
  }

  cancel(serviceId) {
    return this.http.post(`services/${serviceId}/cancel`, null);
  }

  assignTo(serviceId, engineers, eta) {
    return this.http.post(`services/${serviceId}/assign-to`, {engineers, eta});
  }

  checkValid(serviceId, type = '') {
    return this.http.post(`services/${serviceId}/valid`, {type});
  }

  // service uplift
  storeUplift(serviceId, {description = '', user_ids = [], budget = 0}) {
    return this.http.post(`services/${serviceId}/uplifts`, {description, user_ids, budget});
  }

  uplifts(serviceId) {
    return this.http.get(`services/${serviceId}/uplifts`);
  }

  getLastVisitNote(serviceId) {
    return this.http.get(`services/${serviceId}/last-visit-note`, {responseType: 'text'});
  }

  viewPdfs(paperId) {
    return this.http.get(`papers/${paperId}/view-pdfs`);
  }

  getActionLogs(serviceId) {
    return this.http.get(`services/action-log/${serviceId}`);
  }

  approve(serviceId, body?: { expect_start_time: string; }) {
    return this.http.post(`services/${serviceId}/approve`, body);
  }

  reject(serviceId, body?: { note: string; }) {
    return this.http.post(`services/${serviceId}/reject`, body);
  }
}
