import { Component, forwardRef, Input, OnDestroy, OnInit } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ReplaySubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import * as _ from 'lodash';


/**
 * Example use:
 *
 * <app-simple-select-search
 [formControl]="bankCtrl"
 [items]="banks"
 [nullable]="true"
 [placeholderLabel]="'Search'"
 [placeholder]="'Select Options'"
 [display_name]="'name'"
 [display_value]="'_id'"
 [noEntriesFoundLabel]="'Option Not Found'"
 [clearSearchInput]="true"
 >
 </app-simple-select-search>
 *
 *
 *
 */
@Component({
  selector: 'app-simple-select-search',
  templateUrl: './simple-select-search.component.html',
  styleUrls: ['./simple-select-search.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SimpleSelectSearchComponent),
      multi: true
    }
  ]
})
export class SimpleSelectSearchComponent implements OnInit, OnDestroy, ControlValueAccessor {
  private _items = [];
  @Input()
  set items(items: any[]) {
    this._items = items || [];
    this.filteredItems.next(this._items.slice());
    this.filterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterItems();
      });
    if (this.waitItems) {
      this.setCtrlValue(this._value);
    }
  }

  get items() {
    return this._items;
  }

  @Input() display_name = 'name';
  @Input() display_value = 'id';
  @Input() placeholder = 'Select';
  @Input() placeholderLabel = 'Search';
  @Input() noEntriesFoundLabel = 'Option Not Found';
  @Input() clearSearchInput = true;
  @Input() nullable = false;
  @Input() fieldClasses = [];
  @Input() required = false;
  @Input() disabled = false;

  waitItems = true;

  public ctrl: FormControl = new FormControl(); // select option value
  public filterCtrl: FormControl = new FormControl();
  public filteredItems: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
  private _onDestroy = new Subject<void>();

  get value(): string {
    return this._value;
  }

  set value(value: string) {
    this._value = value;
    this.onChange(this._value);
  }

  private _value = '';
  onChange = (_: any) => { };
  onTouched = (_: any) => { };

  constructor() {
  }

  ngOnInit() {
    this.ctrl.valueChanges
      .subscribe((item) => {
        const value = item ? item[this.display_value] : item;
        if (value !== this._value) {
          this._value = value;
          this.onChange(value);
        }
      });
  }

  private filterItems() {
    if (!this._items) {
      return;
    }

    // get the search keyword
    let search = this.filterCtrl.value;
    if (!search) {
      this.filteredItems.next(this._items.slice());
      return;
    } else {
      search = search.toLowerCase();
    }

    // filter the banks
    this.filteredItems.next(
      this._items.filter(item => item[this.display_name].toLowerCase().indexOf(search) > -1)
    );
  }

  ngOnDestroy(): void {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  registerOnChange(fn) {
    this.onChange = fn;
  }

  registerOnTouched(fn) {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
  }

  writeValue(value: string | null): void {
    this.setCtrlValue(value);
    if (value) {
      this._value = value;
    }
  }

  setCtrlValue(value: string) {
    if (this._items.length > 0) {
      if (!value) {
        this.ctrl.setValue(value);
      } else {
        const valueIndex = _.findIndex(this._items, [this.display_value, value]);
        if (valueIndex !== -1) {
          this.ctrl.setValue(this._items[valueIndex]);
        }
      }
      this.waitItems = false;
    } else {
      this.waitItems = true;
    }
  }

}
