import { Component, Input, OnInit, Renderer2 } from '@angular/core';
import { ThemeService } from '../../../services/system/theme.service';
import { LayoutService } from '../../../services/system/layout.service';
import { TranslateService } from '@ngx-translate/core';
import { AuthGuard } from '../../../services/system/auth/auth.guard';
import { Router } from '@angular/router';

import { SettingService } from '@shared/services/api/setting.service';
import { AuthService } from '@shared/services/auth.service';
import {HttpCancelService} from '@shared/services/system/http-cancel.service';

@Component({
  selector: 'app-header-side',
  templateUrl: './header-side.template.html'
})
export class HeaderSideComponent implements OnInit {
  @Input() globalSettingPanel;

  currentLang = 'en';
  public availableLangs = [{
    name: 'English',
    code: 'en',
  }, {
    name: 'Chinese',
    code: 'zh-cn',
  }];
  public egretThemes;
  public layoutConf: any;

  constructor(
    private themeService: ThemeService,
    private layout: LayoutService,
    public translate: TranslateService,
    private renderer: Renderer2,
    private authGuard: AuthGuard,
    private router: Router,
    private httpCancel: HttpCancelService,
    private setting: SettingService,
    public auth: AuthService,
  ) {
  }

  ngOnInit() {
    this.egretThemes = this.themeService.egretThemes;
    this.layoutConf = this.layout.layoutConf;
    this.translate.use(this.currentLang);
  }

  setLang(e) {
    this.translate.use(this.currentLang);
  }

  changeTheme(theme) {
    this.themeService.changeTheme(this.renderer, theme);
  }

  toggleSidenav() {
    if (this.layoutConf.sidebarStyle === 'closed') {
      return this.layout.publishLayoutChange({
        sidebarStyle: 'full'
      });
    }
    this.layout.publishLayoutChange({
      sidebarStyle: 'closed'
    });
  }

  toggleGlobalSetting() {
    this.globalSettingPanel.toggle();
  }

  toggleCollapse() {
    // compact --> full
    if (this.layoutConf.sidebarStyle === 'compact') {
      return this.layout.publishLayoutChange({
        sidebarStyle: 'full'
      }, {transitionClass: true});
    }

    // * --> compact
    this.layout.publishLayoutChange({
      sidebarStyle: 'compact'
    }, {transitionClass: true});

  }

  signout() {
    this.authGuard.signout();
  }

  onEnter(keyword) {
    if (keyword) {
      this.router.navigate(['/search', keyword, {k: new Date().getTime()}]);
    }
  }

  changeTableStyle() {
    this.layout.publishLayoutChange(
      {switchTable: this.layout.layoutConf.switchTable === 'table' ? 'panel' : 'table'}
    );
  }

  refresh() {
    this.httpCancel.cancelPendingRequests();
    this.layout.onRefresh.emit();
  }

  refreshCache() {
    this.setting.refreshCache();
  }
}
