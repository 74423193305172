import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {AppLoaderService} from '@shared/services/system/app-loader/app-loader.service';
import {MatSnackBar} from '@angular/material';
import {Router} from '@angular/router';
import {ModelAttributeOptionService} from '@shared/services/api/model-attribute-option.service';
import {UserService} from '@shared/services/api/user.service';
import {ModelAttributeOptions, UserCompanyOptions, UserOptions} from '@shared/models/options';
import {AppConfirmService} from '@shared/services/system/app-confirm/app-confirm.service';
import {CompanyService} from '@shared/services/api/company.service';
import {NotifyService} from '@shared/services/notify.service';
import {AppTableComponent} from '@shared/components/system/table/table.component';
import {LaravelPageRequest} from '@shared/models/laravel-page-request.model';
import {tap} from 'rxjs/operators';
import {LaravelPageResponse} from '@shared/models/laravel-page-response.model';
import {HolidayApprovalService} from '@shared/services/system/holiday-approval/holiday-approval.service';

@Component({
  selector: 'app-user-edit',
  templateUrl: './user-edit.component.html',
  styleUrls: ['./user-edit.component.scss']
})
export class UserEditComponent implements OnInit {
  public userOptions: UserOptions;
  public userCompanyOptions: UserCompanyOptions;
  public relationTypes = [];
  public user_holiday = {
    items: [],
    columns: [],
    loading$: true,
    paginator: {
      totalElements: 0,
      pageNumber: 0,
      size: 20,
    }
  };
  @Input() id;
  @ViewChild(AppTableComponent) tableComponent: AppTableComponent;

  public itemForm: FormGroup = new FormGroup({
    name: new FormControl('', [Validators.required]),
    phone: new FormControl(''),
    email: new FormControl( { value: '', disabled: true }),
    user_identity: new FormControl(''),
    address1: new FormControl(''),
    address2: new FormControl(''),
    town: new FormControl(''),
    country: new FormControl(''),
    city: new FormControl(''),
    postcode: new FormControl(''),
    status: new FormControl(''),
    user_company: new FormControl([]),
  });

  public item;

  constructor(
    private _service: UserService,
    private loader: AppLoaderService,
    private snack: MatSnackBar,
    private router: Router,
    private confirm: AppConfirmService,
    private companyService: CompanyService,
    private toast: NotifyService,
    private mao: ModelAttributeOptionService,
    private holidayApproval: HolidayApprovalService,
  ) {
  }

  ngOnInit() {
    this.initData();
  }

  initData() {
    this.show(this.id);
    this.getOptions();
    this.loadPage().subscribe();
  }

  getOptions() {
    this.mao
        .all()
        .subscribe((data: ModelAttributeOptions) => {
          this.userOptions = data.user;
          this.userCompanyOptions = data.user_company;
          data.user_company.type.forEach((type) => {
            if (type.option_name === 'user_company_type') {
              this.relationTypes = type.data.supplier;
            }
          });
        });
  }

  show(id) {
    let loader = this.loader.open();
    this._service.show(id)
      ._finally(() => loader.close())
      .subscribe((data: any) => {
        this.item = data;
        this.itemForm.patchValue(data);
      });
  }

  submit() {
    let loader = this.loader.open();
    this._service.update(this.id, this.itemForm.value)
      .finally(() => loader.close())
      .subscribe(() => {
        this.refresh();
        this.toast.show('User Updated!');
      });
  }

  refresh() {
    this.initData();
  }

  openHolidayEditPage(row) {
    this.holidayApproval.show(row)
      .subscribe((res) => {
        if (res) {
          let message = res.status == 'approved' ? 'Approved this holiday' : 'Declined this holiday';
          this.confirm.confirm({ message: message })
            .subscribe(confirm_res => {
              if (confirm_res) {
                let loader = this.loader.open();
                this._service.approvalUserHoliday(row.id, {...res})
                  .finally(() => {
                    loader.close();
                  })
                  .subscribe(() => {
                    message = res.status == 'approved' ? 'Approved success' : 'Declined success';
                    this.toast.show(message);
                    this.refresh();
                  });
              }
            });

        }
      });
  }

  clickPage({ offset }) {
    this.user_holiday.paginator.pageNumber = offset + 1;
    this.loadPage().subscribe();
  }

  loadPage({ keyword }: any = { keyword: '' }) {
    this.user_holiday.loading$ = true;
    let request = new LaravelPageRequest(this.user_holiday.paginator.size, this.user_holiday.paginator.pageNumber, {}, keyword);
    return this._service.getUserHolidays(this.id, request)
      .pipe(
        tap(
          (response: LaravelPageResponse) => {
            this.user_holiday.paginator.size = response.per_page;
            this.user_holiday.paginator.pageNumber = response.current_page;
            this.user_holiday.paginator.totalElements = response.total;
            this.user_holiday.items = response.data;
          }
        ),
      )
      .finally(() => this.user_holiday.loading$ = false);
  }
}
