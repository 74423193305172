import { Component, EventEmitter, Input, OnInit, Output, OnDestroy } from '@angular/core';
import { DisplayColumnsService } from '@shared/services/display-columns.service';
import { ServiceVisitModel } from '@shared/models/service-visit.model';
import { ServiceService } from '@shared/services/api/service.service';
import { ServiceVisitService } from '@shared/services/api/service-visit.service';
import { AppConfirmService } from '@shared/services/system/app-confirm/app-confirm.service';

import { PopupService } from '@shared/services/popup.service';
import { LayoutService } from '@app/shared/services/system/layout.service';
import { NotifyService } from '@app/shared/services/notify.service';

@Component({
  selector: 'app-service-visit-table',
  templateUrl: './service-visit-table.component.html'
})
export class ServiceVisitTableComponent implements OnInit, OnDestroy {
  @Input() id;
  @Output() onLoading = new EventEmitter();
  @Output() onEdited = new EventEmitter();
  public items: ServiceVisitModel[] = [];
  private onRefreshSub;

  constructor(
    public displayColumnsService: DisplayColumnsService,
    private serviceService: ServiceService,
    private serviceVisitService: ServiceVisitService,
    private confirmService: AppConfirmService,
    private toast: NotifyService,
    private popup: PopupService,
    private layout: LayoutService,
  ) {
  }

  ngOnInit() {
    this.refresh();
    this.onRefreshSub = this.layout.onRefresh.subscribe(() => this.refresh());

  }

  ngOnDestroy() {
    if (this.onRefreshSub) {
      this.onRefreshSub.unsubscribe();
    }
  }

  deleteItem(row) {
    this.confirmService.confirm({ message: `Delete ${row.id}?` })
      .subscribe(res => {
        if (res) {
          this.onLoading.emit(true);
          this.serviceVisitService.destroy(row.id)
            .finally(() => {
              this.onLoading.emit(false);
            })
            .subscribe(() => {
              this.refresh();
              this.toast.show(`Service visit ${row.id} deleted!`);
            });
        }
      });
  }

  openEditPage(row) {
    this.popup.openServiceVisitEditPage(row.id)
      .afterClosed()
      .subscribe((res) => {
        if (res) {
          this.onEdited.emit(true);
          this.refresh();
        }
      });
  }

  refresh() {
    this.onLoading.emit(true);
    this.serviceService.getVisits(this.id)
      .finally(() => this.onLoading.emit(false))
      .subscribe((response: ServiceVisitModel[]) => {
        this.items = response;
      });
  }
}
