import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CacheService {

  constructor() { }

  set(key, value) {
    localStorage.setItem(key, JSON.stringify(value));
  }

  get(key, defaultValue: any = undefined) {
    let dataString = localStorage.getItem(key);
    let data;
    if (dataString) {
      data = JSON.parse(dataString);
    } else {
      data = defaultValue;
    }
    return data;
  }

  remove(key) {
    localStorage.removeItem(key);
  }

  removeAll() {
    for (const key in CacheKeys) {
      if (CacheKeys.hasOwnProperty(key)) {
        const cacheKey = CacheKeys[key];
        localStorage.removeItem(cacheKey);
      }
    }
  }
}

export const CacheKeys = {
  global_setting: 'aims/agent-global-setting',
  timesheet_conditions: 'aims/timesheet/conditions'
  // layout: 'aims/layout'
}