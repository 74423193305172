import { AppLoaderService } from '@app/shared/services/system/app-loader/app-loader.service';
import { CompanyService } from '@shared/services/api/company.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Component, Inject, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-quotation-client-review-create',
  template: `
    <h1 mat-dialog-title translate>Client Review</h1>
    <div mat-dialog-content>
      <p translate>You need to fill in some information to create approval</p>
      <div fxLayout="row wrap" >
        <div fxFlex="100" class="pr-1">
          <app-search-select [items]="clientUsers" [placeholder]="'Client Users'" [field_classes]="'full-width'" [form_ctrl]="userIds"
            [value_column]="'id'" [display_column]="'name'" [filter_column]="'name'" [multi]="true"></app-search-select>
        </div>
        <div fxFlex="100" class="pr-1">
          <mat-form-field class="full-width">
            <textarea rows="5" matInput name="Note" [formControl]="note" placeholder="Note"></textarea>
          </mat-form-field>
        </div>
      </div>
    </div>
    <div mat-dialog-actions>
      <button mat-raised-button color="primary" (click)="submit()" translate>Ok</button>
    </div>
  `
})
export class QuotationClientReviewCreate implements OnInit {
  note = new FormControl('');
  userIds = new FormControl([]);
  clientUsers = [];

  constructor(
    public dialogRef: MatDialogRef<QuotationClientReviewCreate>,
    @Inject(MAT_DIALOG_DATA) public data: { clientId: string },
    private companyService: CompanyService,
    private loader: AppLoaderService
  ) {
  }

  ngOnInit(): void {
    let loader = this.loader.open();
    this.companyService.users(this.data.clientId)
      .finally(() => loader.close())
      .subscribe((data: any) => {
        this.clientUsers = data;
      });
  }

  submit() {
    this.dialogRef.close({ note: this.note.value, userIds: this.userIds.value });
  }
}
