import 'hammerjs';
import 'mousetrap';
import {GalleryModule} from '@ks89/angular-modal-gallery';
import {NgModule, LOCALE_ID} from '@angular/core';
import {RouterModule, RouteReuseStrategy, PreloadAllModules} from '@angular/router';
import {BrowserModule, HAMMER_GESTURE_CONFIG} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {GestureConfig, MAT_SNACK_BAR_DEFAULT_OPTIONS} from '@angular/material';

import {rootRouterConfig} from './app.routing';
import {SharedModule} from '@shared/shared.module';
import {AppComponent} from './app.component';


import {HttpClient, HttpClientModule} from '@angular/common/http';
import {TranslateModule, TranslateLoader} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';

import {httpInterceptorProviders} from '@shared/http-interceptors';
import {Md2Module} from 'md2';
import {FormsModule} from '@angular/forms';
import {services} from '@shared/services';
import {SimpleReuseStrategy} from './simple-reuse-strategy';
import {registerLocaleData} from '@angular/common';
import localeEnGb from '@angular/common/locales/en-GB';
import * as moment from 'moment';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FullCalendarModule } from '@fullcalendar/angular';
import { AgmCoreModule } from '@agm/core';
import { AgmSnazzyInfoWindowModule } from '@agm/snazzy-info-window';

// AoT requires an exported function for factories
export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient, './assets/i18n/', '.json?t=2022020701');
}

registerLocaleData(localeEnGb, 'en-GB')
moment.locale('en-GB');

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    SharedModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    RouterModule.forRoot(rootRouterConfig, {
      useHash: false,
      enableTracing: false,
      onSameUrlNavigation: 'reload',
      preloadingStrategy: PreloadAllModules
    }),
    Md2Module,
    FormsModule,
    FlexLayoutModule,
    FullCalendarModule,
    AgmSnazzyInfoWindowModule,
    GalleryModule.forRoot(),
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyBSH-FqXZ6x3v98ukXrM6pUyt08c0Gr2YQ'
    })
  ],
  declarations: [AppComponent],
  providers: [
    // ANGULAR MATERIAL SLIDER FIX
    {provide: HAMMER_GESTURE_CONFIG, useClass: GestureConfig},
    {provide: RouteReuseStrategy, useClass: SimpleReuseStrategy},
    {provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: {duration: 4000, verticalPosition: 'top', MatSnackBarHorizontalPosition: 'right'}},
    {provide: LOCALE_ID, useValue: 'en-GB'},
    httpInterceptorProviders,
    ...services
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
