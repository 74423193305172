import { Injectable } from '@angular/core';
import { ApiBaseService } from '@shared/services/api/api-base.service';

@Injectable()
export class SalaryTimeService extends ApiBaseService {
  route_prefix = 'salary-times';

  deleteTravel(data) {
    return this.http.post('salary-times/delete-travel', data);
  }

  addTravel(data) {
    return this.http.post('salary-times/add-travel', data);
  }

  updateTravel(data) {
    return this.http.post('salary-times/update-travel', data);
  }

  addTopUpFuel(data) {
    return this.http.post('timesheet/add-top-up-fuel', data);
  }

  updateTopUpFuel(data) {
    return this.http.post('timesheet/update-top-up-fuel', data);
  }

  delTopUpFuel(data) {
    return this.http.post('timesheet/delete-top-up-fuel', data);
  }
}
