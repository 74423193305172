import {LayoutService} from '@shared/services/system/layout.service';
import {QuotationModel} from '@shared/models/quotation.model';
import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AppLoaderService} from '@shared/services/system/app-loader/app-loader.service';
import {MatSnackBar} from '@angular/material';
import {ActivatedRoute, Router} from '@angular/router';
import {AppConfirmService} from '@shared/services/system/app-confirm/app-confirm.service';
import {QuotationService} from '@shared/services/api/quotation.service';
import {QuotationItemService} from '@shared/services/api/quotation-item.service';
import {ServiceService} from '@shared/services/api/service.service';
import {ModelAttributeOptionService} from '@shared/services/api/model-attribute-option.service';
import {CompanyService} from '@shared/services/api/company.service';
import {DisplayColumnsService} from '@shared/services/display-columns.service';
import {PopupService} from '@shared/services/popup.service';
import * as _ from 'lodash';

import {ConfirmWithNoteService} from '@app/shared/services/system/confirm-with-note/confirm-with-note.service';
import {NotifyService} from '@app/shared/services/notify.service';

@Component({
  selector: 'app-quotation-edit',
  templateUrl: './quotation-edit.component.html'
})
export class QuotationEditComponent implements OnInit, OnDestroy {
  public loading$ = false;
  public itemForm: FormGroup;
  public dataFormGroup: FormGroup;
  public quotationItemDisplayColumns = [
    {display: 'Type', key: 'item_type', useColor: false},
    {display: 'Quantity', key: 'item_quantity', useColor: false},
    {display: 'Name', key: 'item_name', useColor: false},
    {display: 'Price', key: 'item_unit_price', useColor: false},
    {display: 'Markup (%)', key: 'item_unit_margin', useColor: false},
    {display: 'Total Price', key: 'item_total_price', useColor: false}
  ];

  @Input() id;
  @Input() inPopup = false;
  @Output() onSubmitted: EventEmitter<any> = new EventEmitter();

  public item: QuotationModel;

  public get clients() {
    return this.companyService.clients;
  };

  public serviceIdIsValid = false;
  private onRefreshSub;

  constructor(
    private fb: FormBuilder,
    public quotationService: QuotationService,
    private serviceService: ServiceService,
    public quotationItemService: QuotationItemService,
    private loader: AppLoaderService,
    private snack: MatSnackBar,
    private confirmService: AppConfirmService,
    private route: ActivatedRoute,
    private router: Router,
    private maoService: ModelAttributeOptionService,
    private companyService: CompanyService,
    public displayColumnsService: DisplayColumnsService,
    private popup: PopupService,
    public toast: NotifyService,
    private layout: LayoutService,
    private confirmWithNoteService: ConfirmWithNoteService
  ) {
  }

  ngOnInit() {
    if (this.inPopup) {
      //
    } else {
      this.id = this.route.snapshot.paramMap.get('id');
    }
    this.buildItemForm();
    this.show();
    this.onRefreshSub = this.layout.onRefresh.subscribe(() => this.refresh());

  }

  buildItemForm() {
    this.itemForm = this.fb.group({
      type: ['', Validators.required],
      status: ['', Validators.required],
      description: [''],
    });

    this.dataFormGroup = this.fb.group({
      service_id: ['', {updateOn: 'blur'}],
      discipline: [''],
      out_of_hours_work_required: ['no'],
      we_require_another_discipline_contractor_for_these_works: ['no'],
      special_terms: [''],
      client_reference: [''],
      lead_in_time: [''],
      work_duration: [''],
      client_name: [''],
      building_name: [''],
      building_address: [''],
    });
    this.dataFormGroup.get('service_id').valueChanges.subscribe((change) => {
      if (!change) {
        this.serviceIdIsValid = false;
      } else {
        this.serviceService.checkValid(change, 'quotation')
          .subscribe((data: any) => {
            if (data.valid) {
              this.serviceIdIsValid = true;
            } else {
              this.serviceIdIsValid = false;
            }
          });
      }
    });
  }

  fillItemForm(item) {
    this.itemForm = this.fb.group(item);
    this.dataFormGroup.patchValue({
      service_id: _.get(item, 'data.service_id', ''),
      discipline: _.get(item, 'data.discipline', ''),
      out_of_hours_work_required: _.get(item, 'data.out_of_hours_work_required', ''),
      we_require_another_discipline_contractor_for_these_works: _.get(item, 'data.we_require_another_discipline_contractor_for_these_works', ''),
      special_terms: _.get(item, 'data.special_terms', ''),
      client_reference: _.get(item, 'data.client_reference', ''),
      lead_in_time: _.get(item, 'data.lead_in_time', ''),
      work_duration: _.get(item, 'data.work_duration', ''),
      client_name: _.get(item, 'data.client_name', ''),
      building_name: _.get(item, 'data.building_name', ''),
      building_address: _.get(item, 'data.building_address', ''),
    });
  }

  show() {
    let loader = this.loader.open();
    this.quotationService.show(this.id)
      .finally(() => {
        loader.close();
      })
      .subscribe((data: any) => {
        this.item = data;
        this.fillItemForm(data);
      });
  }

  submit() {
    let data = {...this.itemForm.value, id: this.id, data: this.dataFormGroup.value};
    let loader = this.loader.open();
    this.quotationService.update(this.id, data)
      .finally(() => {
        loader.close();
        this.refresh();
        this.quotationService.refresh();
      })
      .subscribe(() => {
        this.snack.open('Quotation updated!', 'OK', {duration: 4000});
      });
  }

  openEditServicePopUp(row) {
    this.popup.openServiceEditPage(row.id).afterClosed()
      .subscribe(res => {
        if (!res) {
          return;
        }
        this.refresh();
      });
  }

  openQuotationItemCreatePopup() {
    this.popup.openQuotationItemCreatePage(this.id, this.item.data.relation.service)
      .afterClosed()
      .subscribe((res) => {
        if (!res) {
          return;
        }
        this.refresh();
      });
  }

  deleteItem(row) {
    this.confirmService.confirm({message: `Delete ${row.id}?`})
      .subscribe(res => {
        if (res) {
          let loader = this.loader.open();
          this.quotationItemService.destroy(row.id)
            .finally(() => {
              loader.close();
            })
            .subscribe(() => {
              this.refresh();
              this.snack.open(`Quotation item ${row.id} deleted!`, 'OK', {duration: 4000});
            });
        }
      });
  }

  openEditItemPopup(row) {
    this.popup.openQuotationItemEditPage(row.id)
      .afterClosed()
      .subscribe(res => {
        if (res) {
          this.refresh();
        }
      });
  }

  gotoTable() {
    this.router.navigate(['/quotation']);
  }

  refresh() {
    this.show();
  }

  openEditServicePopup(serviceId) {
    this.popup.openServiceEditPage(serviceId);
  }

  ngOnDestroy(): void {
    if (this.onRefreshSub) {
      this.onRefreshSub.unsubscribe();
    }
  }

  generateService() {
    this.confirmWithNoteService.show({title: `Confirm create service?`})
      .subscribe((res) => {
        if (res) {
          let loader = this.loader.open();
          this.quotationService.generateService(this.id, {...res})
            .finally(() => {
              loader.close();
            })
            .subscribe(() => {
              this.toast.show('Create service success.');
              this.refresh();
            });
        }
      });
  }

  openClientReview() {
    this.popup.openQuotationClientReview(this.item.target_company_id).afterClosed().subscribe((res) => {
      if (res) {
        let loader = this.loader.open();
        this.quotationService.clientReview(this.id, {note: res.note, user_ids: res.userIds})
          .finally(() => loader.close())
          .subscribe(() => {
            this.toast.show('Create client review success.');
            this.refresh();
          });
      }
    });
  }

  approve() {
    this.confirmWithNoteService.show({title: `Approving this quotation.`})
      .subscribe((res) => {
        if (res) {
          let loader = this.loader.open();
          this.quotationService.approve(this.id)
            .finally(() => {
              loader.close();
            })
            .subscribe(() => {
              this.toast.show('Quotation approve success.');
              this.refresh();
            });
        }
      });
  }

  reject() {
    this.confirmWithNoteService.show({title: `Rejecting this quotation.`})
      .subscribe((res) => {
        if (res) {
          let loader = this.loader.open();
          this.quotationService.reject(this.id)
            .finally(() => {
              loader.close();
            })
            .subscribe(() => {
              this.toast.show('Quotation reject success.');
              this.refresh();
            });
        }
      });
  }

  getTotal(type = null) {
    if (this.item) {
      let items = this.item.quotation_items;
      if (type) {
        items = items.filter(item => item.item_type === type);
      }
      return items
        .map(item => {
          let price = item.item_total_price;
          if (typeof price === 'string') {
            price = parseFloat(price);
          }
          return price;
        })
        .reduce((p, c) => {
          return p + c;
        }, 0);
    } else {
      return null;
    }
  }

  copyQuotation() {
    this.confirmService.confirm({message: `This action will covered your quotation temp cache, please confirm`})
      .subscribe(res => {
        if (res) {
          localStorage.setItem('quotation-create', JSON.stringify({
            quotationItems: this.item.quotation_items,
            formGroup: {
              ...this.itemForm.value,
              data: this.dataFormGroup.value
            }
          }));
          this.router.navigate(['/quotation/create']);
        }
      });
  }
}
