import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';

@Component({
  selector: 'app-quotation-table-popup',
  template: `
    <app-quotation-table [dashboardType]="dashboardType" [dashboardGroup]="dashboardGroup" [inPopup]="true" (onClickEdit)="onClickEdit()"></app-quotation-table>`
})
export class QuotationTablePopupComponent implements OnInit {
  public dashboardType;
  public dashboardGroup;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { dashboardType: string; dashboardGroup: string },
    public dialogRef: MatDialogRef<QuotationTablePopupComponent>,
  ) {
    if (this.data.dashboardType) {
      this.dashboardType = data.dashboardType;
    }
    if (this.data.dashboardGroup) {
      this.dashboardGroup = data.dashboardGroup;
    }
  }

  ngOnInit() {
  }

  onClickEdit() {
    this.dialogRef.close();
  }
}
