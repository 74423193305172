import { Injectable } from '@angular/core';
import { FileUploader } from 'ng2-file-upload';
import { AuthGuard } from '@shared/services/system/auth/auth.guard';

@Injectable({
  providedIn: null
})
export class UploaderService {

  constructor(
    private auth: AuthGuard,
  ) {
  }

  get(options: {url: string, itemAlias?: string, autoUpload?: boolean}) {
    const appendHeaders = this.auth.appendHeaders();
    let headers = [];
    for (const key in appendHeaders) {
      if (appendHeaders.hasOwnProperty(key)) {
        headers.push({
          name: key,
          value: appendHeaders[key],
        });
      }
    }

    let uploader = new FileUploader({
      url: options.url,
      method: 'POST',
      autoUpload: options['autoUpload'] || false,
      disableMultipart: false,
      itemAlias: options['itemAlias'],
      headers: headers
    });
    uploader.onAfterAddingFile = (file) => file.withCredentials = false;
    return uploader;
  }
}
