import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {FormGroup, Validators, FormControl} from '@angular/forms';
import {AppLoaderService} from '@shared/services/system/app-loader/app-loader.service';
import {Router} from '@angular/router';
import {CompanyService} from '@shared/services/api/company.service';
import {ModelAttributeOptionService} from '@shared/services/api/model-attribute-option.service';
import {CompanyOptions, ModelAttributeOptions} from '@shared/models/options';
import {NotifyService} from '@app/shared/services/notify.service';
import {SimpleReuseStrategy} from '@app/simple-reuse-strategy';

@Component({
  selector: 'app-company-create',
  templateUrl: './company-create.component.html',
  styleUrls: ['./company-create.component.scss'],
  providers: [SimpleReuseStrategy]
})
export class CompanyCreateComponent implements OnInit {
  @Output() onSubmitted: EventEmitter<any> = new EventEmitter();
  public loading$ = false;
  public itemFormGroup: FormGroup = new FormGroup({
    type: new FormControl([], [Validators.required]),
    industry: new FormControl(''),
    name: new FormControl('', [Validators.required]),
    alias: new FormControl(''),
    address1: new FormControl(''),
    address2: new FormControl(''),
    town: new FormControl(''),
    country: new FormControl(''),
    city: new FormControl(''),
    postcode: new FormControl(''),
    phone: new FormControl(''),
    email: new FormControl('', [Validators.email]),
    status: new FormControl('active'),
    data: new FormGroup({
      category: new FormControl([])
    })
  });
  public categories = [
    {option_value: 'product'},
    {option_value: 'service'},
  ];
  public companyOption: CompanyOptions;

  constructor(
    private _service: CompanyService,
    private loader: AppLoaderService,
    private router: Router,
    private maoService: ModelAttributeOptionService,
    private toast: NotifyService,
  ) {
  }

  ngOnInit() {
    this.getOptions();
  }

  disabledType(type) {
    return ['agent'].indexOf(type) !== -1;
  }

  getOptions() {
    this.maoService.all()
      .subscribe((data: ModelAttributeOptions) => {
        this.companyOption = data.company;
      });
  }

  submit() {
    let loader = this.loader.open();
    this._service.store(this.itemFormGroup.value)
      .finally(() => {
        loader.close();
      })
      .subscribe(() => {
        this.onSubmitted.emit();
        SimpleReuseStrategy.deleteRouteSnapshot('///settings/company/create');
        this.toast.show('Company Added!', 'OK');
      });
  }

  gotoTable() {
    this.router.navigate(['/settings/company']);
  }
}
