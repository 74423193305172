import {Injectable} from '@angular/core';
import {ApiBaseService} from '@shared/services/api/api-base.service';

@Injectable()
export class PurchaseOrderItemService extends ApiBaseService {
  route_prefix = 'purchase-order-items';

  options () {
    return this.http.get(this.route_prefix + '/' + 'options');
  }
}
