import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'seconds'
})
export class SecondsPipe implements PipeTransform {

  transform(value: any, type: string): any {
    if (type === 'hour') {
      return Math.floor(value / 3600);
    }
    if (type === 'minute') {
      return Math.floor(value / 60) % 60;
    }
    return value;
  }

}
