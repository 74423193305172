import { Component, OnInit, Inject, EventEmitter, Output } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatSnackBar } from '@angular/material';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AppLoaderService } from '@shared/services/system/app-loader/app-loader.service';
import { InvoiceItemOptions, ModelAttributeOptions } from '@shared/models/options';
import { ModelAttributeOptionService } from '@shared/services/api/model-attribute-option.service';
import { InvoiceItemService } from '@shared/services/api/invoice-item.service';
import { InvoiceItemModel } from '@shared/models/invoice-item.model';

@Component({
  selector: 'app-invoice-item-edit-popup',
  templateUrl: './invoice-item-edit.component.html'
})
export class InvoiceItemEditPopupComponent implements OnInit {
  public id;
  public view = false;
  public itemForm: FormGroup;
  public lineTotal = 0;
  public item: InvoiceItemModel;
  public options: InvoiceItemOptions;
  @Output() onUpdated = new EventEmitter();

  constructor(
    private fb: FormBuilder,
    private _service: InvoiceItemService,
    private loader: AppLoaderService,
    private snack: MatSnackBar,
    public dialogRef: MatDialogRef<InvoiceItemEditPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public maoService: ModelAttributeOptionService
  ) {
  }

  ngOnInit() {
    this.id = this.data.id;
    this.view = this.data.view;
    this.buildItemForm({});
    this.show(this.id);
    this.getOptions();
  }

  buildItemForm(item) {
    this.itemForm = this.fb.group({
      item_name: [item.item_name || '', [Validators.required, Validators.maxLength(255)]],
      item_description: [item.item_description || ''],
      item_unit_price: [item.item_unit_price || 0, Validators.required],
      item_quantity: [item.item_quantity || 0, Validators.required],
      item_status: [item.item_status || '', Validators.required]
    });
    this.itemForm.get('item_unit_price').valueChanges.subscribe((item_unit_price) => {
      let quantity = this.itemForm.value.item_quantity || 0;
      let price = item_unit_price || 0;
      this.lineTotal = quantity * price;
    });
    this.itemForm.get('item_quantity').valueChanges.subscribe((item_quantity) => {
      let q = item_quantity || 0;
      let price = this.itemForm.value.item_unit_price || 0;
      this.lineTotal = q * price;
    });
    this.lineTotal = this.itemForm.value.item_quantity * this.itemForm.value.item_unit_price;
  }

  getOptions() {
    this.maoService.all()
      .subscribe((data: ModelAttributeOptions) => {
        this.options = data.invoice_item;
      });
  }

  show(id) {
    let loader = this.loader.open();
    this._service.show(id)
      .finally(() => {
        loader.close();
      })
      .subscribe((data: any) => {
        this.item = data;
        this.buildItemForm(this.item);
      });
  }

  submit() {
    let data = { ...this.itemForm.value };
    let loader = this.loader.open();
    this._service.update(this.id, data)
      .finally(() => {
        loader.close();
      })
      .subscribe(() => {
        this.snack.open('Invoice item updated!', 'OK', { duration: 4000 });
        this.onUpdated.emit();
        this.dialogRef.close(true);
      });
  }
}
