import { Injectable } from '@angular/core';
import { ApiBaseService } from '@shared/services/api/api-base.service';
import { AssetModel } from '@shared/models/asset.model';
import { Observable } from 'rxjs';
import { ModelAttributeOptions } from '@shared/models/options';
import { HttpClient } from '@angular/common/http';
import { ModelAttributeOptionService } from '@shared/services/api/model-attribute-option.service';
import { Conditions } from '@shared/models/conditions.model';

@Injectable()
export class AssetService extends ApiBaseService {
  route_prefix = 'assets';

  public conditions: Conditions = {
    category: {
      value: null,
      nullable: true,
      items: [],
      display_key: 'option_value',
      select_key: 'option_value',
      display_name: 'Category'
    },
  };

  constructor(
    public http: HttpClient,
    private mao: ModelAttributeOptionService,
  ) {
    super(http);
    this.getOptions();
  }

  getOptions() {
    this.mao.all()
      .subscribe((data: ModelAttributeOptions) => {
        this.conditions.category.items = data.asset.category;
      });
  }

  public byBuildingId(buildingId): Observable<[AssetModel] | Object> {
    return this.http.get(`${this.route_prefix}/by-building-id/${buildingId}`);
  }
}
