import { Injectable } from '@angular/core';
import { CacheService } from '../cache.service';
import { NotifyService } from '../notify.service';
import { AppLoaderService } from '../system/app-loader/app-loader.service';
import { AppConfirmService } from '../system/app-confirm/app-confirm.service';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class SettingService {

  constructor(
    private http: HttpClient,
    private cache: CacheService,
    private toast: NotifyService,
    private loader: AppLoaderService,
    private confirm: AppConfirmService,
  ) {
  }

  refreshCache() {
    this.confirm.confirm({ message: 'Cleaning cache and login out, please confirm.' }).subscribe((res) => {
      if (!res) return;
      let loader = this.loader.open();
      this.http.get(`settings/refresh-cache`)
        .finally(() => loader.close())
        .subscribe(() => {
          this.cache.removeAll();
          this.toast.show('Refresh cache success, will automatically refresh the page');
          setTimeout(() => {
            location.reload(true);
          }, 2000);
        });
    });
  }
}
