import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar, MatHorizontalStepper } from '@angular/material';
import { AppLoaderService } from '@shared/services/system/app-loader/app-loader.service';
import { Router } from '@angular/router';
import { InvoiceService } from '@shared/services/api/invoice.service';
import { ModelAttributeOptionService } from '@shared/services/api/model-attribute-option.service';
import { InvoiceOptions } from '@shared/models/options';
import { CompanyService } from '@shared/services/api/company.service';
import { InvoiceItemOptions } from '@shared/models/options';
import { DisplayColumnsService } from '@shared/services/display-columns.service';
import { AppTableComponent } from '@app/shared/components/system/table/table.component';
import { PopupService } from '@app/shared/services/popup.service';
import { SimpleReuseStrategy } from '@app/simple-reuse-strategy';

@Component({
  selector: 'app-invoice-create',
  templateUrl: './invoice-create.component.html',
  providers: [SimpleReuseStrategy]
})
export class InvoiceCreateComponent implements OnInit {
  quotationDisplay = [
    { display: 'Id', key: 'id', useColor: false, clickable: true, clickevent: new EventEmitter() },
    { display: 'Client', key: 'target_company_name', useColor: false },
    { display: 'Total', key: 'total', useColor: false },
    { display: 'Type', key: 'type', useColor: false },
    { display: 'Contractor', key: 'source_company_name', useColor: false },
    { display: 'Date', key: 'created_at', useColor: false },
    { display: 'Status', key: 'status', useColor: true }
  ];
  purchaseOrderDisplay = [
    { display: 'Id', key: 'id', useColor: false, clickable: true, clickevent: new EventEmitter() },
    { display: 'Total', key: 'total', useColor: false },
    { display: 'Supplier', key: 'source_company_name', useColor: false },
    { display: 'Client', key: 'target_company_name', useColor: false },
    { display: 'Status', key: 'status', useColor: true },
  ];
  invoiceItemDisplayColumns = [
    { display: 'Type', key: 'item_type', useColor: false },
    { display: 'Total Price', key: 'item_total_price', useColor: false },
    { display: 'Name', key: 'item_name', useColor: false },
    { display: 'Unit Price', key: 'item_unit_price', useColor: false },
    { display: 'Quantity', key: 'item_quantity', useColor: false },
  ];
  @ViewChild(AppTableComponent) tableComponent: AppTableComponent;
  @ViewChild(MatHorizontalStepper) stepper: MatHorizontalStepper;
  @Output() onSubmitted: EventEmitter<any> = new EventEmitter();
  public loading$ = false;
  public basicFormGroup: FormGroup;
  public itemFormGroup: FormGroup;
  public total = 0;
  public lineTotal = 0;
  public subtotal = 0;

  public type; // quotation,purchase_order,other
  public tables;
  public selectItem = null; // type_id
  public items = [];
  public invoiceOptions: InvoiceOptions;
  public invoiceItemOptions: InvoiceItemOptions;
  public get clients() {
    return this.companyService.clients;
  };

  constructor(
    private fb: FormBuilder,
    private _service: InvoiceService,
    private router: Router,
    private snack: MatSnackBar,
    private loader: AppLoaderService,
    private maoService: ModelAttributeOptionService,
    private companyService: CompanyService,
    public displayColumnsService: DisplayColumnsService,
    public popup: PopupService
  ) {
  }

  ngOnInit() {
    this.buildItemForm();
    this.getOptions();
    this.quotationDisplay[0].clickevent.subscribe((row) => {
      this.selectQuotationOrPurchaseOrder(row);
    });
    this.purchaseOrderDisplay[0].clickevent.subscribe((row) => {
      this.selectQuotationOrPurchaseOrder(row);
    });
  }

  buildItemForm() {
    this.buildBasicFormGroupGroup();
    this.buildItemFormGroup();
  }

  buildBasicFormGroupGroup(item: any = {}) {
    this.basicFormGroup = this.fb.group({
      subject: [item.subject || '', Validators.required],  // string
      description: [item.description || '', Validators.required],    // string
      vat: [item.vat || 0, [Validators.required, Validators.min(0)]],  // number
      target_company_id: [item.target_company_id || '', Validators.required],
    });
    this.basicFormGroup.get('vat').valueChanges.subscribe((vat) => {
      this.computeTotal(vat);
    });
  }

  buildItemFormGroup() {
    this.itemFormGroup = this.fb.group({
      item_type: ['', Validators.required],
      item_name: ['', [Validators.required, Validators.maxLength(255)]],
      item_description: [''],
      item_unit_price: [0, Validators.compose([Validators.required, Validators.min(0)])],
      item_quantity: [0, Validators.compose([Validators.required, Validators.min(0)])],
    });

    this.itemFormGroup.get('item_unit_price').valueChanges.subscribe((item_unit_price) => {
      let quantity = this.itemFormGroup.value.item_quantity || 0;
      let price = item_unit_price || 0;
      this.lineTotal = quantity * price;
    });
    this.itemFormGroup.get('item_quantity').valueChanges.subscribe((item_quantity) => {
      let q = item_quantity || 0;
      let price = this.itemFormGroup.value.item_unit_price || 0;
      this.lineTotal = q * price;
    });


  }

  getOptions() {
    this.maoService.all()
      .subscribe((data: any) => {
        this.invoiceOptions = data.invoice;
        this.invoiceItemOptions = data.invoice_item;
      });
  }

  submit() {
    let data = {
      ...this.basicFormGroup.value,
      items: this.items,
      type: this.type,
      type_id: this.selectItem ? this.selectItem.id : 0
    };
    let loader = this.loader.open();
    this._service.store(data)
      .finally(() => {
        loader.close();
      })
      .subscribe(() => {
        SimpleReuseStrategy.deleteRouteSnapshot('//invoice/create');
        this.onSubmitted.emit();
        this.snack.open('Invoice Added!', 'OK', { duration: 4000 });
      });
  }

  addItem() {
    let now = this.items;
    now.push({ ...this.itemFormGroup.value, item_total_price: this.itemFormGroup.value.item_unit_price * this.itemFormGroup.value.item_quantity });
    this.items = [...this.items];
    this.computeTotal();
  }

  initItems(data = []) {
    this.items = data;
    this.computeTotal();
  }

  removeItem(row) {
    let index = this.items.indexOf(row);
    this.items.splice(index, 1);
    this.items = [...this.items];
    this.computeTotal();
  }

  computeTotal(vat = 0) {
    let subtotal = 0;
    for (let item of this.items) {
      subtotal += item.item_unit_price * item.item_quantity;
    }
    if (!vat) {
      vat = this.basicFormGroup.value.vat || 0;
    }
    this.total = subtotal + vat;
  }

  getApiItems(id, type) {
    if (type === 'quotation') {
      this._service.getQuotationItems(id).subscribe((data: any) => {
        this.initItems(data);
      });
    } else if (type === 'purchase_order') {
      this._service.getPurchaseOrderItems(id).subscribe((data: any) => {
        this.initItems(data);
      });
    } else {
      this.initItems([]);
    }
  }

  getTable() {
    let loader = this.loader.open();
    if (this.type === 'quotation') {
      this._service.getQuotations({ page: this.tableComponent.paginator.pageNumber + 1, pre_page: 10, keyword: '' })
        .finally(() => {
          loader.close();
        })
        .subscribe((response: any) => {
          this.tableComponent.setPaginator(response.total, response.per_page, response.current_page - 1);
          this.tables = response.data;
        });
    } else if (this.type === 'purchase_order') {
      this._service.getPurchaseOrders({ page: this.tableComponent.paginator.pageNumber + 1, pre_page: 10, keyword: '' })
        .finally(() => {
          loader.close();
        })
        .subscribe((response: any) => {
          this.tableComponent.setPaginator(response.total, response.per_page, response.current_page - 1);
          this.tables = response.data;
        });
    } else {
      this.tables = [];
      loader.close();
    }
  }

  gotoTable() {
    this.router.navigate(['/invoice']);
  }

  selectStepType(stepper, type) {
    this.type = type;
    this.items = [];
    this.buildBasicFormGroupGroup();
    stepper.next();
    this.getTable();
  }

  clickTableEdit($event) {
    if (this.type === 'quotation') {
      this.popup.openQuotationEditPage($event.id)
        .afterClosed().subscribe((res) => {
          if (res) {
            this.getTable();
          }
        });
    } else if (this.type === 'purchase_order') {
      this.popup.openPurchaseOrderEditPage($event.id)
        .afterClosed().subscribe((res) => {
          if (res) {
            this.getTable();
          }
        });
    } else {

    }
  }

  selectQuotationOrPurchaseOrder(row) {
    this.selectItem = row;
    this.getApiItems(row.id, this.type);
    this.buildBasicFormGroupGroup({
      subject: row.subject,  // string
      description: row.description,    // string
      vat: row.vat,  // number
      target_company_id: row.target_company_id,
    });
    this.stepper.next();
  }

  changePage($event) {
    this.tableComponent.setPaginator($event.count, $event.pageSize, $event.offset);
    this.getTable();
  }
}
