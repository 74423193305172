import { Component, OnInit, Inject, Output, EventEmitter } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatSnackBar } from '@angular/material';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { AppLoaderService } from '@shared/services/system/app-loader/app-loader.service';
import { ModelAttributeOptions, PurchaseOrderItemOptions } from '@shared/models/options';
import { PurchaseOrderItemService } from '@shared/services/api/purchase-order-item.service';
import { ModelAttributeOptionService } from '@shared/services/api/model-attribute-option.service';

@Component({
  selector: 'app-purchase-order-item-edit-popup',
  templateUrl: './purchase-order-item-edit.component.html'
})
export class PurchaseOrderItemEditPopupComponent implements OnInit {
  public id;
  public itemFormGroup: FormGroup = new FormGroup({
    item_status: new FormControl('', Validators.required),
    item_type: new FormControl('', Validators.required),
    item_name: new FormControl('', [Validators.required, Validators.maxLength(255)]),
    item_description: new FormControl(''),
    item_unit_margin: new FormControl(0, Validators.compose([Validators.required, Validators.min(0)])),
    item_unit_price: new FormControl(0, Validators.compose([Validators.required])),
    item_quantity: new FormControl(0, Validators.compose([Validators.required, Validators.min(0)])),
  });
  public item;
  public options: PurchaseOrderItemOptions;
  public lineTotal = 0;
  @Output() onUpdated = new EventEmitter();

  constructor(
    private _service: PurchaseOrderItemService,
    private loader: AppLoaderService,
    private snack: MatSnackBar,
    public dialogRef: MatDialogRef<PurchaseOrderItemEditPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public maoService: ModelAttributeOptionService
  ) {
  }

  ngOnInit() {
    this.id = this.data.id;
    this.buildItemForm()
    this.show(this.id);
    this.getOptions();
  }

  getOptions() {
    this.maoService.all()
      .subscribe((data: ModelAttributeOptions) => {
        this.options = data.purchase_order_item;
      });
  }

  buildItemForm() {
    this.itemFormGroup.valueChanges.subscribe((change) => {
      let item_quantity = change.item_quantity || 0;
      let item_unit_price = change.item_unit_price || 0;
      let item_unit_margin = change.item_unit_margin || 0;
      this.lineTotal = item_quantity * item_unit_price * (1 + item_unit_margin / 100);
    });
  }

  fillItemForm(item) {
    this.itemFormGroup.patchValue({ ...item });
  }

  show(id) {
    let loader = this.loader.open();
    this._service.show(id)
      .finally(() => {
        loader.close();
      })
      .subscribe((data: any) => {
        this.item = data;
        this.fillItemForm(this.item);
      });
  }

  submit() {
    let data = { ...this.itemFormGroup.value };
    let loader = this.loader.open();
    this._service.update(this.id, data)
      .finally(() => {
        loader.close();
      })
      .subscribe(() => {
        this.onUpdated.emit();
        this.snack.open('Purchase order item updated!', 'OK', { duration: 4000 });
        this.dialogRef.close(true);
      });
  }
}
