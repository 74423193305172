import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { LaravelPageRequest } from '@shared/models/laravel-page-request.model';
import { Conditions } from '@shared/models/conditions.model';
import { UserModel } from '@shared/models/user.model';
import { ReplaySubject } from 'rxjs';
import { GlobalSettingService } from '@shared/services/system/global-setting.service';
import { ModelAttributeOptions } from '@shared/models/options';
import { ModelAttributeOptionService } from '@shared/services/api/model-attribute-option.service';

@Injectable()
export class UserService {
  route_prefix = 'users';
  public loadingEngineers = false;
  public engineers: UserModel[] = [];
  public engineers$ = new ReplaySubject(1);
  public conditions: Conditions = {
    status: {
      value: null,
      nullable: true,
      items: [],
      display_key: 'option_value',
      select_key: 'option_value',
      display_name: 'Status'
    },
  };

  constructor(
    private http: HttpClient,
    private globalSettingService: GlobalSettingService,
    private mao: ModelAttributeOptionService,
  ) {
    this.globalSettingService.useCompanyId$.subscribe((id) => {
      if (id) {
        this.getEngineers();
      }
    });
    this.getOptions();
  }

  getOptions() {
    this.mao.all()
      .subscribe((data: ModelAttributeOptions) => {
        this.conditions.status.items = data.user.status;
      });
  }

  getEngineers() {
    this.loadingEngineers = true;
    this.http.get('users/engineers')
      .finally(() => this.loadingEngineers = false)
      .subscribe((engineers: UserModel[]) => {
        this.engineers = engineers;
        this.engineers$.next(engineers);
      });
  }

  accountantsByServiceId(serviceId = '') {
    return this.http.get(this.route_prefix + '/accountants-by-service-id/' + serviceId);
  }

  index(params: LaravelPageRequest) {
    return this.http.get(this.route_prefix, {params: params.request()});
  }

  store(data) {
    return this.http.post(this.route_prefix, data);
  }

  show(id) {
    return this.http.get(this.route_prefix + '/' + id);
  }

  destroy(id) {
    return this.http.delete(this.route_prefix + '/' + id);
  }

  update(id, data) {
    return this.http.put(this.route_prefix + '/' + id, data);
  }

  getUserHolidays(id, params: LaravelPageRequest) {
    return this.http.get(this.route_prefix + '/' + id + '/user-holidays', {params: params.request()});
  }

  approvalUserHoliday(id, params) {
    return this.http.post(this.route_prefix + '/user-holidays' + '/' + id, params);
  }

  sendEmail(params) {
    return this.http.post('auth/password/send-email', params);
  }

  resetPassword(params) {
    return this.http.post('auth/password/reset', params);
  }
}
