import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { MatSnackBar } from '@angular/material';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AppLoaderService } from '@shared/services/system/app-loader/app-loader.service';
import { NoteService } from '@shared/services/api/note.service';
import { ModelAttributeOptionService } from '@app/shared/services/api/model-attribute-option.service';
import { NoteOptions, ModelAttributeOptions } from '@app/shared/models/options';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-note-create',
  templateUrl: './note-create.component.html'
})
export class NoteCreateComponent implements OnInit {
  @Input() id;  // type_id
  @Input() type;
  @Input() isPopup = false;
  @Output() onCreated = new EventEmitter();

  public noteOptions: NoteOptions;

  public itemForm: FormGroup;
  public users = [];

  constructor(
    private fb: FormBuilder,
    private _service: NoteService,
    private loader: AppLoaderService,
    private snack: MatSnackBar,
    private _http: HttpClient,
    private mao: ModelAttributeOptionService
  ) {
  }

  ngOnInit() {
    this.buildItemForm();
    this.mao.all()
      .subscribe((data: ModelAttributeOptions) => {
        this.noteOptions = data.note;
      });
    this.initUsers();
  }

  buildItemForm() {
    this.itemForm = this.fb.group({
      note: ['', Validators.required],
      severity: [''],
      target_user: [[]]
    });
  }

  submit() {
    let data = { ...this.itemForm.value, type: this.type, type_id: this.id };
    let loader = this.loader.open();
    this._service.store(data)
      .finally(() => {
        loader.close();
      })
      .subscribe(() => {
        this.snack.open('Note created!', 'OK', { duration: 4000 });
        this.onCreated.emit(true);
      });
  }

  severityStyle(value) {
    return this._service.severityStyle(value);
  }

  initUsers() {
    let loader = this.loader.open();
    this._http.get('notes/read-users')
      .finally(() => {
        loader.close();
      })
      .subscribe((data: any) => {
        this.users = data.list;
      });
  }
}
