import { Component, OnInit, Inject, Output, EventEmitter } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { AppLoaderService } from '@shared/services/system/app-loader/app-loader.service';
import { ModelAttributeOptions, QuotationItemOptions } from '@shared/models/options';
import { ModelAttributeOptionService } from '@shared/services/api/model-attribute-option.service';
import { QuotationItemService } from '@shared/services/api/quotation-item.service';
import { NotifyService } from '@app/shared/services/notify.service';
import {debounceTime, distinctUntilChanged, filter, map, switchMap} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';

@Component({
  selector: 'app-quotation-item-edit-popup',
  templateUrl: './quotation-item-edit.component.html'
})
export class QuotationItemEditPopupComponent implements OnInit {
  public id;
  public view = false;
  public itemFormGroup: FormGroup = new FormGroup({
    item_type: new FormControl('', Validators.required),
    item_name: new FormControl('', [Validators.required, Validators.maxLength(255)]),
    item_description: new FormControl(''),
    item_unit_margin: new FormControl(0, Validators.compose([Validators.required, Validators.min(0)])),
    item_unit_price: new FormControl(0, Validators.compose([Validators.required, Validators.min(0)])),
    item_unit_duration: new FormControl(1, Validators.compose([Validators.required, Validators.min(0)])),
    item_quantity: new FormControl(0, Validators.compose([Validators.required, Validators.min(0)])),
    item_status: new FormControl('', Validators.required),
  });
  public defaultMargin = 0;
  public useMargin = 0;

  public materials = [];
  public lineTotal = 0;
  public item;
  public options: QuotationItemOptions
  @Output() onUpdated = new EventEmitter();

  constructor(
    private _service: QuotationItemService,
    private loader: AppLoaderService,
    private http: HttpClient,
    public dialogRef: MatDialogRef<QuotationItemEditPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { id: string; view: boolean },
    public maoService: ModelAttributeOptionService,
    private toast: NotifyService,
  ) {
  }

  ngOnInit() {
    this.id = this.data.id;
    this.view = this.data.view;
    this.buildItemForm();
    this.show(this.id);
    this.getOptions();
  }

  getOptions() {
    this.maoService.all()
      .subscribe((data: ModelAttributeOptions) => {
        this.options = data.quotation_item;
      });
  }

  buildItemForm() {
    this.itemFormGroup.valueChanges.subscribe((change) => {
      let item_quantity = change.item_quantity || 0;
      let item_unit_price = change.item_unit_price || 0;
      let item_unit_margin = change.item_unit_margin || 0;
      let item_unit_duration = change.item_unit_duration || 1;
      this.lineTotal = item_quantity * item_unit_price * item_unit_duration * (1 + item_unit_margin / 100);
    });
    this.itemFormGroup.controls['item_name'].valueChanges
      .pipe(
        filter(text => {
          return this.itemFormGroup.controls['item_type'].value === 'material' && text.length > 2;
        }),
        debounceTime(10),
        distinctUntilChanged(),
        switchMap((res) => this.http.get('quotation-items/materials', {params: {keyword: res}}))
      )
      .subscribe((res: any) => {
        this.materials = res;
      });
  }

  fillItemForm(item) {
    this.itemFormGroup.patchValue({ ...item });
  }

  show(id) {
    let loader = this.loader.open();
    this._service.show(id)
      .finally(() => {
        loader.close();
      })
      .subscribe((data: any) => {
        this.item = data;
        this.fillItemForm(this.item);
      });
  }

  submit() {
    let item = this.itemFormGroup.value;
    if (item.item_type === 'material' && item.item_name.item_name) {
      item.item_name = item.item_name.item_name;
    }
    let data = { ...item };
    let loader = this.loader.open();
    this._service.update(this.id, data)
      .finally(() => {
        loader.close();
        this.show(this.id);
      })
      .subscribe(() => {
        this.onUpdated.emit();
        this.toast.show('Quotation item updated!');
        this.dialogRef.close(true);
      });
  }

  materialAutoComplete($event) {
    const item = $event.option.value;
    this.itemFormGroup.patchValue({
      product_id: item.id,
      item_unit_price: item.sell_price,
      // item_description: item.description
    });
  }

  materialName(item) {
    if (item) {
      if (item.code) {
        return item.code + '-' + item.name;
      } else {
        return item.name;
      }
    } else {
      return '';
    }
  }
}
