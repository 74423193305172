import { ConfirmWithNoteComponent } from './services/system/confirm-with-note/confirm-with-note.component';
import { HtmlPipe } from './pipes/html.pipe';
import { UpliftComponent } from './components/business/uplift/uplift.component';
import { TableFilterComponent } from './components/system/table-filter/table-filter.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { FlexLayoutModule } from '@angular/flex-layout';
import { TranslateModule } from '@ngx-translate/core';
import {
  MatAutocompleteModule,
  MatButtonModule,
  MatCardModule,
  MatCheckboxModule,
  MatChipsModule,
  MatDialogModule,
  MatExpansionModule,
  MatGridListModule,
  MatIconModule,
  MatInputModule,
  MatListModule,
  MatMenuModule,
  MatNativeDateModule,
  MatOptionModule,
  MatProgressBarModule,
  MatProgressSpinnerModule,
  MatRadioModule,
  MatRippleModule,
  MatSelectModule,
  MatSidenavModule,
  MatSlideToggleModule,
  MatSnackBarModule,
  MatStepperModule,
  MatTabsModule,
  MatToolbarModule,
  MatTooltipModule
} from '@angular/material';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
  MatDateFormats,
  MatDatepickerModule,
  MatMomentDateModule,
  Moment,
  MomentDateAdapter
} from '@coachcare/datepicker';
// ONLY REQUIRED FOR **SIDE** NAVIGATION LAYOUT
import { HeaderSideComponent } from './components/system/header-side/header-side.component';
import { SidebarSideComponent } from './components/system/sidebar-side/sidebar-side.component';
// ONLY REQUIRED FOR **TOP** NAVIGATION LAYOUT
import { HeaderTopComponent } from './components/system/header-top/header-top.component';
import { SidebarTopComponent } from './components/system/sidebar-top/sidebar-top.component';
// ONLY FOR DEMO (Removable without changing any layout configuration)
import { CustomizerComponent } from '@shared/components/system/customizer/customizer.component';
// ALL TIME REQUIRED
import { AdminLayoutComponent } from '@shared/components/system/layouts/admin-layout/admin-layout.component';
import { AuthLayoutComponent } from '@shared/components/system/layouts/auth-layout/auth-layout.component';
import { NotificationsComponent } from '@shared/components/business/notifications/notifications.component';
import { SidenavComponent } from '@shared/components/system/sidenav/sidenav.component';
import { BreadcrumbComponent } from '@shared/components/system/breadcrumb/breadcrumb.component';
import { AppComfirmComponent } from '@shared/services/system/app-confirm/app-confirm.component';
import { AppLoaderComponent } from '@shared/services/system/app-loader/app-loader.component';
// DIRECTIVES
import { FontSizeDirective } from '@shared/directives/font-size.directive';
import { ScrollToDirective } from '@shared/directives/scroll-to.directive';
import { AppDropdownDirective } from '@shared/directives/dropdown.directive';
import { DropdownAnchorDirective } from '@shared/directives/dropdown-anchor.directive';
import { DropdownLinkDirective } from '@shared/directives/dropdown-link.directive';
// PIPES
import { RelativeTimePipe } from '@shared/pipes/relative-time.pipe';
import { ExcerptPipe } from '@shared/pipes/excerpt.pipe';
import { DisplayUserTypePipe } from '@shared/pipes/display-user-type.pipe';
// SERVICES
import { GlobalSettingComponent } from '@shared/components/business/global-setting/global-setting.component';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { Md2DatepickerModule, NoConflictStyleCompatibilityMode } from 'md2';
import { FileUploadModule } from 'ng2-file-upload';
import { PageCardHeaderComponent } from '@shared/components/base/page-card-header/page-card-header.component';
import { ServiceCertificateVerifyPopupComponent } from '@shared/components/base/service-certificate-verify/service-certificate-verify-popup.component';
import { ServiceCertificateVerifyComponent } from '@shared/components/base/service-certificate-verify/service-certificate-verify.component';
import { BaseCreateComponent } from '@shared/components/base/base-create.component';
import { BaseTableComponent } from '@shared/components/base/base-table.component';
import { AppTableConditionComponent } from '@shared/components/system/table/condition/condition.component';
import { AppTableComponent } from '@shared/components/system/table/table.component';
import { ServiceTablePopupComponent } from '@shared/components/business/service/service-table/service-table-popup.component';
import { ServiceTableComponent } from '@shared/components/business/service/service-table/service-table.component';
import { SelectSearchComponent } from '@shared/components/system/select-search/select-search.component';
import { SelectSearchClearDirective } from '@shared/components/system/select-search/select-search-clear.directive';
import { SimpleSelectSearchComponent } from '@shared/components/system/select-search/simple-select-search/simple-select-search.component';
import { ServiceEditComponent } from '@shared/components/business/service/service-edit/service-edit.component';
import { ServiceCreateComponent } from '@shared/components/business/service/service-create/service-create.component';
import { AppServiceCertificateComponent } from '@shared/components/base/app-service-certificate/app-service-certificate.component';
import { ServiceDashboardComponent } from '@shared/components/business/service/service-dashboard/service-dashboard.component';
import { QuotationItemCreatePopupComponent } from '@shared/components/business/quotation/quotation-item-create/quotation-item-create-popup.component';
import { QuotationEditComponent } from '@shared/components/business/quotation/quotation-edit/quotation-edit.component';
import { QuotationCreateComponent } from '@shared/components/business/quotation/quotation-create/quotation-create.component';
import { PurchaseOrderCreateComponent } from '@shared/components/business/purchase-order/purchase-order-create/purchase-order-create.component';
import { PurchaseOrderEditComponent } from '@shared/components/business/purchase-order/purchase-order-edit/purchase-order-edit.component';
import { PurchaseOrderItemCreatePopupComponent } from '@shared/components/business/purchase-order/purchase-order-item-create/purchase-order-item-create-popup.component';
import { CompanyCreateComponent } from '@shared/components/business/company/company-create/company-create.component';
import { CompanyEditComponent } from '@shared/components/business/company/company-edit/company-edit.component';
import { ServiceEditPopupComponent } from '@shared/components/business/service/service-edit/service-edit-popup.component';
import { InvoiceCreateComponent } from '@shared/components/business/invoice/invoice-create/invoice-create.component';
import { InvoiceEditComponent } from '@shared/components/business/invoice/invoice-edit/invoice-edit.component';
import { BuildingEditComponent } from '@shared/components/business/building/building-edit/building-edit.component';
import { BuildingCreateComponent } from '@shared/components/business/building/building-create/building-create.component';
import { InvoiceItemCreatePopupComponent } from '@shared/components/business/invoice/invoice-item-create/invoice-item-create-popup.component';
import { MatProgressButtonsModule } from 'mat-progress-buttons';
import { ServiceVisitEditPopupComponent } from '@shared/components/business/service-visit/service-visit-edit/service-visit-edit-popup.component';
import { ServiceVisitTableComponent } from '@shared/components/business/service-visit/service-visit-table/service-visit-table.component';
import { NoteTableComponent } from '@shared/components/business/note/note-table/note-table.component';
import { NoteEditPopupComponent } from '@shared/components/business/note/note-edit/note-edit-popup.component';
import { DocumentCreatePopupComponent } from '@shared/components/business/document/document-create/document-create-popup.component';
import { NoteCreatePopupComponent } from '@shared/components/business/note/note-create/note-create-popup.component';
import { DocumentCreateComponent } from '@shared/components/business/document/document-create/document-create.component';
import { DocumentEditPopupComponent } from '@shared/components/business/document/document-edit/document-edit-popup.component';
import { DocumentTableComponent } from '@shared/components/business/document/document-table/document-table.component';
import { NoteCreateComponent } from '@shared/components/business/note/note-create/note-create.component';
import { InvoiceItemEditPopupComponent } from '@shared/components/business/invoice/invoice-item-edit/invoice-item-edit-popup.component';
import { QuotationItemEditPopupComponent } from '@shared/components/business/quotation/quotation-item-edit/quotation-item-edit-popup.component';
import { PurchaseOrderItemEditPopupComponent } from '@shared/components/business/purchase-order/purchase-order-item-edit/purchase-order-item-edit-popup.component';
import { AssetCreateComponent } from '@shared/components/business/asset/asset-create/asset-create.component';
import { AssetEditComponent } from '@shared/components/business/asset/asset-edit/asset-edit.component';
import { AssetTableComponent } from '@shared/components/business/asset/asset-table/asset-table.component';
import { AssetEditPopupComponent } from '@shared/components/business/asset/asset-edit/asset-edit-popup.component';
import { AppSelectCompanyComponent } from '@app/shared/services/system/app-select-company/app-select-company.component';
import { QuotationTablePopupComponent } from '@shared/components/business/quotation/quotation-table/quotation-table-popup.component';
import { QuotationTableComponent } from '@shared/components/business/quotation/quotation-table/quotation-table.component';
import { QuotationDashboardComponent } from '@app/views/quotation/quotation-dashboard/quotation-dashboard.component';
import { ChangeTimesheetTimeComponent } from '@app/views/timesheet/change-timesheet-time/change-timesheet-time.component';
import { ProgressComponent } from '@shared/components/system/progress/progress.component';
import { AssetCreatePopupComponent } from '@shared/components/business/asset/asset-create/asset-create-popup.component';
import { StrCheckboxComponent } from '@shared/components/system/str-checkbox/str-checkbox.component';
import { ServiceVisitCreatePopupComponent } from '@shared/components/business/service-visit/service-visit-create/service-visit-create-popup.component';
import { ServiceVisitCreateComponent } from '@shared/components/business/service-visit/service-visit-create/service-visit-create.component';
import { BuildingEditPopupComponent } from '@shared/components/business/building/building-edit/building-edit-popup.component';
import { PurchaseOrderEditPopupComponent } from '@shared/components/business/purchase-order/purchase-order-edit/purchase-order-edit-popup.component';
import { QuotationEditPopupComponent } from '@shared/components/business/quotation/quotation-edit/quotation-edit-popup.component';
import { QuotationCreatePopupComponent } from '@shared/components/business/quotation/quotation-create/quotation-create-popup.component';
import { PurchaseOrderCreatePopupComponent } from '@shared/components/business/purchase-order/purchase-order-create/purchase-order-create-popup.component';
import { SearchSelectComponent } from './components/system/search-select/search-select.component';
import { InvoiceEditPopup } from './components/business/invoice/invoice-edit/invoice-edit-popup.component';
import { QuotationClientReviewCreate } from './components/business/quotation/quotation-client-review-create.component';
import { PpmListComponent } from './components/business/ppm/ppm-list/ppm-list.component';
import { PpmDashboardComponent } from './components/business/ppm/ppm-dashboard/ppm-dashboard.component';
import { PpmCreateComponent } from './components/business/ppm/ppm-create/ppm-create.component';
import { ServiceCustomizeDataCreateComponent } from './components/common/service-customize-data-create/service-customize-data-create.component';
import { ServiceCustomizeDataEditComponent } from './components/common/service-customize-data-edit/service-customize-data-edit.component';
import { ServiceExtraCreateComponent } from './components/common/service-extra-create/service-extra-create.component';
import { ServiceExtraEditComponent } from './components/common/service-extra-edit/service-extra-edit.component';
import { BuildingDetailCardComponent } from './components/common/building-detail-card/building-detail-card.component';
import { BuildingNotesCardComponent } from './components/common/building-notes-card/building-notes-card.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { ColorShipComponent } from './components/system/color-ship/color-ship.component';
import { SecondsPipe } from './pipes/seconds.pipe';
import { GalleryModule } from '@ks89/angular-modal-gallery';
import { GalleryDialogComponent } from './components/business/gallery-dialog/gallery-dialog.component';
import * as moment from 'moment';
import { UploaderService } from '@shared/services/uploader.service';
import { UserEditComponent } from '@shared/components/business/user/user-edit/user-edit.component';
import { UserCreateComponent } from '@shared/components/business/user/user-create/user-create.component';
import { HolidayApprovalComponent } from '@shared/services/system/holiday-approval/holiday-approval.component';
import { FormErrorPipe } from '@shared/pipes/form-error.pipe';
import { BREAKPOINT } from '@angular/flex-layout';
import { ServiceCreatePopupComponent } from './components/business/service/service-create/service-create-popup.component';
import { RejectServiceComponent } from './services/system/reject-service/reject-service.component';
import { ApproveServiceComponent } from './services/system/approve-service/approve-service.component';

// import {Logger} from './services/logger.service';
/*
  Only Required if you want to use Angular Landing
  (https://themeforest.net/item/angular-landing-material-design-angular-app-landing-page/21198258)
*/
// import { LandingPageService } from '../shared/services/landing-page.service';

const classesToInclude = [
  HeaderTopComponent,
  SidebarTopComponent,
  SidenavComponent,
  NotificationsComponent,
  SidebarSideComponent,
  HeaderSideComponent,
  AdminLayoutComponent,
  AuthLayoutComponent,
  BreadcrumbComponent,
  CustomizerComponent,
  FontSizeDirective,
  ScrollToDirective,
  AppDropdownDirective,
  DropdownAnchorDirective,
  DropdownLinkDirective,
  RelativeTimePipe,
  ExcerptPipe,
  HtmlPipe,
  FormErrorPipe,
  DisplayUserTypePipe,
  GlobalSettingComponent,
  PageCardHeaderComponent,
  ServiceCertificateVerifyComponent,
  BaseCreateComponent,
  BaseTableComponent,
  AppTableConditionComponent,
  AppTableComponent,
  ServiceTableComponent,
  SelectSearchComponent,
  SelectSearchClearDirective,
  SimpleSelectSearchComponent,
  ServiceEditComponent,
  ServiceCreateComponent,
  AppServiceCertificateComponent,
  ServiceDashboardComponent,
  QuotationEditComponent,
  QuotationCreateComponent,
  PurchaseOrderCreateComponent,
  PurchaseOrderEditComponent,
  CompanyCreateComponent,
  CompanyEditComponent,
  InvoiceCreateComponent,
  InvoiceEditComponent,
  BuildingEditComponent,
  BuildingCreateComponent,
  ServiceVisitTableComponent,
  NoteTableComponent,
  NoteCreateComponent,
  DocumentTableComponent,
  DocumentCreateComponent,
  AssetCreateComponent,
  AssetEditComponent,
  AssetTableComponent,
  QuotationTableComponent,
  QuotationDashboardComponent,
  ProgressComponent,
  StrCheckboxComponent,
  ServiceVisitCreateComponent,
  TableFilterComponent,
  UpliftComponent,
  SearchSelectComponent,
  PpmListComponent,
  PpmDashboardComponent,
  ColorShipComponent,
  UserEditComponent,
  UserCreateComponent,
];

const popups = [
  AppComfirmComponent,
  AppLoaderComponent,
  QuotationItemCreatePopupComponent,
  ServiceEditPopupComponent,
  ServiceCreatePopupComponent,
  PurchaseOrderItemCreatePopupComponent,
  ServiceCertificateVerifyPopupComponent,
  ServiceTablePopupComponent,
  InvoiceItemCreatePopupComponent,
  ServiceVisitEditPopupComponent,
  NoteEditPopupComponent,
  NoteCreatePopupComponent,
  DocumentCreatePopupComponent,
  DocumentEditPopupComponent,
  InvoiceItemEditPopupComponent,
  PurchaseOrderItemEditPopupComponent,
  QuotationItemEditPopupComponent,
  AssetEditPopupComponent,
  AppSelectCompanyComponent,
  QuotationTablePopupComponent,
  ChangeTimesheetTimeComponent,
  AssetCreatePopupComponent,
  ServiceVisitCreatePopupComponent,
  BuildingEditPopupComponent,
  PurchaseOrderEditPopupComponent,
  QuotationEditPopupComponent,
  QuotationCreatePopupComponent,
  PurchaseOrderCreatePopupComponent,
  InvoiceEditPopup,
  QuotationClientReviewCreate,
  ConfirmWithNoteComponent,
  HolidayApprovalComponent,
  PpmCreateComponent,
  ServiceCustomizeDataCreateComponent,
  ServiceCustomizeDataEditComponent,
  ServiceExtraCreateComponent,
  ServiceExtraEditComponent,
  BuildingDetailCardComponent,
  BuildingNotesCardComponent,
  GalleryDialogComponent,
  RejectServiceComponent,
  ApproveServiceComponent
];

const imports = [
  CommonModule,
  FormsModule,
  RouterModule,
  FlexLayoutModule,
  TranslateModule,
  MatSidenavModule,
  MatListModule,
  MatTooltipModule,
  MatOptionModule,
  MatSelectModule,
  MatMenuModule,
  MatSnackBarModule,
  MatGridListModule,
  MatToolbarModule,
  MatIconModule,
  MatButtonModule,
  MatRadioModule,
  MatCheckboxModule,
  MatCardModule,
  MatProgressSpinnerModule,
  MatRippleModule,
  MatDialogModule,
  MatAutocompleteModule,
  ReactiveFormsModule,
  MatDatepickerModule,
  MatMomentDateModule,
  MatNativeDateModule,
  MatInputModule,
  MatTabsModule,
  NgxDatatableModule,
  MatChipsModule,
  Md2DatepickerModule,
  FileUploadModule,
  MatStepperModule,
  MatSlideToggleModule,
  MatProgressBarModule,
  MatProgressButtonsModule,
  NoConflictStyleCompatibilityMode,
  MatExpansionModule,
  NgSelectModule,
  NgxMatSelectSearchModule,
  GalleryModule
];

export const MAT_MOMENT_DATE_FORMATS: MatDateFormats = {
  parse: {
    date: ['DD/MM/YYYY'],
    datetime: ['DD/MM/YYYY HH:mm', 'DD/MM/YYYY H:mm'],
    time: ['H:mm', 'HH:mm', 'h:mm a', 'hh:mm a']
  },
  display: {
    date: 'DD/MM/YYYY',
    datetime: 'DD/MM/YYYY HH:mm',
    time: 'HH:mm',
    dateA11yLabel: 'YY',
    monthDayLabel: 'MMM D',
    monthDayA11yLabel: 'MMMM D',
    monthYearLabel: 'MMMM YYYY',
    monthYearA11yLabel: 'MMMM YYYY',
    timeLabel: 'HH:mm'
  }
};

export class CustomAdapter extends MomentDateAdapter {
  format(date: Moment, displayFormat: string): string {
    if (typeof date === 'string') {
      date = moment(date);
    }
    date.toISOString = () => {
      return date.toLocaleString();
    };
    return super.format(date, displayFormat);
  }

  parse(value: any, parseFormat: string | string[]): Moment | null {
    let moment = super.parse(value, parseFormat);
    moment.toISOString = () => {
      return moment.toLocaleString();
    };
    return moment;
  }
}

@NgModule({
  imports: imports,
  entryComponents: popups,
  providers: [
    {provide: MAT_DATE_LOCALE, useValue: 'en-GB'},
    {provide: DateAdapter, useClass: CustomAdapter, deps: [MAT_DATE_LOCALE]},
    {provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS},
    UploaderService
  ],
  declarations: [
    ...classesToInclude,
    ...popups,
    SecondsPipe,
    GalleryDialogComponent,
  ],
  exports: [
    ...classesToInclude,
    ...imports,
    SecondsPipe,
  ]
})
export class SharedModule {
}
