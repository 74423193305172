import { Observable } from 'rxjs';
import { MatDialog } from '@angular/material';
import { Injectable } from '@angular/core';
import { RejectServiceComponent } from './reject-service.component';

@Injectable()
export class RejectServiceService {

  constructor(private dialog: MatDialog) {
  }

  public show(): Observable<undefined | { note: string }> {
    return this.dialog.open(RejectServiceComponent, {
      width: '380px',
      disableClose: false,
      data: {}
    }).afterClosed();
  }
}
