import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs/Observable';
import {HttpCancelService} from '@shared/services/system/http-cancel.service';

/**
 * Adds a default error handler to all requests.
 */
@Injectable()
export class CancelHandlerInterceptor implements HttpInterceptor {

    constructor(private httpCancelService: HttpCancelService) {
    }

    intercept<T>(req: HttpRequest<T>, next: HttpHandler): Observable<HttpEvent<T>> {
        return next.handle(req).takeUntil(this.httpCancelService.onCancelPendingRequests())
    }

}
