import { AppConfirmService } from '@shared/services/system/app-confirm/app-confirm.service';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { ServiceService } from '@shared/services/api/service.service';
import { MatSnackBar } from '@angular/material';
import { AppLoaderService } from '@shared/services/system/app-loader/app-loader.service';
import { CompanyService } from '@shared/services/api/company.service';
import { UserService } from '@shared/services/api/user.service';
import { ModelAttributeOptionService } from '@shared/services/api/model-attribute-option.service';
import { AssetService } from '@shared/services/api/asset.service';
import { AssetModel } from '@shared/models/asset.model';
import { PopupService } from '@shared/services/popup.service';
import { LayoutService } from '@shared/services/system/layout.service';
import { ServiceModel } from '@shared/models/service.model';
import { BuildingService } from '@shared/services/api/building.service';
import { BuildingModel } from '@shared/models/building.model';
import * as _ from 'lodash';
import { isArray } from 'lodash';
import { addHours, addDays, addMonths } from 'date-fns';
import { ServiceExtraService } from '@app/shared/components/common/service-extra.service';
import { ServiceCustomizeService } from '@app/shared/components/common/service-customize.service';
import { ActivatedRoute } from '@angular/router';
import { TimeConvertService } from '@shared/services/time-convert.service';

@Component({
  selector: 'app-service-create',
  templateUrl: './service-create.component.html',
  providers: [
    // SimpleReuseStrategy,
    ServiceCustomizeService,
    ServiceExtraService,
  ]
})
export class ServiceCreateComponent implements OnInit, OnDestroy {
  public _ = _;
  @Output() onSubmitted: EventEmitter<any> = new EventEmitter();
  @Input() engineer_id = null;
  @Input() expect_start_time = null;
  @Input() inPopup = false;
  @Output() onBack = new EventEmitter();

  public loadingBuildings = false;
  public secondFormGroup: FormGroup = new FormGroup({
    previous_service_id: new FormControl([])
  });
  public formGroup: FormGroup = new FormGroup({
    client_id: new FormControl(null, Validators.required),
    building_id: new FormControl(null, Validators.required),
    subject: new FormControl(''),  // string
    problem: new FormControl('', Validators.required),    // string
    budget: new FormControl(0, Validators.required),  // number
    service_type: new FormControl('', Validators.required), // option
    discipline: new FormControl('', Validators.required), // option
    severity: new FormControl(''),   // option
    engineers: new FormControl([]),
    status: new FormControl('pending'),
    asset_ids: new FormControl([]),
    expect_start_time: new FormControl('', Validators.required),
    quote_required_service_id: new FormControl(0),
    data: new FormGroup({
      client: new FormGroup({
        client_reference: new FormControl(''),
      }),
      standard_rate: new FormGroup({
        start_time: new FormControl(null),
        end_time: new FormControl(null),
      }),
      room_id: new FormControl(null),
    }),
  });

  public buildings = [];
  public rooms = [];
  public assets: [AssetModel];

  public filterServices: ServiceModel[] = [];
  public filterServicesTemp: ServiceModel[] = [];
  public building: BuildingModel;
  public loadingPreviousService = false;

  constructor(
    private _service: ServiceService,
    public serviceService: ServiceService,
    private snack: MatSnackBar,
    private loader: AppLoaderService,
    public customizeService: ServiceCustomizeService,
    public extraService: ServiceExtraService,
    public companyService: CompanyService,
    public userService: UserService,
    private maoService: ModelAttributeOptionService,
    private assetService: AssetService,
    public popup: PopupService,
    public layout: LayoutService,
    public buildingService: BuildingService,
    private confirmService: AppConfirmService,
    private route: ActivatedRoute,
    private timeConvertService: TimeConvertService
  ) {
  }

  ngOnInit() {
    this.buildItemForm();
    this.getRouteParam();
  }

  ngOnDestroy(): void {
    this.customizeService.clean();
  }

  getRouteParam() {
    let paramMap = this.route.snapshot.paramMap;
    let id = + paramMap.get('id');
    let type = paramMap.get('type');
    if (type === 'create_quote_request' && id) {
      this.formGroup.patchValue({
        quote_required_service_id: id,
        service_type: 'quote_request',
        client_id: + paramMap.get('client_id'),
        building_id: + paramMap.get('building_id'),
        subject: paramMap.get('subject'),
        problem: paramMap.get('problem'),
        discipline: paramMap.get('discipline'),
      });
    }

    if (this.engineer_id) {
      setTimeout(() => {
        this.formGroup.patchValue({
          engineers: [parseInt(this.engineer_id)],
        });
      }, 618 * 2);
    }

    if (this.expect_start_time) {
      this.formGroup.patchValue({
        expect_start_time: this.expect_start_time,
      });
    }
  }


  buildItemForm() {
    this.formGroup.get('building_id').valueChanges.subscribe((building_id) => {
      if (building_id) {
        if (!this.formGroup.value.quote_required_service_id) {
          this.getServicesByBuildingId(building_id);
          if (this.formGroup.get('service_type').value === 'ppm') {
            this.getAssets(building_id);
          }
        }
        this.getBuilding(building_id);
      }
    });
    this.formGroup.get('service_type').valueChanges.subscribe((service_type) => {
      if (service_type === 'ppm') {
        this.formGroup.get('asset_ids').setValidators([Validators.required]);
        this.getAssets(this.formGroup.get('building_id').value);
      } else {
        this.formGroup.get('asset_ids').clearValidators();
        this.formGroup.get('asset_ids').reset([]);
      }
      if (service_type === 'reactive') {
        this.getDefaultBudget(this.formGroup.value.client_id);
      }
    });
    this.formGroup.get('client_id').valueChanges.subscribe((client_id: any) => {
      if (client_id) {
        this.getBuildings(client_id);
      }
    });
    this.formGroup.get('severity').valueChanges.subscribe((severity: string) => {
      let now = new Date();
      let retDate;
      if (severity.indexOf('hours') !== - 1) {
        retDate = addHours(now, parseInt(severity.slice(0, severity.length - 6), 10));
      }
      if (severity.indexOf('days') !== - 1) {
        retDate = addDays(now, parseInt(severity.slice(0, severity.length - 5), 10));
      }
      if (severity.indexOf('months') !== - 1) {
        retDate = addMonths(now, parseInt(severity.slice(0, severity.length - 7), 10));
      }
      if (retDate) {
        this.formGroup.patchValue({
          expect_start_time: retDate.toISOString()
        });
      }
    });
  }

  getDefaultBudget(clientId) {
    this._service.getDefaultBudget(clientId).subscribe((budget) => {
      this.formGroup.patchValue({budget: budget});
    });
  }

  getAssets(building_id) {
    let loader = this.loader.open();
    this.assetService.byBuildingId(building_id)
      .finally(() => loader.close())
      .subscribe((data: [AssetModel]) => {
        this.assets = data;
      });
  }

  getBuilding(buildingId) {
    let loader = this.loader.open();
    this.buildingService.show(buildingId)
      .finally(() => loader.close())
      .subscribe((data: BuildingModel) => {
        this.building = data;
        this.rooms = _.get(this.building, 'data.rooms', []);
      });
  }

  getServicesByBuildingId(buildingId) {
    this.loadingPreviousService = true;
    this._service.filterServicesByBuilding(buildingId)
      .finally(() => this.loadingPreviousService = false)
      .subscribe((data: ServiceModel[]) => {
        this.filterServices = data;
        this.filterServicesTemp = data;
      });
  }

  openEditServicePopup(serviceId) {
    this.popup.openServiceEditPage(serviceId)
      .afterClosed()
      .subscribe((res) => {
        if (res) {
          this.getServicesByBuildingId(this.formGroup.value.building_id);
        }
      });
  }

  submit() {
    this.confirmService.confirm().subscribe(res => {
      if (res) {
        let loader = this.loader.open();
        let formGroupValue = this.formGroup.value;
        formGroupValue['expect_start_time'] = this.timeConvertService.convert(formGroupValue['expect_start_time']);
        formGroupValue['data']['extra'] = this.extraService.all();
        formGroupValue['data']['customize'] = this.customizeService.allObject();
        if (formGroupValue['data']['standard_rate']['start_time']) {
          formGroupValue['data']['standard_rate']['start_time'] = formGroupValue['data']['standard_rate']['start_time'].format('HH:mm');
        }
        if (formGroupValue['data']['standard_rate']['end_time']) {
          formGroupValue['data']['standard_rate']['end_time'] = formGroupValue['data']['standard_rate']['end_time'].format('HH:mm');
        }
        formGroupValue['previous_service_id'] = this.secondFormGroup.get('previous_service_id').value;
        this._service.store(formGroupValue)
          .finally(() => loader.close())
          .subscribe(() => {
            this.onSubmitted.emit();
            this.customizeService.clean();
            this.extraService.clear();
            this.snack.open('Service Added!', 'OK', { duration: 4000 });
          });
      }
    });
  }

  getBuildings(client_id) {
    this.loadingBuildings = true;
    this.companyService.getBuildingsByClientId(client_id)
      .finally(() => {
        this.loadingBuildings = false;
      })
      .subscribe((data: any) => {
        if (data && isArray(data)) {
          this.buildings = data.filter(item => {
            return item.status === 'active';
          });
        }
      });
  }

  openAssetCreatePage() {
    this.popup.openAssetCreatePage(this.formGroup.value.client_id, this.formGroup.value.building_id)
      .afterClosed()
      .subscribe((res) => {
        if (res) {
          this.getAssets(this.formGroup.value.building_id);
        }
      });
  }

  recall(service: ServiceModel, stepper) {
    this.secondFormGroup.patchValue({
      previous_service_id: service.id
    });
    this.formGroup.patchValue({
      subject: service.subject,  // string
      problem: service.problem,    // string
      budget: service.budget,  // number
      service_type: service.service_type, // option
      discipline: service.discipline, // option
      severity: service.severity,   // option
    });
    this.extraService.setFormGroup(_.get(service, 'data.extra', {}), {recall: true});
    this.customizeService.setByObject(_.get(service, 'data.customize', {}));
    let assets = _.get(service, 'data.assets', []);
    if (isArray(assets)) {
      this.formGroup.patchValue({asset_ids: assets.map(asset => asset.id)});
    }
    stepper.next();
  }

  warranty(service: ServiceModel, stepper) {
    this.secondFormGroup.patchValue({
      previous_service_id: service.id
    });
    this.formGroup.patchValue({
      subject: service.subject,  // string
      problem: service.problem,    // string
      budget: service.budget,  // number
      service_type: service.service_type, // option
      discipline: service.discipline, // option
      severity: service.severity,   // option
    });
    this.extraService.setFormGroup(_.get(service, 'data.extra', {}), {warranty: true});
    this.customizeService.setByObject(_.get(service, 'data.customize'));

    let assets = _.get(service, 'data.assets', []);
    if (isArray(assets)) {
      this.formGroup.patchValue({asset_ids: assets.map(asset => asset.id)});
    }
    stepper.next();
  }

  filter($event) {
    const val = $event.toLowerCase();
    let temp = this.filterServicesTemp;
    if (val) {
      temp = this.filterServicesTemp.filter(item => {
        let isFind = false;
        ['id', 'service_type', 'discipline', 'create_user_name', 'status', 'created_at'].forEach(element => {
          if ((item[element]).toString().toLowerCase().indexOf(val) !== - 1) {
            isFind = true;
          }
          if ((item[element]).toString().toLowerCase().replace('_', ' ').indexOf(val) !== - 1) {
            isFind = true;
          }
        });
        return isFind;
      });
    }
    this.filterServices = temp;
  }

  searchBuilding(term, item) {
    return item.code.toString().toLowerCase().indexOf(term.toString().toLowerCase()) >= 0 ||
      item.name.toString().toLowerCase().indexOf(term.toString().toLowerCase()) >= 0;
  }

  searchRoom(term, item) {
    return item.name.toString().toLowerCase().indexOf(term.toString().toLowerCase()) >= 0;
  }


  back() {
    this.onBack.emit();
  }
}
