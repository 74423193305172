import { FormControl } from '@angular/forms';
import { CompanyModel } from './../../../models/company.model';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Component, Inject, OnInit } from '@angular/core';
import { CompanyService } from '@shared/services/api/company.service';
import { AuthGuard } from '@shared/services/system/auth/auth.guard';

import { GlobalSettingService } from '@shared/services/system/global-setting.service';
import { NotifyService } from '../../notify.service';

@Component({
  selector: 'app-select-company',
  template: `
    <h1 mat-dialog-title translate>Select Use Company</h1>
    <div mat-dialog-content>
      <p translate>You have to choose one use company, please select.</p>
      <mat-form-field>
        <mat-select [formControl]="useCompanyIdControl">
          <mat-option *ngFor="let company of companies" [value]="company.id">{{company.name}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div mat-dialog-actions>
      <button mat-button (click)="submit()" translate>Ok</button>
    </div>
  `
})
export class AppSelectCompanyComponent implements OnInit {
  companies: CompanyModel[];
  useCompanyIdControl = new FormControl('');
  useCompany: CompanyModel;

  constructor(
    public dialogRef: MatDialogRef<AppSelectCompanyComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public companyService: CompanyService,
    public auth: AuthGuard,
    public toast: NotifyService,
    public globalSettingService: GlobalSettingService
  ) {
  }

  ngOnInit(): void {
    this.getSelfCompanies();
    this.useCompanyIdControl.valueChanges.subscribe((companyId) => {
      if (companyId) {
        this.selectCompany(companyId);
      }
    });
  }

  getSelfCompanies() {
    let companies = this.globalSettingService.getConfig('companies', []);
    if (companies === 0) {
      this.companyService.self().subscribe((data: any) => {
        this.companies = data;
        this.globalSettingService.setConfig('companies', this.companies);
        this.checkIfOnlyOneCompanies();
      });
    } else {
      this.companies = companies;
      this.checkIfOnlyOneCompanies();
    }
  }

  checkIfOnlyOneCompanies() {
    if (this.companies.length === 1) {
      this.selectCompany(this.companies[0].id);
      this.submit();
    }
  }

  selectCompany(companyId) {
    this.useCompanyIdControl.patchValue(companyId, { emitEvent: false });
    this.useCompany = this.companies.find(item => +item.id === +companyId);
  }

  submit() {
    this.globalSettingService.publishConfig({
      useCompanyId: this.useCompanyIdControl.value,
    });
    this.dialogRef.close();
    this.toast.show('Set use company id success.');
  }
}
