export const dashboardConfigs = {
  service: [
    {item: 'heating_gas', group: 'service'},
    {item: 'plumbing', group: 'service'},
    {item: 'electrical', group: 'service'},
    {item: 'gas_ppm', group: 'service'},
    {item: 'ac_reactive', group: 'service'},
    {item: 'ac_ppm', group: 'service'},
    {item: 'general', group: 'service'},
    {item: 'general_ppm', group: 'service'},
    {item: 'assigned', group: 'service'},
    {item: 'another_visit', group: 'service'},
    {item: 'parts_required', group: 'service'},
    {item: 'ppm', group: 'service'},
    {item: 'not_trading', group: 'service'},
    {item: 'restricted_trading', group: 'service'},
    {item: 'attended_jobs', group: 'service'},
    {item: 'daily_completed_job', group: 'service'},
    {item: 'weekly_completed_job', group: 'service'},
    {item: 'monthly_completed_job', group: 'service'},

    { item: 'general_quote_approval', group: 'quote' },
    { item: 'ac_quote_approval', group: 'quote' },
    { item: 'fra_quote_approval', group: 'quote' },
    { item: 'another_visit_quote_approval', group: 'quote' },
    {item: 'heating_gas_quote_approval', group: 'quote'},
    {item: 'plumbing_quote_approval', group: 'quote'},
    {item: 'electrical_quote_approval', group: 'quote'},
    { item: 'flooring_quote_approval', group: 'quote' },
    { item: 'drainage_quote_approval', group: 'quote' },
    { item: 'general_quote_request', group: 'quote' },
    { item: 'flooring_quote_request', group: 'quote' },
    {item: 'heating_gas_quote_request', group: 'quote'},
    {item: 'plumbing_quote_request', group: 'quote'},
    {item: 'electrical_quote_request', group: 'quote'},
    {item: 'ac_quote_request', group: 'quote'},
    { item: 'fra_quote_request', group: 'quote' },
    { item: 'drainage_quote_request', group: 'quote' },
  ],
  quotation: [
    {item: 'quotes_awaiting_action', group: 'quotation'},
    {item: 'quotes_awaiting_approval', group: 'quotation'},
    {item: 'quotes_uploaded_today', group: 'quotation'},
    {item: 'quotes_approved_today', group: 'quotation'},
    {item: 'more_info', group: 'quotation'},
  ],
  serviceQuotation: [
    { item: 'general_quote_request', group: 'quote', table: 'service' },
    { item: 'flooring_quote_request', group: 'quote', table: 'service' },
    { item: 'heating_gas_quote_request', group: 'quote', table: 'service' },
    { item: 'plumbing_quote_request', group: 'quote', table: 'service' },
    { item: 'electrical_quote_request', group: 'quote', table: 'service' },
    { item: 'ac_quote_request', group: 'quote', table: 'service' },
    { item: 'fra_quote_request', group: 'quote', table: 'service' },
    { item: 'drainage_quote_request', group: 'quote', table: 'service' },
  ],
};

export const tableReplaceValueColumn = {
  severity: (value) => {
    return value;
  },
  item_total_price: (value) => {
    return parseFloat(value).toFixed(2);
  },
  total: (value) => {
    return parseFloat(value).toFixed(2);
  },
};
