import {Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import {MatSnackBar} from '@angular/material';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {AppLoaderService} from '@shared/services/system/app-loader/app-loader.service';
import {ModelAttributeOptionService} from '@shared/services/api/model-attribute-option.service';
import {ModelAttributeOptions, ServiceVisitOptions} from '@shared/models/options';
import {ServiceVisitService} from '@shared/services/api/service-visit.service';
import {ServiceService} from '@shared/services/api/service.service';
import {UserModel} from '@shared/models/user.model';
import {TimeConvertService} from '@shared/services/time-convert.service';
import {UserService} from '@shared/services/api/user.service';
import {DisplayColumnsService} from '@shared/services/display-columns.service';
import {debounceTime, distinctUntilChanged, filter, map, switchMap, tap} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import * as _ from 'lodash';

@Component({
  selector: 'app-service-visit-create',
  templateUrl: './service-visit-create.component.html'
})
export class ServiceVisitCreateComponent implements OnInit {
  @Input() id;  // type_id
  @Input() isPopup = false;
  @Input() rateType = 'hourly_rate';
  @Output() onCreated = new EventEmitter();
  public showTable = true;
  public engineers: UserModel[] = [];
  public sales = [];
  public vehicles = [];
  public materials = [];
  public sources = [
    'inventory',
    'vehicle',
    'other'
  ];

  public serviceVisitOptions: ServiceVisitOptions;

  public itemForm: FormGroup = new FormGroup({
    user_id: new FormControl(null, Validators.required),
    check_in_time: new FormControl('', Validators.required),
    check_out_time: new FormControl('', Validators.required),
    service_status: new FormControl('', Validators.required),
    note: new FormControl(''),
    observer: new FormControl(''),
    rate_type: new FormControl(''),
    check_in_latitude: new FormControl(''),
    check_out_latitude: new FormControl(''),
    check_in_longitude: new FormControl(''),
    check_out_longitude: new FormControl(''),
  });

  public saleForm: FormGroup = new FormGroup({
    id: new FormControl(null),
    source: new FormControl('other', Validators.required),
    vehicle: new FormControl(''),
    item_name: new FormControl('', Validators.required),
    item_quantity: new FormControl('', Validators.required),
    item_description: new FormControl(''),
    item_unit_price: new FormControl(0, Validators.required),
    item_unit_margin: new FormControl(0),
  });

  constructor(
    private fb: FormBuilder,
    private _service: ServiceVisitService,
    public serviceService: ServiceService,
    private loader: AppLoaderService,
    private snack: MatSnackBar,
    private http: HttpClient,
    private mao: ModelAttributeOptionService,
    private timeConvertService: TimeConvertService,
    public userService: UserService,
    public displayColumnsService: DisplayColumnsService,
  ) {
  }

  ngOnInit() {
    this.getServiceEngineers();
    this.getVehicles();
    this.mao.all()
      .subscribe((data: ModelAttributeOptions) => {
        this.serviceVisitOptions = data.service_visit;
      });
    this.itemForm.patchValue({rate_type: this.rateType});
    this.saleForm.controls['source'].valueChanges
      .subscribe((res) => {
        if (res !== 'vehicle') {
          this.saleForm.removeControl('vehicle');
          this.saleForm.addControl('vehicle', new FormControl(''));
        }
        this.saleForm.patchValue({
          item_name: '',
          item_quantity: 0,
          item_description: '',
          item_unit_price: 0,
          item_unit_margin: 0,
        });
        this.materials = [];
      });
    this.saleForm.controls['item_name'].valueChanges
      .pipe(
        filter(text => {
          return this.saleForm.controls['source'].value !== 'other' && text.length > 2;
        }),
        debounceTime(10),
        distinctUntilChanged(),
        switchMap((res) => this.http.get('service-visits/materials', {
          params: {
            keyword: res,
            source: this.saleForm.controls['source'].value,
            vehicle: this.saleForm.controls['vehicle'].value,
          }
        })),
      )
      .subscribe((res: any) => {
        this.materials = res;
        if (res.length === 0) {
          this.saleForm.controls['item_name'].setErrors({noneItem: true});
        }
      });
  }

  getVehicles() {
    this.http.get('service-visits/vehicles').subscribe((res: any) => {
      this.vehicles = res;
    });
  }

  getServiceEngineers() {
    let loader = this.loader.open();
    this.serviceService.engineers(this.id)
      .finally(() => loader.close())
      .subscribe((data: UserModel[]) => {
        this.engineers = data;
      });
  }

  submit() {
    let itemFormValue = this.itemForm.value;
    itemFormValue['check_in_time'] = this.timeConvertService.convert(itemFormValue['check_in_time']);
    itemFormValue['check_out_time'] = this.timeConvertService.convert(itemFormValue['check_out_time']);

    let data = {...itemFormValue, service_id: this.id, sales: this.sales};
    let loader = this.loader.open();
    this._service.store(data)
      .finally(() => {
        loader.close();
      })
      .subscribe(() => {
        this.snack.open('Visit created!', 'OK', {duration: 4000});
        this.onCreated.emit(true);
      });
  }

  materialAutoComplete($event) {
    const item = $event.option.value;
    this.saleForm.patchValue({
      item_description: item.description,
      item_unit_price: item.sell_price
    });
  }

  materialName(item) {
    return item.name;
  }

  addSale() {
    const sale = this.saleForm.value;
    if (sale.source !== 'other') {
      if (sale.item_name.name) {
        const quantity = sale.item_quantity;
        if (quantity > sale.item_name.quantity) {
          this.snack.open('Maximum quantity exceeded.', 'OK', {duration: 4000});
          return;
        }
        if (quantity < 0) {
          this.snack.open('Quantity must be greater than 0.', 'OK', {duration: 4000});
          return;
        }
        _.assignIn(sale, {product_id: sale.item_name.id});
        sale.item_name = sale.item_name.name;
      } else {
        return;
      }
    }
    _.assignIn(sale, {item_total_price: this.itemTotalPrice});
    this.sales.push(_.cloneDeep(sale));
    this.sales = [...this.sales];
    this.saleForm.patchValue({
      id: null,
      source: 'other',
      vehicle: '',
      item_name: '',
      item_quantity: '',
      item_description: '',
      item_unit_price: 0,
      item_unit_margin: 0
    });
  }

  deleteMaterial(index) {
    this.sales.splice(index, 1);
  }

  get itemTotalPrice() {
    let lineTotal = 0;
    if (this.saleForm) {
      lineTotal = (this.saleForm.controls['item_unit_price'].value * (1 + this.saleForm.controls['item_unit_margin'].value / 100)) *
        this.saleForm.controls['item_quantity'].value;
    }

    return lineTotal.toFixed(2);
  }

  changeIndex() {
    this.showTable = false;
    setTimeout(() => {
      this.showTable = true;
    });
  }
}
