import { Component, OnInit, Inject, Input } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA, MatSnackBar } from "@angular/material";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { AppLoaderService } from "@shared/services/system/app-loader/app-loader.service";
import { FileUploader } from "ng2-file-upload";
import { DocumentService } from "@shared/services/api/document.service";
import { AuthGuard } from "@shared/services/system/auth/auth.guard";
import { NotifyService } from "@app/shared/services/notify.service";
import { UploaderService } from "@shared/services/uploader.service";

@Component({
  selector: "app-document-edit-popup",
  templateUrl: "./document-edit.component.html",
})
export class DocumentEditPopupComponent implements OnInit {
  id; // type_id
  public uploader: FileUploader;
  public itemForm: FormGroup;
  public inPopup = true;

  public item;

  constructor(
    private fb: FormBuilder,
    private loader: AppLoaderService,
    private snack: MatSnackBar,
    public dialogRef: MatDialogRef<DocumentEditPopupComponent>,
    public documentService: DocumentService,
    private auth: AuthGuard,
    private toast: NotifyService,
    private uploaderService: UploaderService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {
    this.id = this.data.id;
    this.uploader = this.uploaderService.get({
      url: this.documentService.uploadUrl() + "/" + this.id,
    });
    this.setUploadEvent();
    this.buildItemForm({});
    this.show(this.id);
  }

  buildItemForm(item) {
    this.itemForm = this.fb.group({
      subject: ["" || item.subject, Validators.required],
      description: ["" || item.description, Validators.required],
      type: ["" || item.type, Validators.required],
    });
  }

  setUploadEvent() {
    this.uploader.onBuildItemForm = (fileItem, form) => {
      this.uploader.options.additionalParameter = {
        ...this.itemForm.value,
        _method: "PUT",
      };
    };
    let loader;
    this.uploader.onBeforeUploadItem = (fileItem) => {
      loader = this.loader.open("Uploading...");
    };
    this.uploader.onCompleteItem = (
      item: any,
      response: any,
      status: any,
      headers: any
    ) => {
      if (status === 200) {
        this.toast.show("Add success!");
        this.uploader.clearQueue();
        this.dialogRef.close(true);
      } else {
        this.toast.show("Add failed!");
      }
      loader.close();
    };
  }

  show(id) {
    let loader = this.loader.open();
    this.documentService
      .show(id)
      .finally(() => {
        loader.close();
      })
      .subscribe((data: any) => {
        this.item = data;
        this.buildItemForm(this.item);
      });
  }

  submit() {
    if (this.uploader.queue.length === 0) {
      let data = { ...this.itemForm.value };
      let loader = this.loader.open();
      this.documentService
        .update(this.id, data)
        .finally(() => {
          loader.close();
        })
        .subscribe(() => {
          this.snack.open("Document updated!", "OK", { duration: 4000 });
          this.dialogRef.close(true);
        });
    } else {
      this.uploader.uploadAll();
    }
  }
}
