import { GlobalSettingService } from './../../../../services/system/global-setting.service';
import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { egretAnimations } from '@shared/animations/egret-animations';
import { QuotationService } from '@shared/services/api/quotation.service';
import { LayoutService } from '@app/shared/services/system/layout.service';
import { GlobalSettingConfig } from '@shared/interfaces/global-setting-config';
import {DisplayColumnsService} from '@shared/services/display-columns.service';
import * as _ from 'lodash';

@Component({
  selector: 'app-quotation-table',
  templateUrl: './quotation-table.component.html',
  animations: egretAnimations
})
export class QuotationTableComponent implements OnInit, OnDestroy {

  @Input() dashboardType = '';
  @Input() dashboardGroup = '';
  @Input() inPopup = false;
  public config = null;
  private destroy = false;

  constructor(
    public quotationService: QuotationService,
    public displayColumnsService: DisplayColumnsService,
    private globalSettingService: GlobalSettingService,
    private layout: LayoutService,
  ) {
  }

  ngOnInit() {
    this.layout.onRefresh
      .takeWhile(() => !this.destroy)
      .subscribe(() => this.quotationService.refresh());
    this.globalSettingService.config$
      .takeWhile(() => !this.destroy)
      .subscribe((config: GlobalSettingConfig) => {
        if (config.useCompanyId) {
          this.config = config;
          this.quotationService.refresh();
        }
      });
    if (this.dashboardType && this.dashboardGroup) {
      this.quotationService.conditions.dashboard_type.value = {
        item: this.dashboardType,
        group: this.dashboardGroup
      };
    }
  }

  ngOnDestroy(): void {
    this.destroy = true;
  }

  getSorts($event) {
    let canSort = false;
    this.displayColumnsService.configs.quotation.displayedColumns.forEach((item) => {
      if (item.key === $event.sort_column && item.canSort) {
        canSort = true;
        $event.sort_column = item.sortColumnName;
        $event.sort_table = item.columnTable;
      }
    });

    if (canSort) {
      return $event;
    }

    return {};
  }

  serverSort($event) {
    if (this.config && this.config.useCompanyId) {
      if ($event.sorts.length > 0) {
        const sort = $event.sorts[0];
        this.quotationService.paginator.pageNumber = 1;
        _.assignIn(this.quotationService.others, this.getSorts({sort_column: sort.prop, order_by: sort.dir, sort_table: ''}));
        this.quotationService.refresh();
      }
    }
  }

  sort() {
  }
}
